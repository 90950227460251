import { HTMLAttributes } from 'react';
import * as React from 'react';
import { FieldRenderProps } from 'react-final-form';
import classnames from 'classnames';

interface TextFieldProps extends HTMLAttributes<HTMLInputElement> {
  label: string;
  errorOnTouch?: boolean; // if true, error state will be shown on touch, otherwise on submit
  info?: string; // info message to show below the field
}

const Field = ({
  className,
  label,
  errorOnTouch,
  info,
  meta: { touched, submitFailed, error, submitError, dirtySinceLastSubmit = false },
  ...inputProps
}: FieldRenderProps<any, HTMLInputElement> & TextFieldProps): React.ReactElement => {
  const areErrorsPresent = error || submitError;
  const isInErrorState = errorOnTouch
    ? touched && areErrorsPresent
    : touched && areErrorsPresent && submitFailed && !dirtySinceLastSubmit;
  const fieldClassName = classnames(
    `form__input-wrapper form__input-wrapper--${inputProps.input.name}`,
    {
      'form__input-wrapper--error': isInErrorState,
    }
  );
  const { input, ...props } = inputProps;
  return (
    <div className={fieldClassName}>
      <label className="form__label">
        {label}
        <input
          {...{
            ...input,
            ...props,
            className,
          }}
        />
        {info && <span className="form__info">{info}</span>}
      </label>
    </div>
  );
};

export default Field;
