import logger from '@klara/logger';
import { reactRouterNavigateTo } from 'actions/LocationActions';
import { routeBuilders } from 'boot/patient/routes';
import { PatientConversation } from 'models/common.types';
import ExchangeTokenWidgetEndpoint from 'persistence/patient/ExchangeKeycloakTokenWidgetEndpoint';
import { Reducer } from 'redux';
import { defineTypes } from 'redux/helpers';
import { actions as AlertActions } from 'redux/modules/common/alerts';
import { put, call, select, SagaGenerator, takeLatest } from 'typed-redux-saga';

import { PatientRootState } from './reducers';

export const WIDGET_KEYCLOAK_CALLBACK = 'KEYCLOAK/EXCHANGE/WIDGET/CALLBACK';
export const startAuthTypes = defineTypes('PATIENT/WIDGET/START_AUTH');
export const STORE_PHONE_NUMBER_WIDGET = 'STORE_PHONE_NUMBER_WIDGET';

export type TokenPayload = {
  code: string;
};

type AuthPayload = {
  phoneNumber: string;
};

type WidgetKeycloakCallback = {
  type: string;
  payload: Record<string, never>;
};

type StartAuthWidget = {
  type: typeof startAuthTypes.REQUEST;
  payload: AuthPayload;
};

type WidgetPhonePayload = {
  phoneNumber: string;
};

type StorePhoneNumberWidget = {
  type: typeof STORE_PHONE_NUMBER_WIDGET;
  payload: WidgetPhonePayload;
};

export const actions = {
  widgetKeycloakCallback(): WidgetKeycloakCallback {
    return { type: WIDGET_KEYCLOAK_CALLBACK, payload: {} };
  },
  startAuthWidget(payload: AuthPayload): StartAuthWidget {
    return { type: startAuthTypes.REQUEST, payload };
  },
  storePhoneNumberWidget(payload: WidgetPhonePayload): StorePhoneNumberWidget {
    return { type: STORE_PHONE_NUMBER_WIDGET, payload };
  },
};

type ExchangeKeycloakState = {
  widgetLoginPhoneNumber: string;
  isAuthSuccessfull: boolean;
};

export const initialState: ExchangeKeycloakState = {
  widgetLoginPhoneNumber: null,
  isAuthSuccessfull: false,
};

export const reducer: Reducer<ExchangeKeycloakState> = (state = initialState, action) => {
  switch (action.type) {
    case STORE_PHONE_NUMBER_WIDGET:
      return { ...state, widgetLoginPhoneNumber: action.payload.phoneNumber };
    case startAuthTypes.REQUEST:
    case startAuthTypes.FAILURE:
      return { ...state, isAuthSuccessfull: false };
    case startAuthTypes.SUCCESS:
      return { ...state, isAuthSuccessfull: true };

    default:
      return state;
  }
};

export const selectors = {
  widgetLoginPhoneNumber: (state: PatientRootState): string =>
    state.exchangeKeycloak.widgetLoginPhoneNumber,
  isAuthSuccessfull: (state: PatientRootState): boolean => state.exchangeKeycloak.isAuthSuccessfull,
};

export function* widgetKeycloakCallback() {
  try {
    //TODO: Add logic to get convo
    // yield call(getConversationsSaga);
    const conversations = null // yield* select(conversationsSelectors.conversations);
    const teamId = localStorage.getItem('klaraTeamId');
    const hasConversationForTeam = conversations.some((conversation: PatientConversation) =>
      conversation.teams.some((team) => team.id === parseInt(teamId, 10))
    );

    if (hasConversationForTeam) {
      yield call(reactRouterNavigateTo, routeBuilders.teamConversationsRoute(teamId));
    } else {
      yield call(reactRouterNavigateTo, routeBuilders.teamSignupRoute(teamId));
    }
  } catch (error) {
    logger.error('Widget keycloak callback failed', {}, error);
    yield put(AlertActions.pushDefaultErrorAndNotify(error));
  }
}

export function* startAuthWidgetSaga({ payload }: { payload: AuthPayload }) {
  try {
    yield call(ExchangeTokenWidgetEndpoint.startAuth, payload.phoneNumber);
    yield* put({ type: startAuthTypes.SUCCESS });
  } catch (error) {
    yield* put({ type: startAuthTypes.FAILURE });
    yield put(AlertActions.pushDefaultErrorAndNotify(error));
  }
}

export function* rootSaga(): SagaGenerator<void> {
  yield* takeLatest<WidgetKeycloakCallback>(WIDGET_KEYCLOAK_CALLBACK, widgetKeycloakCallback);
  yield* takeLatest<StartAuthWidget>(startAuthTypes.REQUEST, startAuthWidgetSaga);
}
