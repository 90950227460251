import * as React from 'react';
import styled, { css } from 'styled-components';
import colors from '../config/theme/colors';

export interface CheckboxProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'type'> {
  theme: 'dark' | 'light';
  checkboxSize?: 'small' | 'large';
}

/* eslint-disable */
const CustomCheckbox = styled.input<CheckboxProps>`
  /* NOTE: it is styling [type="checkbox"] for specificity */
  &[type='checkbox'] {
    appearance: none;
    width: 14px;
    height: 14px;
    line-height: 12px;
    font-size: 12px;
    background-color: transparent;
    border: 2px solid ${(props) => (props.theme === 'dark' ? colors.grey10 : colors.primaryBrand)};
    box-shadow: none;
    border-radius: 3px;
    display: inline-block;
    position: relative;

    &:checked {
      background-color: ${(props) =>
        props.theme === 'dark' ? colors.grey10 : colors.primaryBrand};
      padding: 0px;
    }

    &:disabled {
      border-color: ${colors.grey10};
      background-color: ${colors.grey10};

      &:checked {
        &::after {
          content: url("data:image/svg+xml;utf8,<svg width='12px' height='9px' viewBox='0 0 12 9' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><g stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'><g transform='translate(-874.000000, -244.000000)' fill='%23333333' fill-rule='nonzero'><g transform='translate(864.000000, 120.000000)'><g transform='translate(0.000000, 116.000000)'><path d='M14.980443,16.8047011 L21.4491776,10.3359665 C21.5663646,10.2187795 21.6249584,10.0840138 21.6249584,9.93167059 C21.6249584,9.77932738 21.5663646,9.64456172 21.4491776,9.52737468 L20.6757419,8.75393902 C20.5585549,8.63675198 20.4237892,8.57815819 20.271446,8.57815819 C20.1191028,8.57815819 19.9843372,8.63675198 19.8671501,8.75393902 L14.5937252,14.027364 L12.1327936,11.5664323 C12.0156065,11.4492453 11.8808409,11.3906515 11.7284976,11.3906515 C11.5761544,11.3906515 11.4413888,11.4492453 11.3242017,11.5664323 L10.5507661,12.339868 C10.433579,12.457055 10.3749852,12.5918207 10.3749852,12.7441639 C10.3749852,12.8965071 10.433579,13.0312728 10.5507661,13.1484598 L14.2070074,16.8047011 C14.3007573,16.9218881 14.4296629,16.9804819 14.5937252,16.9804819 C14.7577875,16.9804819 14.8866931,16.9218881 14.980443,16.8047011 Z'></path></g></g></g></g></svg>");
        }
      }
    }
  }
`;

const CheckboxAfterCss = css<any>`
  display: block;
  position: absolute;
  left: -1px;
  ${(props) =>
    props.checkboxSize === 'large'
      ? {
          transform: 'scale(1.4)',
          left: 0,
          paddingLeft: '1px',
        }
      : {}}
`;

const DarkCheckbox = styled(CustomCheckbox)`
  &:checked::after {
    content: url("data:image/svg+xml;utf8,<svg width='12px' height='9px' viewBox='0 0 12 9' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><g stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'><g transform='translate(-874.000000, -244.000000)' fill='%23333333' fill-rule='nonzero'><g transform='translate(864.000000, 120.000000)'><g transform='translate(0.000000, 116.000000)'><path d='M14.980443,16.8047011 L21.4491776,10.3359665 C21.5663646,10.2187795 21.6249584,10.0840138 21.6249584,9.93167059 C21.6249584,9.77932738 21.5663646,9.64456172 21.4491776,9.52737468 L20.6757419,8.75393902 C20.5585549,8.63675198 20.4237892,8.57815819 20.271446,8.57815819 C20.1191028,8.57815819 19.9843372,8.63675198 19.8671501,8.75393902 L14.5937252,14.027364 L12.1327936,11.5664323 C12.0156065,11.4492453 11.8808409,11.3906515 11.7284976,11.3906515 C11.5761544,11.3906515 11.4413888,11.4492453 11.3242017,11.5664323 L10.5507661,12.339868 C10.433579,12.457055 10.3749852,12.5918207 10.3749852,12.7441639 C10.3749852,12.8965071 10.433579,13.0312728 10.5507661,13.1484598 L14.2070074,16.8047011 C14.3007573,16.9218881 14.4296629,16.9804819 14.5937252,16.9804819 C14.7577875,16.9804819 14.8866931,16.9218881 14.980443,16.8047011 Z'></path></g></g></g></g></svg>");
    ${CheckboxAfterCss};
  }
`;

const LightCheckbox = styled(CustomCheckbox)`
  &:checked::after {
    content: url("data:image/svg+xml;utf8,<svg width='12px' height='9px' viewBox='0 0 12 9' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><g stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'><g transform='translate(-874.000000, -244.000000)' fill='%23FFFFFF' fill-rule='nonzero'><g transform='translate(864.000000, 120.000000)'><g transform='translate(0.000000, 116.000000)'><path d='M14.980443,16.8047011 L21.4491776,10.3359665 C21.5663646,10.2187795 21.6249584,10.0840138 21.6249584,9.93167059 C21.6249584,9.77932738 21.5663646,9.64456172 21.4491776,9.52737468 L20.6757419,8.75393902 C20.5585549,8.63675198 20.4237892,8.57815819 20.271446,8.57815819 C20.1191028,8.57815819 19.9843372,8.63675198 19.8671501,8.75393902 L14.5937252,14.027364 L12.1327936,11.5664323 C12.0156065,11.4492453 11.8808409,11.3906515 11.7284976,11.3906515 C11.5761544,11.3906515 11.4413888,11.4492453 11.3242017,11.5664323 L10.5507661,12.339868 C10.433579,12.457055 10.3749852,12.5918207 10.3749852,12.7441639 C10.3749852,12.8965071 10.433579,13.0312728 10.5507661,13.1484598 L14.2070074,16.8047011 C14.3007573,16.9218881 14.4296629,16.9804819 14.5937252,16.9804819 C14.7577875,16.9804819 14.8866931,16.9218881 14.980443,16.8047011 Z'></path></g></g></g></g></svg>");
    ${CheckboxAfterCss};
  }
`;

function Checkbox(props: CheckboxProps) {
  const SelectedCheckbox = props.theme === 'dark' ? DarkCheckbox : LightCheckbox;

  return (
    <SelectedCheckbox {...props} type="checkbox" checkboxSize={props.checkboxSize ?? 'small'} />
  );
}

export default Checkbox;
