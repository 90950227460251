import { call, put, takeLatest } from 'redux-saga/effects';
import { defineTypes } from 'redux/helpers';

export const initialState = {
  sensorType: null,
};

export const biometricsTypes = defineTypes('BIOMETRICS');

export const selectors = {
  biometricsAvailable: (state) => state.mobile.sensorType !== null,
  sensorType: (state) => state.mobile.sensorType,
};

export const actions = {
  checkIfBiometricsAvailable() {
    return { type: biometricsTypes.REQUEST };
  },
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case biometricsTypes.SUCCESS: {
      return { ...state, sensorType: action.sensorType };
    }
    case biometricsTypes.FAILURE: {
      return { ...state, sensorType: null };
    }
    default:
      return state;
  }
};

export function* biometricsAvailabilitySaga() {
  if (window.nativeAppInterface) {
    try {
      const sensorType = yield call(window.nativeAppInterface.biometricsAreAvailable);
      yield put({ type: biometricsTypes.SUCCESS, sensorType });
    } catch (err) {
      yield put({ type: biometricsTypes.FAILURE });
    }
  }
}

export function* rootSaga() {
  yield takeLatest(biometricsTypes.REQUEST, biometricsAvailabilitySaga);
}
