import PropTypes from 'prop-types';
import { Component } from 'react';
import throttle from 'lodash/throttle';

class WithWindowSize extends Component {
  state = {
    windowHeight: window.innerHeight,
    windowWidth: window.innerWidth,
  };

  constructor(props) {
    super(props);

    window.addEventListener('resize', this.throttledSetWindowSize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.throttledSetWindowSize);
  }

  setWindowSize = () => {
    this.setState({
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
    });
  };

  throttledSetWindowSize = throttle(this.setWindowSize, 200, { leading: true });

  render() {
    const { InnerComponent, ...rest } = this.props;
    const { windowHeight, windowWidth } = this.state;

    return (
      <InnerComponent
        {...rest}
        windowHeight={windowHeight}
        windowWidth={windowWidth}
        breakpoint={768}
        isMobile={windowWidth <= 768}
      />
    );
  }
}

WithWindowSize.propTypes = {
  InnerComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
};

export default (InnerComponent) => (props) =>
  <WithWindowSize {...props} InnerComponent={InnerComponent} />;
