import PropTypes from 'prop-types';
import * as React from 'react';
import styled from 'styled-components';
import theme from '../../config/theme/klara';
import Icon from '../Icons/Icon';

const ButtonFile = styled.div`
  cursor: pointer;
  height: 21px;
  width: 21px;
  margin: 0 10px 0 10px;
  border: none;
  position: relative;
  color: ${theme.colors.primaryBrand};

  :hover {
    opacity: 0.9;
  }
`;

const Input = styled.input`
  opacity: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
`;

class Component extends React.Component {
  constructor(props) {
    super(props);

    this.fileRef = null;
  }

  setFileRef = (el) => {
    this.fileRef = el;
  };

  render() {
    const { onChange, onClick, className, style, multiple, disabled } = this.props;

    return (
      <ButtonFile className={className} style={style} onClick={onClick}>
        <Icon name="paperclip" />
        <Input
          ref={this.setFileRef}
          type="file"
          multiple={multiple}
          onChange={onChange}
          disabled={disabled}
        />
      </ButtonFile>
    );
  }
}

Component.propTypes = {
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.shape({}),
};

Component.defaultProps = {
  disabled: false,
  multiple: true,
};

Component.displayName = 'ButtonFile';

export default Component;
