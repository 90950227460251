import { reducer as formReducer } from 'redux-form';

import { reducer as alertsReducer } from './alerts';
import { reducer as casperReducer } from './casper';
import { reducer as devicesReducer } from './devices';
import { reducer as locationReducer } from './location';
import { reducer as mobileReducer } from './mobile';
import { reducer as pusherReducer } from './pusher/pusher';
import { reducer as sessionReducer } from './session';


export default {
  alerts: alertsReducer,
  form: formReducer,
  mobile: mobileReducer,
  devices: devicesReducer,
  location: locationReducer,
  session: sessionReducer,
  pusher: pusherReducer,
  casper: casperReducer
};
