export default {
  primaryBrand: '#3C32AA',

  grey100: '#1D1D1B',
  grey90: '#333333',
  grey50: '#8E8E8D',
  grey60: '#666666',
  grey25: '#C6C6C6',
  grey10: '#E8E8E8',
  grey0: '#F9F9F9',
  white: '#ffffff',
  black: '#000000',
  blue50: '#f2ecff',

  hoverGray: '#f6fcff',

  errorRed: '#E8205E',
  errorOpaque: '#fbc6d5',
  successGreen: '#2DCD82',
  successOpaque: 'rgb(202, 242, 224)',

  internalNoteBlue: '#e0d4fd',
  yellowLight: '#fff5d1',
  pink: '#FFDFDF',
  transparentRed: '#E8205E15',
  transparentGreen: '#2DCD8215',
  transparentGrey: '#8E8E8D15',
};
