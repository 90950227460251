import PropTypes from 'prop-types';
import { Component } from 'react';
import upperFirst from 'lodash/upperFirst';
import translate from 'services/translate';

class FormError extends Component {
  static propTypes = {
    fieldName: PropTypes.string.isRequired,
    errorToken: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.shape({})]),
    formName: PropTypes.string.isRequired,
    singleError: PropTypes.bool,
  };

  static defaultProps = {
    singleError: false,
  };

  translateErrorKey = (errorToken) => {
    const { fieldName, formName } = this.props;
    let translationKeys;

    if (Array.isArray(errorToken)) {
      translationKeys = errorToken.map((token) => `${formName}.errors.${fieldName}.${token}`);
    } else {
      translationKeys = [
        `${formName}.errors.${fieldName}.${errorToken}`,
        `errors.${fieldName}.${errorToken}`,
        `generic_errors.${errorToken}`,
        'generic_errors.invalid',
      ];
    }

    return {
      __html: upperFirst(translate(translationKeys, { fieldName: fieldName.split('_').join(' ') })),
    };
  };

  render() {
    const { fieldName, errorToken, singleError } = this.props;

    if (Array.isArray(errorToken)) {
      if (errorToken.filter((token) => !!token).length === 0) {
        return null;
      }
    }
    if (singleError) {
      return (
        <div
          className="form__errors-box-item"
          data-test-id="error-message"
          key={fieldName + errorToken}
          dangerouslySetInnerHTML={this.translateErrorKey(errorToken)}
        />
      );
    }
    return (
      <li
        className="form__errors-box-item"
        data-test-id="error-message"
        key={fieldName + errorToken}
        dangerouslySetInnerHTML={this.translateErrorKey(errorToken)}
      />
    );
  }
}

export default FormError;
