import { isArray } from 'lodash';
import { Answers, FormattedAnswer, Question } from 'models/questionnaire.types';
import QuestionnaireEndpoint from 'persistence/patient/scheduling/QuestionnaireEndpoint';
import { Reducer } from 'redux';
import { defineTypes } from 'redux/helpers';
import { PatientRootState } from 'redux/modules/patient/reducers';
import { call, SagaGenerator, put, takeLatest } from 'typed-redux-saga';
import { KeysToCamelCase } from 'typescript-utils';

import { camelCaseKeys } from 'util/camelCaseKeys';
import { OTHER_RESPONSE_SUFFIX, OTHER_TEXT_VALUE } from 'util/constants';


const getAllQuestionsTypes = defineTypes('PATIENT/SCHEDULING/QUESTIONS/GET');
export const GET_QUESTIONS_FINALLY = 'PATIENT/SCHEDULING/QUESTIONS/FINALLY';
const RESET = 'PATIENT/SCHEDULING/QUESTIONS/RESET';

type GetAllQuestionsActionPayload = {
  encryptedCoreTeamId: string;
  phoneVerificationToken?: string;
  authToken?: string;
};
type GetAllQuestionsAction = {
  type: typeof getAllQuestionsTypes.REQUEST;
  payload: GetAllQuestionsActionPayload;
};

const SAVE_ANSWERS = 'PATIENT/SCHEDULING/QUESTIONS/SAVE';
type SaveAnswersAction = {
  type: typeof SAVE_ANSWERS;
  payload: Answers;
};

type QuestionnaireState = {
  initialized: boolean;
  questions: Question[];
  answers?: Answers;
};

export const actions = {
  getAllQuestions: (payload: GetAllQuestionsActionPayload): GetAllQuestionsAction => ({
    type: getAllQuestionsTypes.REQUEST,
    payload,
  }),
  saveAnswers: (answers: Answers): SaveAnswersAction => ({
    type: SAVE_ANSWERS,
    payload: answers,
  }),
};

export const selectors = {
  isInitialized: ({ schedulingQuestionnaire }: PatientRootState): boolean =>
    schedulingQuestionnaire.initialized,
  hasPublishedQuestions: ({ schedulingQuestionnaire }: PatientRootState): boolean =>
    schedulingQuestionnaire.questions?.length > 0,
  isQuestionnaireVisible: (state: PatientRootState): boolean =>
    selectors.hasPublishedQuestions(state),
  questionFields: ({ schedulingQuestionnaire }: PatientRootState): Question[] =>
    schedulingQuestionnaire.questions.sort(
      ({ position: orderA }, { position: orderB }) => orderA - orderB
    ),
  questionnaireAnswers: ({ schedulingQuestionnaire }: PatientRootState): Answers =>
    schedulingQuestionnaire.answers,
  formattedAnswers: (state: PatientRootState): KeysToCamelCase<FormattedAnswer, '_'>[] => {
    const questionnaireAnswers: KeysToCamelCase<FormattedAnswer, '_'>[] = [];
    const qAnswers = selectors.questionnaireAnswers(state);
    const questions = selectors.questionFields(state);

    Object.keys(qAnswers).forEach((key: keyof typeof qAnswers) => {
      let uuid = key.replace('response-', '');
      const isOtherAnswer = uuid.includes(OTHER_RESPONSE_SUFFIX);
      if (isOtherAnswer) uuid = uuid.replace(OTHER_RESPONSE_SUFFIX, '');

      const question = questions.find((q) => q.uuid === uuid);
      const value: string | string[] = qAnswers[key];

      // when a single-select field has `otherAllowed` and patient uses it to input their own answer
      // we get 2 entries: one for the selected option "Other" and one for the value entered by user
      // here we skip the "Other" value from the selected radio button entry
      // and instead we rely on the entry for the actual patient input value
      if (question.otherAllowed && !isOtherAnswer && value === OTHER_TEXT_VALUE) return;

      questionnaireAnswers.push({
        questionUuid: uuid,
        question: question.text,
        values: isArray(value) ? value : [value],
      });
    });

    return questionnaireAnswers;
  },
};

export const initialState: QuestionnaireState = {
  initialized: false,
  questions: null,
  answers: null,
};

export const reducer: Reducer<QuestionnaireState> = (state = initialState, { type, payload }) => {
  switch (type) {
    case getAllQuestionsTypes.SUCCESS:
      return {
        ...state,
        initialized: true,
        questions: payload,
      };
    case SAVE_ANSWERS:
      return {
        ...state,
        answers: payload,
      };
    case RESET:
      return initialState;
    default:
      return state;
  }
};

export function* getAllQuestionsSaga({ payload }: GetAllQuestionsAction): SagaGenerator<void> {
  try {
    const response = yield* call(QuestionnaireEndpoint.getQuestions, payload);

    yield* put({
      type: getAllQuestionsTypes.SUCCESS,
      payload: response.map(camelCaseKeys),
    });
  } catch (error) {
    yield* put({
      type: getAllQuestionsTypes.FAILURE,
    });
  } finally {
    yield* put({
      type: GET_QUESTIONS_FINALLY,
    });
  }
}

export function* rootSaga(): SagaGenerator<void> {
  yield* takeLatest<GetAllQuestionsAction>(getAllQuestionsTypes.REQUEST, getAllQuestionsSaga);
}
