export const routeBuilders = {
  rootRoute: () => routeBuilders.inboxRoute(),
  inboxRoute: () => '/inbox',
  activationRoute: () => '/activate',
  forgotPasswordRoute: () => '/reset-password',
  notificationSettingsRoute: () => '/notification-settings',
  helpRoute: () => '/help',
  conversationRoute: (conversationId, messageId) =>
    messageId
      ? `/conversations/${conversationId}/messages/${messageId}`
      : `/conversations/${conversationId}`,
  videoVisitEndedRoute: (conversationId) => `/video-visit-ended/${conversationId}`,
  videoVisitCompletedRoute: (conversationId) => `/video-visit-completed/${conversationId}`,
  thankyouRoute: () => '/thankyou',
  loginRoute: () => '/login',
  logoutRoute: () => '/logout',
  documentUploadRoute: (documentRequestId) => `/document-request-response/${documentRequestId}`,
  lastStepRoute: () => '/last-step',
  schedulingRoute: (token) => `/scheduling?resourceToken=${token}`,
  schedulingRequestSubmittedRoute: (conversationId) => `/scheduling/submitted/${conversationId}`,
  schedulingThankyouRoute: (conversationId) => `/scheduling/thankyou/${conversationId}`,
  schedulingUnavailableRoute: (conversationId = null) =>
    conversationId ? `/scheduling/unavailable/${conversationId}` : '/scheduling/unavailable',
  videoCallRoute: (conversationId) => `/conversations/${conversationId}/video-visit`,
  teamConversationsRoute: (teamId) => `/team/${teamId}/conversations`,
  addDependentRoute: (teamId) => `/team/${teamId}/dependent`,

  askFor2FARoute: () => '/2fa/prompt',
  startEmailSetupRoute: () => '/2fa/setup',
  mfaSettingsRoute: () => '/2fa/settings',
  enterEmailCodeRoute: (mode = 'desktop') => `/2fa/verify/${mode}`,
  successful2FARoute: () => '/2fa/success',

  teamSignupRoute: (teamId) => `/signup/${teamId}`,
  teamSignupSelfRoute: (teamId) => `/signup/${teamId}/self`,
  teamSignupDependentRoute: (teamId) => `/signup/${teamId}/dependent`,
  teamSignupVerifyRoute: (teamId) => `/widget/signup/${teamId}/verify`,
  widgetRoute: (widgetId) => `/widget/${widgetId}`,
  widgetConfirmAndRedirectRoute: (route) => `/widget/confirmRedirect/?route=${route}`,
  publicSchedulingRoute: (teamId) => `/widget/publicScheduling/scheduling/${teamId}`,
  publicSchedulingThankyouRoute: () => '/widget/publicScheduling/thankyou/',
  publicSchedulingRequestSentRoute: () => '/widget/publicScheduling/requestsent',
  publicSchedulingUnavailableRoute: () => '/widget/publicScheduling/unavailable/',
  appointmentReminderUnavailableRoute: () => '/appointmentReminder/unavailable/',

  selfRoutingConfirmationRoute: (teamId, conversationId) =>
    `/phone/signup/${teamId}/confirmation${conversationId ? `/${conversationId}` : ''}`,
  selfRoutingSignupRoute: (teamId, sessionKey) => `/phone/signup/${teamId}/${sessionKey}`,
  selfRoutingSignupSelfRoute: (teamId, sessionKey, conversationId) =>
    `/phone/signup/${teamId}/${sessionKey}/self${
      conversationId ? `?selectedConversation=${conversationId}` : ''
    }`,
  selfRoutingSignupDependentRoute: (teamId, sessionKey) =>
    `/phone/signup/${teamId}/${sessionKey}/dependent`,
  linkExpiredRoute: () => '/link-expired',
  modMedCardResultRoute: () => '/payments/modmed/card/result',
  keycloakRedirectRoute: (teamId) => `/keycloak/ps/${teamId}`,
};
