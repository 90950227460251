import { FC, ReactNode } from 'react';
import styled from 'styled-components';

interface Props {
  /** Defines a title of the list which is showed above the list. */
  title?: string;

  /** Determines if the list has a top and bottom padding. */
  hasGutter?: boolean;

  /** Array of ListItem to display in the list. */
  children: ReactNode;
}

const ListContainer = styled.div<Props>`
  padding: ${({
    hasGutter,
    theme: {
      list: { padding },
    },
  }) => (hasGutter ? padding.big : padding.default)};
  width: 100%;
`;

const Title = styled.h3`
  font-size: ${({ theme }) => theme.fontSize};
  line-height: 1.2;
  font-weight: ${({ theme }) => theme.fontWeight};
  padding: 0 0 8px 0;
  margin: 0;
`;

const List: FC<Props> = ({ children, title, ...props }) => (
  <ListContainer data-component-name="list" {...props}>
    {title && <Title>{title}</Title>}
    {children}
  </ListContainer>
);

export default List;
