import { ButtonVariant, IconButton } from 'klara-ui';
import { FC } from 'react';
import * as React from 'react';

type BackgroundType = 'default' | 'dark' | 'image';

type Props = {
  onClick(e: React.FormEvent): void;
  isSmall?: boolean;
  backgroundType?: BackgroundType;
};

const getBackgroundType = (backgroundType: BackgroundType): ButtonVariant => {
  if (backgroundType === 'dark') return ButtonVariant.ghostWhite;
  if (backgroundType === 'image') return ButtonVariant.primary;
  return ButtonVariant.ghost;
};

const CloseButton: FC<Props> = ({ backgroundType, ...rest }) => (
  <IconButton isSmall iconName="close" variant={getBackgroundType(backgroundType)} {...rest} />
);

export default CloseButton;
