import logger from '@klara/logger';
import { reactRouterNavigateTo } from 'actions/LocationActions';
import { routeBuilders } from 'boot/patient/routes';
import config from 'config';
import { LoadingSpinner } from 'klara-ui/old';
import ExchangeKeycloakTokenEndpoint from 'persistence/patient/ExchangeKeycloakTokenEndpoint';
import { FC, ReactElement, ReactNode, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectors as currentPatientSelectors } from 'redux/modules/patient/currentPatient';
import { actions as loginActions } from 'redux/modules/patient/login';
import SessionService from 'services/SessionService';

const getTokens = async (code: string) => {
  const redirectUri = `${window.location.protocol}//${window.location.host}${window.location.pathname}${window.location.hash}`;

  try {
    const response = await ExchangeKeycloakTokenEndpoint.exchange({
      code,
      // Filter out search params since code is in it
      redirect_uri: redirectUri,
      client_id: config.KEYCLOAK_CLIENT_ID_PATIENT,
    });

    const tokens = await response.json();

    SessionService.setPatientRefreshToken(tokens.refresh_token);
    SessionService.setPatientAccessToken(tokens.access_token);
  } catch (error) {
    logger.error('Could not fetch the tokens', {}, error);
  }
};

const LoginRequired: FC<{ children: ReactNode }> = ({ children }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const patientIsLoading = useSelector(currentPatientSelectors.isLoading);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    // On load we want to;
    // else fetch do after login saga
    const checkAuth = async () => {
      logger.debug('LoginRequired: Checking patient auth');

      // If we got to the page with a magic link or login callback redirect
      const matchCode = window.location.href.match(/code=([^&/#]+)/);
      if (matchCode) {
        logger.debug('LoginRequired: Detectted new magic link', { matchCode });
        const code = matchCode[1];
        await getTokens(code);

        logger.debug('LoginRequired: Got tokens from magic link', { code });

        // clean the url from the token without reload
        const gotoUrl = window.location.pathname + window.location.hash;
        logger.debug('LoginRequired: Redirecting to the cleaned url', { gotoUrl });
        window.history.replaceState(null, '', gotoUrl);
        logger.debug('LoginRequired: The new hash', { hash: window.location.hash });
        history.replace(window.location.hash.slice(1));
      }

      // if after exchange no tokens redirect to login page
      const refreshToken = SessionService.getRefreshToken();
      if (!refreshToken) {
        logger.debug("LoginRequired: We do not have refresh token, let's log out");
        reactRouterNavigateTo(routeBuilders.logoutRoute());
        return;
      }

      // This function always needs to run after login saga
      logger.debug('LoginRequired: Calling afterLogin and finishing auth check');
      dispatch(loginActions.afterLogin());
      setIsLoading(false);
    };

    checkAuth().catch(() => {});
  }, []);

  if (patientIsLoading || isLoading || !SessionService.getToken())
    return <LoadingSpinner isLoading />;
  return children as ReactElement;
};

export default LoginRequired;
