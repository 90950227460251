const parseQuery = (queryString) => {
  const nameValuePairs = queryString && queryString.substr(queryString.indexOf('?') + 1).split('&');

  if (nameValuePairs) {
    return nameValuePairs.reduce((accumulator, currentValue) => {
      const [key, value] = currentValue.split('=');

      return { ...accumulator, [key]: value };
    }, {});
  }

  return {};
};

export default parseQuery;
