import HTTPClient from 'persistence/HTTPClient';
import { HTTPError } from 'persistence/HTTPError';
import addJsonHeaders from 'persistence/interceptors/addJsonHeaders';
import handleRedirect from 'persistence/interceptors/handleRedirect';
import handleUserNotLoggedIn from 'persistence/interceptors/handleUserNotLoggedIn';
import prefixUrl from 'persistence/interceptors/prefixUrl';
import { refreshTokenIfAccessTokenExpired } from 'persistence/interceptors/refreshTokenIfNeeded';
import returnJsonIfSuccessful from 'persistence/interceptors/returnJsonIfSuccessful';

function throwHttpErrorIfUnsuccessful({ status, body }) {
  // https:// fetch.spec.whatwg.org/#statuses
  if (status >= 200 && status <= 299) {
    return body;
  }

  throw HTTPError.fromStatusBody(status, body);
}

function ExternalClient(baseUrl) {
  const client = HTTPClient({
    requestInterceptors: [prefixUrl(baseUrl), addJsonHeaders],

    responseInterceptors: [
      refreshTokenIfAccessTokenExpired,
      handleRedirect,
      handleUserNotLoggedIn,
      returnJsonIfSuccessful,
    ],
  });

  return {
    fetch(url, options) {
      return client.fetch(url, options).then(throwHttpErrorIfUnsuccessful);
    },
  };
}

export default ExternalClient;
