import { FC, ReactNode } from 'react';
import styled, { keyframes } from 'styled-components';

import theme from '../../config/theme/klara';

const FigureAnimation = keyframes`
  0% {
    height: 0;
    width: 0;
    background-color: ${theme.colors.grey10};
  }
  29% {
    background-color: ${theme.colors.grey10};
  }
  30% {
    height: 2em;
    width: 2em;
    background-color: transparent;
    border-width: 1em;
    opacity: 1;
  }
  100% {
    height: 2em;
    width: 2em;
    border-width: 0;
    opacity: 0;
    background-color: transparent;
  }
`;

const Span = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
`;

const Loader = styled(Span)`
  overflow: visible;
  padding-top: 2em;
  height: 0;
  width: 2em;
`;

const LoaderFigure = styled(Span)`
  height: 0;
  width: 0;
  box-sizing: border-box;
  border: 0 solid ${theme.colors.grey10};
  border-radius: 50%;
  animation: ${FigureAnimation} 1s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
`;

const LoaderCentered = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  height: 100%;
  width: 100%;
`;

interface Props {
  isLoading: boolean;
  children?: ReactNode;
}

const LoadingComponent: FC<Props> = ({ isLoading, children }) => {
  if (isLoading) {
    return (
      <LoaderCentered>
        <Loader>
          <LoaderFigure data-test-id="loading-spinner" />
        </Loader>
      </LoaderCentered>
    );
  }
  return <>{children}</>;
};

export default LoadingComponent;
