/**
 * A quick overview of the file and what is **should** do:
 *
 * Usage:
 *  Call subscribe action when want to subscribe to a pusher event.
 *  Call unsubscribe action when finished listening to the action (please clean up after yourself).
 *  Sending a message:
 *    Make sure you are already subscribed to the channel you want to trigger a pusher event in
 *    Use the trigger action to dispatch the action with your data
 *    If subscribed to the channel just to send this message make sure you unsubscribe to clean up after yourself.
 *
 * How it works:
 *   On subscribe it authenticates and expects a pusher connection (should be created on login beforehand)
 *     starts to listen to the pusher event and dispaches redux actions with the "event" name from pusher
 *   On unsubscribe
 *     If we were the last listeners to the given channel and event, then unsubscribes from it
 *   On trigger
 *     If subscribed to the channel, it dispatches the action to pusher.
 */

import { RootState } from 'models/common.types';
import Pusher from 'pusher-js';
import { Reducer } from 'redux';
import logger from '@klara/logger';
import config from 'config';
import SessionService from 'services/SessionService';
import {
  Actions,
  PusherActionPayload,
  PusherActionTypes,
  PusherTriggerChannelAction,
} from './types';
import { pusherToDispatch } from './PusherToDispatch';

const EVENT_PREFIX = 'client';

interface State {
  pusherConnected: boolean;
  pusherConnecting: boolean;
}
const initialState: State = {
  pusherConnected: false,
  pusherConnecting: false,
};

export const actions = {
  login: (): Actions => ({
    type: PusherActionTypes.connect,
    payload: {},
  }),
  subscribe: (payload: PusherActionPayload): Actions => ({
    type: PusherActionTypes.subscribe,
    payload,
  }),
  unsubscribe: (payload: PusherActionPayload): Actions => ({
    type: PusherActionTypes.unsubscribe,
    payload,
  }),
  trigger: (payload: PusherTriggerChannelAction['payload']): Actions => ({
    type: PusherActionTypes.trigger,
    payload,
  }),
  logout: (): Actions => ({
    type: PusherActionTypes.logout,
    payload: {},
  }),
};

const getChannelName = (entity: string, id: number): string => `private-${entity}-${id}`;
const getEventName = (event: string) => `${EVENT_PREFIX}-${event}`;
const getActionTypeName = (event: string, postfix: string) => event + postfix;

export const selectors = {
  isConnected: (state: RootState): boolean => state.pusher.pusherConnected,
};

export const reducer: Reducer<State, Actions> = (
  state: State = initialState,
  { type, payload }
) => {
  switch (type) {
    case PusherActionTypes.connect:
      pusherToDispatch.setClient(
        new Pusher(config.PUSHER_API_KEY, {
          cluster: config.PUSHER_CLUSTER || 'us2',
          channelAuthorization: {
            endpoint: `${config.API_BASE_URL}/pusher/session`,
            transport: 'ajax',
            params: {},
            headersProvider: () => {
              return { 'X-AUTH-TOKEN': SessionService.getToken() };
            },
          },
          userAuthentication: {
            endpoint: `${config.API_BASE_URL}/pusher/session`,
            transport: 'ajax',
            params: {},
            headersProvider: () => {
              return { 'X-AUTH-TOKEN': SessionService.getToken() };
            },
          },
        })
      );
      return state;
    case PusherActionTypes.connected:
      logger.debug('Pusher connected');
      return { ...state, pusherConnected: true, pusherConnecting: false };
    case PusherActionTypes.connecting:
      logger.debug('Pusher connecting');
      return { ...state, pusherConnecting: true };
    case PusherActionTypes.disconnected:
      logger.warn('Pusher is disconnected.');
      return { ...state, pusherConnected: false, pusherConnecting: false };
    case PusherActionTypes.unavailable:
      logger.error('Pusher is unavaiable');
      return { ...state, pusherConnected: false, pusherConnecting: false };
    case PusherActionTypes.failed:
      logger.error('Connecting to pusher failed.');
      return { ...state, pusherConnected: false, pusherConnecting: false };
    case PusherActionTypes.subscribe: {
      pusherToDispatch.subscribe(
        getChannelName(payload.entity, payload.entityId),
        getEventName(payload.event),
        getActionTypeName(payload.event, payload.postfix)
      );
      return state;
    }
    case PusherActionTypes.unsubscribe: {
      pusherToDispatch.unsubscribe(
        getChannelName(payload.entity, payload.entityId),
        getEventName(payload.event),
        getActionTypeName(payload.event, payload.postfix)
      );
      return state;
    }
    case PusherActionTypes.trigger: {
      const channelName = getChannelName(payload.entity, payload.entityId);
      const eventName = getEventName(payload.event);
      pusherToDispatch.trigger(channelName, eventName, payload.data);
      return state;
    }
    case PusherActionTypes.logout: {
      return initialState;
    }
    default:
      return state;
  }
};
