const addJsonHeaders = (request: Request): Request => {
  const defaultHeaders: Record<string, string> = {
    Accept: 'application/json',
  };

  if (!(request.body instanceof FormData)) {
    defaultHeaders['Content-Type'] = 'application/json';
  }

  return {
    ...request,
    headers: {
      ...request.headers,
      ...defaultHeaders,
    },
  };
};

export default addJsonHeaders;
