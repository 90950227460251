import { Component } from 'react';
import PropTypes from 'prop-types';
import SearchDropdown from './SearchDropdown';

// TODO: This is a wrapper component of SearchDropdown. Delete this when there are no dependencies left.
//  This component is only left here for backward compatibility.
export default class SearchableDropdown extends Component {
  static displayName = 'SearchableDropdown';

  static propTypes = {
    onMenuClose: PropTypes.func,
    onMenuOpen: PropTypes.func,
  };

  state = {
    isOpen: false,
  };

  onClose = () => {
    this.setState({ isOpen: false });
    if (this.props.onMenuClose) {
      this.props.onMenuClose();
    }
  };

  onOpen = () => {
    this.setState({ isOpen: true });
    if (this.props.onMenuOpen) {
      this.props.onMenuOpen();
    }
  };

  render() {
    return (
      <SearchDropdown
        isOpen={this.state.isOpen}
        onClose={this.onClose}
        onOpen={this.onOpen}
        width="320px"
        {...this.props}
      />
    );
  }
}
