import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
  border: none;
  overflow-y: scroll;
  padding: 0 30px;
  background: ${(props) => props.backgroundColor};
  border-radius: 6px;
  max-height: ${(props) => props.maxHeight};
`;

Container.displayName = 'Container';

class OptionsContainer extends PureComponent {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.element,
      PropTypes.arrayOf(PropTypes.node),
    ]),
    maxHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    backgroundColor: PropTypes.string,
  };

  static defaultProps = {
    maxHeight: '100vh',
    backgroundColor: 'rgba(29,29,27,0.95)',
  };

  render() {
    const { children, maxHeight, backgroundColor, ...rest } = this.props;
    let maxHeightValue = Number.isNaN(maxHeight) ? `${maxHeight}px` : maxHeight;

    return (
      <Container {...rest} maxHeight={maxHeightValue} backgroundColor={backgroundColor}>
        {children}
      </Container>
    );
  }
}

export default OptionsContainer;
