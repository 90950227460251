import * as React from 'react';
import { T, TProps } from './T';
import { DSTokenProps } from '../../hoc/withDSTokens';

const HeadingText1: React.FC<TProps & DSTokenProps & {children: React.ReactNode}& {children: React.ReactNode}> = (props) => {
  const { htmlTag = 'h1' } = props;
  return <T variant="h1" htmlTag={htmlTag} {...props} />;
};

export default HeadingText1;
