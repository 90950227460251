import { HttpClientType } from 'models/common.types';
import config from 'config';
import ExternalClient from 'services/ExternalClient';

export function ExchangeTokenWidgetEndpoint(httpClient: HttpClientType) {
  return {
    startAuth(phoneNumber: string) {
      return httpClient.fetch('start_auth', {
        method: 'POST',
        body: JSON.stringify({ phone_number: phoneNumber }),
      });
    },
  };
}

export default ExchangeTokenWidgetEndpoint(ExternalClient(config.BACKEND_WIDGET_URL));
