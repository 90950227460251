import logger from '@klara/logger';
import Link from 'elements/Link';
import { ElementsSeperator } from 'elements/Section';
import ErrorIllustration from 'images/illu_error.svg';
import { theme, Modal, H1, TextBlock, Button } from 'klara-ui/old';
import { FC, ReactElement } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import styled from 'styled-components';

const logError = (error: Error, info: { componentStack: string }) => {
  logger.error('Error boundary: application crashed', { stack: info.componentStack }, error);
};

const StyledImg = styled.img`
  width: 250px;
`;

const StyledLink = styled(Link)`
  color: ${theme.colors.primaryBrand};
  font-weight: bold;
`;

export const ErrorFallback: FC = () => (
  <Modal center fullscreen>
    <div>
      <StyledImg src={ErrorIllustration} alt="Sorry, something went wrong" />
      <ElementsSeperator />
      <H1>Sorry, something went wrong</H1>
      <ElementsSeperator />
      <TextBlock>
        <span>Please try again or </span>
        <StyledLink href="mailto:support@klara.com">contact us</StyledLink>
      </TextBlock>
      <ElementsSeperator />
      <Button size="medium" onClick={() => window.location.reload()}>
        Go back
      </Button>
    </div>
  </Modal>
);

const Error: FC<{ children: ReactElement }> = ({ children }) => (
  <ErrorBoundary FallbackComponent={ErrorFallback} onError={logError}>
    {children}
  </ErrorBoundary>
);

export default Error;
