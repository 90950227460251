import React, { ReactElement, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import klaraTheme from '../../config/theme/klara';
import Checkbox from '../Checkbox';
import TextBlock from '../typography/TextBlock';
import { OptionRow } from './OptionRow';

const RowContainer = styled.label<{ disabled: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${(props) =>
    props.disabled
      ? null
      : css`
          > * {
            cursor: pointer;
          }
        `}
`;

const StyledCheckbox = styled(Checkbox)`
  &[type='checkbox'] {
    margin: 0;
  }
  flex: 0 0 auto;
`;

const ValueContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  margin-left: 8px;
`;

const Subtitle = styled.span`
  font-size: ${klaraTheme.fonts.sizes.s};
  color: ${klaraTheme.colors.grey60};
`;

interface CheckboxOptionRowProps {
  onCheck: (value: boolean) => void;
  checked: boolean;
  title: string | ReactNode;
  subtitle?: string | ReactNode;
  renderRight?: () => JSX.Element;
  className?: string;
  disabled?: boolean;
  checkboxProps?: any;
  theme: 'dark' | 'light';
  dataTestId?: string;
  [rest: string]: any;
}

export const CheckboxOptionRow = (props: CheckboxOptionRowProps): ReactElement => {
  const {
    checked,
    title,
    subtitle,
    disabled,
    theme = 'dark',
    checkboxProps = {},
    renderRight,
    className,
    onCheck,
    ...rest
  } = props;

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onCheck) {
      onCheck(event.target.checked);
    }
  };

  const renderLeft = () => {
    const titleColor = () => {
      if (disabled) {
        return klaraTheme.colors.grey50;
      }

      return theme === 'dark' ? klaraTheme.colors.white : klaraTheme.colors.black;
    };

    return (
      <RowContainer disabled={disabled}>
        <StyledCheckbox
          key={Math.random()}
          theme={theme}
          checked={!!checked}
          disabled={disabled}
          onChange={handleCheckboxChange}
          {...checkboxProps}
        />

        <ValueContainer>
          {typeof title === 'string' ? <TextBlock color={titleColor()}>{title}</TextBlock> : title}
          {typeof subtitle === 'string' ? <Subtitle>{subtitle}</Subtitle> : subtitle}
        </ValueContainer>
      </RowContainer>
    );
  };

  return (
    <OptionRow {...rest} className={className} renderLeft={renderLeft} renderRight={renderRight} />
  );
};

export default CheckboxOptionRow;
