import PropTypes from 'prop-types';
import { Component } from 'react';
import styled from 'styled-components';
import OptionRow from './OptionRow';
import IconOptionRow from './IconOptionRow';
import AvatarOptionRow from './AvatarOptionRow';
import theme from '../../config/theme/klara';
import Icon from '../Icons/Icon';

const Container = styled.div`
  color: ${(props) => (props.active ? theme.colors.white : theme.colors.grey10)};
`;

const StyledChevronIcon = styled.div`
  margin-right: 5px;

  & svg {
    color: ${theme.colors.grey50};
  }
`;

const TitleContainer = styled.div`
  color: ${theme.colors.grey60};
`;

class SectionHeader extends Component {
  renderLeft = () => <TitleContainer> {this.props.title} </TitleContainer>;

  renderRight = () => {
    const { expandable, active } = this.props;
    if (expandable) {
      return active ? (
        <StyledChevronIcon>
          <Icon name="chevron-up" />
        </StyledChevronIcon>
      ) : (
        <StyledChevronIcon>
          <Icon name="chevron-down" />
        </StyledChevronIcon>
      );
    }

    return null;
  };

  onClick = (e) => {
    const { onClick, ...props } = this.props;

    if (onClick) onClick(props, e);
  };

  render() {
    const {
      icon,
      title,
      active,
      className,
      showAvatar,
      avatarUrl,
      dataTestId = 'section-header',
    } = this.props;

    return (
      <Container data-test-id={dataTestId} className={className} active={active}>
        {icon && (
          <IconOptionRow
            title={title}
            icon={icon}
            onClick={this.onClick}
            renderRight={this.renderRight}
          />
        )}
        {showAvatar && (
          <AvatarOptionRow
            title={title}
            url={avatarUrl}
            onClick={this.onClick}
            renderRight={this.renderRight}
          />
        )}
        {!icon && !showAvatar && title && (
          <OptionRow
            onClick={this.onClick}
            renderLeft={this.renderLeft}
            renderRight={this.renderRight}
          />
        )}
      </Container>
    );
  }
}

SectionHeader.propTypes = {
  icon: PropTypes.func,
  avatarUrl: PropTypes.string,
  title: PropTypes.string,
  onClick: PropTypes.func,
  active: PropTypes.bool,
  className: PropTypes.string,
  expandable: PropTypes.bool.isRequired,
  showAvatar: PropTypes.bool,
  dataTestId: PropTypes.string,
};

SectionHeader.displayName = 'SectionHeader';

export default SectionHeader;
