import PropTypes from 'prop-types';
import styled from 'styled-components';
import TextareaAutosize from 'react-textarea-autosize';

const Input = styled(TextareaAutosize)`
  width: 100%;
  box-sizing: border-box;
  border: none;
  margin: 10px 0;
  outline: none;
  font-size: 16px;
  resize: none !important;
  padding: 8px 16px;
  color: #1d1d1b;
  ::-webkit-input-placeholder {
    color: #8e8e8d;
  }
  ::-moz-placeholder {
    color: #8e8e8d;
  }
  :-ms-input-placeholder {
    color: #8e8e8d;
  }
  :-moz-placeholder {
    color: #8e8e8d;
  }
  ::-webkit-scrollbar {
    width: 10px;
    background-color: #fff;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #f5f5f5;
    border-radius: 5px;
  }
`;

const AutoSizeTextArea = ({ input, meta: _, ...props }) => <Input {...input} {...props} />;

AutoSizeTextArea.propTypes = {
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  style: PropTypes.shape({}),
  className: PropTypes.string,
  onHeightChange: PropTypes.func,
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func,
    value: PropTypes.string,
  }),
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
  minRows: PropTypes.number,
  maxRows: PropTypes.number,
  meta: PropTypes.shape({}),
};

AutoSizeTextArea.defaultProps = {
  maxRows: 4,
};

AutoSizeTextArea.displayName = 'DropdownMenu';

export default AutoSizeTextArea;
