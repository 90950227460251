import { FC, ReactNode } from 'react';
import styled from 'styled-components';
import Modal from '../../old/components/modals/Modal';
import { BodyText2, HeadingText1 } from '../../old/components/typography';

const ActionButtonsContainer = styled.div`
  margin-top: 32px;
  align-items: center;
  display: inline-flex;
  gap: 12px;
`;

interface Props {
  /** The main title of the modal. */
  title: string;

  /** The content between the title and the button. Duh :D.
   * Limited to string as we wanna display only a specific type of text.
   */
  children?: string;

  /**
   * The action buttons will be displayed under the text in the modal.
   * These elements will have a default spacing applied to them.
   */
  actionButtons?: ReactNode;
}

const ConfirmationModalImproved: FC<Props> = ({ title, children, actionButtons, ...props }) => (
  <Modal center fullscreen {...props}>
    <HeadingText1>{title}</HeadingText1>
    <BodyText2>{children}</BodyText2>
    <ActionButtonsContainer>{actionButtons}</ActionButtonsContainer>
  </Modal>
);

export default ConfirmationModalImproved;
