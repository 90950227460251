import { DateObjectUnits, DateTime } from 'luxon';

function isDateObject(dateObj: any): dateObj is DateTime {
  return dateObj && (dateObj.year || dateObj.month || dateObj.day);
}

export default function (dateInput: Date | string | DateTime | undefined): DateTime {
  if (dateInput instanceof Date) {
    return DateTime.fromJSDate(dateInput);
  }
  if (isDateObject(dateInput)) {
    // Here's object from input form in format { year: 1990, month: 1, day: 1 }
    return DateTime.fromObject(dateInput as DateObjectUnits);
  }
  if (dateInput) {
    // We assume ISO 8601 date string here
    return DateTime.fromISO(dateInput);
  }
  // inputDate is null or undefined
  return DateTime.now();
}
