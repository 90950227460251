import * as React from 'react';

import withDSTokens from '../../hoc/withDSTokens';
import { Col, Row } from '../Grid';
import HeadingText2 from './HeadingText2';

const H2: React.FC<{children: React.ReactNode}>  = ({ children, ...props }) => (
  <Row {...props}>
    <Col p={0} xs={12} pb={3}>
      <HeadingText2>{children}</HeadingText2>
    </Col>
  </Row>
);

H2.displayName = 'H2';

export default withDSTokens(H2);
