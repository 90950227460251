export default function prefixUrl(prefix) {
  const prefixWithoutTrailingSlash = prefix?.replace(/\/$/, '');

  return (request) => {
    const locationFragment = request?.url?.replace(/^\//, '');

    return Object.assign(request, {
      url: `${prefixWithoutTrailingSlash || ''}/${locationFragment}`,
    });
  };
}
