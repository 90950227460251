import { FC } from 'react';
import styled from 'styled-components';
import { SpanValues } from './types';

interface Props {
  /** Defines column span of the grid item. */
  span?: SpanValues;

  /** Defines row span of the grid item. */
  rowSpan?: SpanValues;

  /** Defines in which horizontal direction should the grid item align. */
  justify?: 'normal' | 'center' | 'start' | 'end';

  /** Content of the grid item. */
  children: React.ReactNode;
}

interface GridItemContainerProps {
  span: SpanValues;
  justify: 'normal' | 'center' | 'start' | 'end';
  rowSpan: SpanValues;
}

const GridItemContainer = styled.div<GridItemContainerProps>`
  display: grid;
  grid-column: ${({ span }) => `span ${span}`};
  grid-row: ${({ rowSpan }) => `span ${rowSpan}`};
  justify-content: ${({ justify }) => justify};
`;

const GridItem: FC<Props> = ({ span = 1, rowSpan = 1, justify = 'normal', children, ...props }) => {
  return (
    <GridItemContainer span={span} rowSpan={rowSpan} justify={justify} {...props}>
      {children}
    </GridItemContainer>
  );
};

export default GridItem;
