import { FC } from 'react';
import styled from 'styled-components';

const StyledLabel = styled.label`
  padding-left: 8px;
`;

const Space = styled.span`
  margin-right: 20px;
`;

const RadioButtonContainer = styled.div`
  padding: 12px;
  display: flex;
  & input,
  label {
    cursor: pointer;
  }
`;

interface Props {
  options: {
    name: string;
    label: string;
    value: string;
  }[];
  selected: string;
  onChange: (key: string) => void;
}

const RadioButton: FC<Props> = ({ options, selected, onChange, ...props }) => (
  <RadioButtonContainer data-component-name="radio-button-container" {...props}>
    {options?.map(({ name, label, value }) => (
      <div key={value} onClick={() => onChange(value)}>
        <input
          type="radio"
          name={name}
          value={value}
          id={value}
          checked={value === selected}
          onChange={(e) => onChange(e.target.value)}
        />
        <StyledLabel htmlFor={value}>{label}</StyledLabel>
        <Space />
      </div>
    ))}
  </RadioButtonContainer>
);

export default RadioButton;
