import gridConfig from './grid';
import colorsExport from './exports/color';

const parseColors = () => {
  let colorpallete = {};

  Object.keys(colorsExport.color).forEach((key) => {
    colorpallete[key] = colorsExport.color[key].hex;
  });

  return colorpallete;
};

export default {
  colorpallete: parseColors(),
  spacing: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  zIndex: {
    base: 0,
    modal: 9999,
  },
  border: [0, 1, 2],
  borderRadius: [0, 8],
  ...gridConfig,
};
