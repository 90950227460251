import styled from 'styled-components';
import withDSTokens from '../../hoc/withDSTokens';

interface FixedContainerProps {
  bottom?: number;
  left?: number;
  right?: number;
  top?: number;
  z?: number;
  sticky?: boolean;
}

const FixedContainer = styled.div<FixedContainerProps>`
  position: ${({ sticky }) => (sticky ? 'sticky' : 'fixed')};

  ${({ top = null, right = null, bottom = null, left = null, z = null }) => `
    ${top !== null ? `top: ${top}px;` : ''}
    ${right !== null ? `right: ${right}px;` : ''}
    ${bottom !== null ? `bottom: ${bottom}px;` : ''}
    ${left !== null ? `left: ${left}px;` : ''}
    z-index: ${z || '1'};
  `};
`;

export default withDSTokens<FixedContainerProps>(FixedContainer);
