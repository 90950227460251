import styled from 'styled-components';
import { T } from '../typography';
import Icon, { IconProps } from './Icon';

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 15px 0;

  [data-test-id='icon-label'] {
    /**
     * !important is needed because T (component) uses inline style
     * which has more priority than the below rule
     */
    display: none !important;
  }

  @media (min-width: 768px) {
    [data-test-id='icon-label'] {
      display: block !important;
    }
  }
`;

interface IconWithLabel extends IconProps {
  onClick?(): void;
  label: string;
}

export function IconWithLabel({ label, onClick, ...iconProps }: IconWithLabel) {
  return (
    <IconContainer onClick={onClick}>
      <Icon {...iconProps} />
      <T data-test-id="icon-label" c="white" pl={3}>
        {label}
      </T>
    </IconContainer>
  );
}
