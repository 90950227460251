import { ComponentType, CSSProperties, ReactNode } from 'react';

import colorExport from '../tokens/exports/color/index.json';
import utilsExport from '../tokens/exports/utils/index.json';

// FIXME: revisit this strategy in future
export type Color = keyof typeof colorExport.color;
export type Spacing = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
export type Border = 0 | 1 | 2;
export type BorderRadius = 0 | 8;
export type BorderStyle = 'solid' | 'dashed';
export type SupportedPosition = 'static' | 'relative' | 'absolute' | 'fixed';
export type SupportedAlignment = 'left' | 'center' | 'right';
export type SupportedDisplayAttribute = 'initial' | 'block' | 'inline' | 'none';
export type ZIndex = 0 | 50 | 100 | 200 | 300 | 400 | 500;

type SpacerName =
  | 'space-1'
  | 'space-2'
  | 'space-3'
  | 'space-4'
  | 'space-5'
  | 'space-6'
  | 'space-7'
  | 'space-8'
  | 'space-9'
  | 'space-10'
  | 'space-11'
  | 'space-12';
const calculateSpace = (property: number) =>
  `${utilsExport.utils[`space-${property}` as SpacerName].spacer / 16}rem`;

export interface DSTokenProps {
  pt?: Spacing;
  pr?: Spacing;
  pb?: Spacing;
  pl?: Spacing;
  ph?: Spacing;
  pv?: Spacing;
  p?: Spacing;
  bg?: string;
  bgc?: Color;
  bgp?: string;
  bgs?: string;
  bs?: 'border-box' | 'content-box';
  fullHeight?: boolean;
  fullWidth?: boolean;
  circleBorder?: boolean;
  roundedBorder?: boolean;
  align?: SupportedAlignment;
  bt?: Border;
  br?: Border;
  bb?: Border;
  bl?: Border;
  b?: Border;
  brad?: BorderRadius;
  bst?: BorderStyle;
  bc?: Color;
  c?: Color;
  pos?: SupportedPosition;
  z?: ZIndex;
  w?: number;
  h?: number;
  d?: SupportedDisplayAttribute;
  lineHeight?: number;
  pointerEvents?: 'auto' | 'none';
  style?: CSSProperties;
  cursor?: 'default' | 'pointer';
  children?: ReactNode;
}

// TODO: Find a better solution for withDSToken (use hook for example)
// eslint-disable-next-line @typescript-eslint/ban-types
const withDSTokens =
  <T extends Record<string, any>>(
    Component: ComponentType<T>,
    defaultProps: Record<string, string | number> = {}
  ): ComponentType<T & DSTokenProps> =>
  ({
    z,
    p,
    ph,
    pv,
    pt,
    pr,
    pb,
    pl,
    bgc,
    bg,
    bgp,
    bgs,
    b,
    bs,
    bt,
    br,
    bb,
    bl,
    brad,
    bc,
    align,
    c,
    w,
    h,
    fullHeight = false,
    fullWidth = false,
    circleBorder = false,
    roundedBorder = false,
    lineHeight = defaultProps.lineHeight ?? 1,
    d,
    pos,
    pointerEvents,
    style = {},
    cursor,
    bst = 'solid',
    ...props
  }) => {
    const dsStyle: CSSProperties = {};
    // z-index
    if (z > 0) {
      dsStyle.zIndex = z;
    }
    // spacing
    if (p !== undefined && typeof p === 'number') {
      dsStyle.padding = calculateSpace(p);
    }
    if (pv !== undefined && typeof pv === 'number') {
      dsStyle.paddingTop = calculateSpace(pv);
      dsStyle.paddingBottom = calculateSpace(pv);
    }
    if (ph !== undefined && typeof ph === 'number') {
      dsStyle.paddingLeft = calculateSpace(ph);
      dsStyle.paddingRight = calculateSpace(ph);
    }
    if (pt !== undefined && typeof pt === 'number') {
      dsStyle.paddingTop = calculateSpace(pt);
    }
    if (pb !== undefined && typeof pb === 'number') {
      dsStyle.paddingBottom = calculateSpace(pb);
    }
    if (pl !== undefined && typeof pl === 'number') {
      dsStyle.paddingLeft = calculateSpace(pl);
    }
    if (pr !== undefined && typeof pr === 'number') {
      dsStyle.paddingRight = calculateSpace(pr);
    }
    // borders
    if (b > 0) {
      dsStyle.border = `${b}px ${bst}`;
    }
    if (bt > 0) {
      dsStyle.borderTop = `${bt}px ${bst}`;
    }
    if (br > 0) {
      dsStyle.borderRight = `${br}px ${bst}`;
    }
    if (bl > 0) {
      dsStyle.borderLeft = `${bl}px ${bst}`;
    }
    if (bb > 0) {
      dsStyle.borderBottom = `${bb}px ${bst}`;
    }
    if (brad > 0 && !circleBorder && !roundedBorder) {
      dsStyle.borderRadius = `${brad}px`;
    }
    if (circleBorder) {
      dsStyle.borderRadius = '50%';
    }
    if (roundedBorder) {
      dsStyle.borderRadius = '100vh';
    }
    if (bc) {
      dsStyle.borderColor = colorExport?.color[bc]?.hex;
    }
    if (w) {
      dsStyle.width = `${w}px`;
    }
    if (h) {
      dsStyle.height = `${h}px`;
    }
    // backgrounds
    if (bgc) {
      dsStyle.backgroundColor = colorExport?.color[bgc]?.hex;
    }
    if (bg) {
      dsStyle.background = `url("${bg}") no-repeat`;
      dsStyle.backgroundSize = 'contain';
    }
    if (bgp) {
      dsStyle.backgroundPosition = bgp;
    }
    if (bgs) {
      dsStyle.backgroundSize = bgs;
    }

    // font-color
    if (c) {
      dsStyle.color = colorExport?.color[c]?.hex;
    }

    // fullheight
    if (fullHeight) {
      dsStyle.height = '100%';
    }

    if (lineHeight) {
      dsStyle.lineHeight = lineHeight;
    }

    // fullWidth
    if (fullWidth) {
      dsStyle.width = '100%';
    }

    // boxSizing
    if (bs) {
      dsStyle.boxSizing = bs;
    }

    // display
    if (d) {
      dsStyle.display = d;
    }

    // position
    if (pos) {
      dsStyle.position = pos;
    }

    // alignment
    if (align) {
      dsStyle.textAlign = align;
    }
    if (lineHeight) {
      dsStyle.lineHeight = lineHeight;
    }

    if (pointerEvents) {
      dsStyle.pointerEvents = pointerEvents;
    }

    if (cursor) {
      dsStyle.cursor = cursor;
    }

    return <Component {...(props as T)} style={{ ...style, ...dsStyle }} />;
  };

export default withDSTokens;
