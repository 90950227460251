import PropTypes from 'prop-types';
import classnames from 'classnames';
import Select from 'react-select';
import { OptionRow, withWindowSize } from 'klara-ui/old';
import { Menu } from './AutoCompleteElements';

const renderLeft = (label) => () => <div>{label}</div>;

const renderOption = ({ data: { label }, innerProps }) => (
  <OptionRow {...innerProps} dataTestId="select-option" renderLeft={renderLeft(label)} />
);

const customStyles = {
  control: (provided) => ({
    ...provided,
    borderRadius: 6,
  }),
};

renderOption.propTypes = {
  data: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  innerProps: PropTypes.shape({}),
  dataComponentName: PropTypes.string,
};

const Field = ({
  className,
  disabled,
  isMobile,
  label,
  options,
  placeholder,
  meta: { error, touched, submitFailed },
  dataComponentName = '',
  ...inputProps
}) => {
  const fieldClassName = classnames(
    `form__input-wrapper form__input-wrapper--${inputProps.input.name}`,
    className,
    { 'form__input-wrapper--error': error && touched && submitFailed }
  );

  const handleChange = (args) => {
    inputProps.input.onChange(args.value);
  };

  return (
    <div className={fieldClassName} data-component-name={dataComponentName}>
      {/* eslint-disable-next-line */}
      <label className="form__label">
        {label}
        <Select
          className="form__input form__input--react-select"
          classNamePrefix="Select"
          styles={customStyles}
          name={inputProps.input.name}
          /**
           * Key to make possible reset select to placeholder if there are no initially selected value
           * https://stackoverflow.com/questions/50412843/how-to-programmatically-clear-reset-react-select
           * */
          key={`select_key_${inputProps.input.value}`}
          isClearable={false}
          isDisabled={disabled}
          isSearchable={!isMobile}
          options={options}
          placeholder={placeholder}
          value={options.find((option) => inputProps.input.value === option.value)}
          components={{
            Option: renderOption,
            Menu,
          }}
          onChange={handleChange}
        />
      </label>
    </div>
  );
};

Field.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  isMobile: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  meta: PropTypes.shape({
    error: PropTypes.bool,
    touched: PropTypes.bool,
    submitFailed: PropTypes.bool,
  }),
  options: PropTypes.array.isRequired,
};

export default withWindowSize(Field);
