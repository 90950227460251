import { PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { PatientMatchOptionsResponse } from 'concerns/patient/scheduling/types';
import { isObject } from 'lodash';
import { DateString } from 'models/common.types';
import {
  ConvertedProviderType,
  ExtendedDepartmentType,
  ProviderType,
  ReasonType,
  SlotType,
} from 'models/patientTypes';
import { ContactDetailsInitialValues } from 'redux/forms/patient/publicScheduling/@types/ContactDetails';
import { InsuranceDetailsInitialValues } from 'redux/forms/patient/publicScheduling/@types/InsuranceDetails';

import { SchedulingState, SchedulingStep, initialState } from './schedulingSlice';

export const reducers: SliceCaseReducers<SchedulingState> = {
  resetSlots(state) {
    state.selectedLocation = null;
    state.selectedProvider = null;
    state.slotSelection = initialState.slotSelection;
  },

  resetState(state) {
    return state;
  },

  resetReasons(state) {
    state.selectedReason = null;
    state.selectedReasonGroup = null;
  },

  onSelectReasonGroup(state, { payload }: PayloadAction<ReasonType>) {
    state.selectedReasonGroup = payload;
  },

  onSelectReason(state, { payload }: PayloadAction<ReasonType>) {
    state.selectedReason = payload;
  },

  onLocationChange(
    state,
    {
      payload: { newLocation, newProvider },
    }: PayloadAction<{ newLocation: ExtendedDepartmentType; newProvider?: ConvertedProviderType }>
  ) {
    state.selectedLocation = newLocation;
    state.selectedProvider = newProvider || state.selectedProvider;
  },

  onProviderChange(state, { payload }: PayloadAction<ProviderType>) {
    state.selectedProvider = payload;
  },

  setStartDate(state, { payload }: PayloadAction<DateString>) {
    state.slotSelection.startDate = payload;
  },

  setSelectedDate(state, { payload }: PayloadAction<DateString>) {
    state.slotSelection.selectedDate = payload;
  },

  setPatientInformation(
    state,
    { payload }: PayloadAction<InsuranceDetailsInitialValues & ContactDetailsInitialValues>
  ) {
    state.patientInformation.insuranceCarrier = payload.insuranceCarrier;
    state.patientInformation.insurancePlan = payload.insurancePlan;
    state.patientInformation.memberId = payload.memberId;
    state.patientInformation.patientHasHealthInsurance = Boolean(payload.patientHasHealthInsurance);
    state.patientInformation.patientEmail = payload.email;
    state.patientInformation.patientZip = payload.zip;
  },

  setPatientProfileData(
    state,
    {
      payload,
    }: PayloadAction<
      Pick<
        SchedulingState['patientInformation'],
        'patientName' | 'patientLastname' | 'patientEmail' | 'patientDOB' | 'patientIsDependent'
      >
    >
  ) {
    state.patientInformation.patientName = payload.patientName;
    state.patientInformation.patientLastname = payload.patientLastname;
    state.patientInformation.patientDOB = payload.patientDOB;
    state.patientInformation.patientIsDependent = payload.patientIsDependent;
  },

  onGoToNextStep(state) {
    state.currentStep =
      state.currentStep === SchedulingStep.APPOINTMENT_SUMMARY_STEP
        ? state.currentStep
        : state.currentStep + 1;
    state.allStepsCompleted =
      state.currentStep + 1 === SchedulingStep.APPOINTMENT_SUMMARY_STEP
        ? true
        : state.allStepsCompleted;
  },

  onGoToPreviousStep(state) {
    state.currentStep =
      state.currentStep === SchedulingStep.REASON_GROUP_SELECTION_STEP
        ? state.currentStep
        : state.currentStep - 1;
  },
  onGoToStep(state, { payload }: PayloadAction<SchedulingStep>) {
    state.currentStep = payload;
    state.allStepsCompleted =
      payload === SchedulingStep.APPOINTMENT_SUMMARY_STEP ? true : state.allStepsCompleted;
  },

  onSelectSlot(
    state,
    { payload: { provider, slot } }: PayloadAction<{ slot: SlotType; provider: ProviderType }>
  ) {
    state.slotSelection.selectedSlot = slot;
    state.selectedProvider = provider;
  },

  onGoToNextAvailableSlots(state, { payload }: PayloadAction<any>) {
    state.slotSelection.selectedSlot = payload;
  },

  onSetExitSchedulingModalVisible(state, { payload }: PayloadAction<boolean>) {
    state.exitSchedulingModalVisible = payload;
  },

  onSetFailedBookingModalVisible(state, { payload }: PayloadAction<boolean>) {
    state.exitSchedulingModalVisible = payload;
  },

  resetSchedulingState() {
    return initialState;
  },

  onSetHasPrefilledInsuranceImages(state, { payload }: PayloadAction<boolean>) {
    state.hasPrefilledInsuranceImages = payload;
  },

  onSelectPatientMatch(
    state,
    { payload }: PayloadAction<PatientMatchOptionsResponse['result'][number]['id']>
  ) {
    state.patientMatchId = payload;
  },

  onFirstUnavailableProvidersRender(state, { payload }: PayloadAction<{ providerId?: string }>) {
    const { providerId } = payload;
    const { id: selectedProviderId } = state.selectedProvider;
    const { unavailableProvidersRenderedAt } = state.slotSelection;

    if (providerId) {
      state.slotSelection.unavailableProvidersRenderedAt = {
        ...(isObject(unavailableProvidersRenderedAt) ? unavailableProvidersRenderedAt : {}),
        [providerId]: state.slotSelection.selectedDate,
      };

      return;
    }

    state.slotSelection.unavailableProvidersRenderedAt =
      selectedProviderId === null
        ? state.slotSelection.selectedDate
        : {
            ...(isObject(unavailableProvidersRenderedAt) ? unavailableProvidersRenderedAt : {}),
            [selectedProviderId]: state.slotSelection.selectedDate,
          };
  },
};

export const extraReducers = () => ({}); // (builder: ActionReducerMapBuilder<SchedulingState>) => {};
