import styled from 'styled-components';

interface Props {
  /** Setting this to true adds a small space between each individual items. */
  hasGutter?: boolean;

  /** Setting this to true positions your item evenly separated on the space. */
  spaceBetween?: boolean;

  /** Setting this to true allow your item to go full width. */
  fullWidth?: boolean;

  /** Align elements individual items on the left. */
  alignLeft?: boolean;

  /** Defines if it should stack elements inside the item. */
  column?: boolean;
}

export const Split = styled.div<Props>`
  display: flex;
  align-items: ${({ alignLeft }) => (alignLeft ? 'left' : 'center')};
  gap: ${({ hasGutter }) => (hasGutter ? '12px' : '0')};
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
  justify-content: ${({ spaceBetween }) => (spaceBetween ? 'space-between' : '')};
  flex-direction: ${({ column }) => (column ? 'column' : 'row')};
`;

interface ItemProps {
  /**
   * Adds flex-grow: 1 property to the element.
   * It can be used to make the element fill up the remaining space vertically.
   * When applied to multiple sibling elements they will share the space evenely.
   */
  isFilled?: boolean;

  /** Defines if it should try to center elements inside the item. */
  center?: boolean;
}

export const SplitItem = styled.div<ItemProps>`
  ${({ center }) =>
    center
      ? `
    display: flex;
    justify-items: center;
    align-items: center;
    justify-content: space-around;
  `
      : 'display: block;'}
  ${({ isFilled }) => (isFilled ? 'flex-grow: 1;' : '')}
`;
