export enum CorePusherEvents {
  messageSent = 'MESSAGE_SENT',
  messageRead = 'MESSAGE_READ',
  conversationReassigned = 'CONVERSATION_REASSIGNED',
  signatureRequestSigned = 'SIGNATURE_REQUEST_SIGNED',
  signatureRequestProcessed = 'SIGNATURE_REQUEST_PROCESSED',
  documentRequestProcessed = 'DOCUMENT_REQUEST_PROCESSED',
  newVideoCallMeeting = 'NEW_VIDEO_CALL_MEETING',
  videoCallMeetingEnded = 'VIDEO_CALL_MEETING_ENDED',
  videoCallParticipantConnected = 'VIDEO_CALL_PARTICIPANT_CONNECTED',
  videoCallParticipantDisconnected = 'VIDEO_CALL_PARTICIPANT_DISCONNECTED',
  videoCallExternalStateChanged = 'VIDEO_CALL_EXTERNAL_STATE_CHANGED',
}

export enum WebClientsPusherEvents {
  someoneIsTyping = 'SOMEONE_IS_TYPING',
  patientDeclinesVideoCallRequest = 'PATIENT_DECLINES_VIDEO_CALL_REQUEST',
}

export enum EhrIntegrationPusherEvents {
  patientAppointmentIsReady = 'PATIENT_APPOINTMENTS_READY',
}

export enum EformPusherEvents {
  submissionRequestStatusChanged = 'SUBMISSION_REQUEST_STATUS_CHANGED',
}

export enum PaymentBackendPusherEvents {
  paymentMethodStatusChanged = 'PAYMENT_METHOD_STATUS_CHANGED',
  paymentRequestStatusUpdate = 'PAYMENT_REQUEST_STATUS_UPDATE',
}

export type PusherEvents =
  | CorePusherEvents
  | WebClientsPusherEvents
  | EhrIntegrationPusherEvents
  | EformPusherEvents
  | PaymentBackendPusherEvents;

// TODO: Temp until we have typed all the Events and their payload (data)
export type PusherReduxAction =
  | {
      type: string; // Action type, this is what you wanna react to in reducers.
      channel: string; // The channel name, like private-doctor-25
      event: string; // One of the avaiable pusher events.
      // data: Record<string, string>; // Data that is sent with the pusher event
    }
  | PusherReduxConversationReassignedAction
  | PusherReduxMessageSentAction;

export interface PusherReduxConversationReassignedAction {
  type: string;
  channel: string;
  event: CorePusherEvents.conversationReassigned;
  data: {
    conversation_id: number;
    source_list_id: number;
    destination_list_id: number;
    initiator_id: number;
  };
}

export interface PusherReduxMessageSentAction {
  type: string;
  channel: string;
  event: CorePusherEvents.messageSent;
  data: {
    conversation_id: number;
    message_id: number;
  };
}
