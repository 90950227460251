/* eslint-disable max-len */
import { createGlobalStyle } from 'styled-components';

const KlaraTooltipStyle = createGlobalStyle`
  button[data-balloon] {
    overflow: visible; }

  [data-balloon] {
    position: relative;
    cursor: pointer; }
  [data-balloon]:after {
    filter: alpha(opactiy=0);
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    -moz-opacity: 0;
    -khtml-opacity: 0;
    opacity: 0;
    pointer-events: none;
    -webkit-transition: all 0.18s ease-out 0.18s;
    -moz-transition: all 0.18s ease-out 0.18s;
    -ms-transition: all 0.18s ease-out 0.18s;
    -o-transition: all 0.18s ease-out 0.18s;
    transition: all 0.18s ease-out 0.18s;
    font-family: 'Cera ModMed', sans-serif !important;
    font-weight: normal !important;
    font-style: normal !important;
    text-shadow: none !important;
    font-size: 12px !important;
    background: rgba(17, 17, 17, 0.9);
    border-radius: 4px;
    color: #fff;
    content: attr(data-balloon);
    padding: .5em 1em;
    position: absolute;
    white-space: nowrap;
    z-index: 10;
  }
  [data-balloon]:hover:before, [data-balloon]:hover:after, [data-balloon][data-balloon-visible]:before, [data-balloon][data-balloon-visible]:after {
    filter: alpha(opactiy=100);
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    -moz-opacity: 1;
    -khtml-opacity: 1;
    opacity: 1;
    pointer-events: auto;
  }
  [data-balloon].font-awesome:after {
    font-family: FontAwesome; }
  [data-balloon][data-balloon-break]:after {
    white-space: pre; }
  [data-balloon][data-balloon-blunt]:before, [data-balloon][data-balloon-blunt]:after {
    -webkit-transition: none;
    -moz-transition: none;
    -ms-transition: none;
    -o-transition: none;
    transition: none;
  }
  [data-balloon][data-balloon-pos="up"]:after {
    bottom: 100%;
    left: 50%;
    margin-bottom: 11px;
    -webkit-transform: translate(-50%, 10px);
    -moz-transform: translate(-50%, 10px);
    -ms-transform: translate(-50%, 10px);
    transform: translate(-50%, 10px);
    -webkit-transform-origin: top;
    -moz-transform-origin: top;
    -ms-transform-origin: top;
    transform-origin: top;
  }

  [data-balloon][data-balloon-pos="up"]:hover:after, [data-balloon][data-balloon-pos="up"][data-balloon-visible]:after {
    -webkit-transform: translate(-50%, 0);
    -moz-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    transform: translate(-50%, 0); }
  [data-balloon][data-balloon-pos="up"]:hover:before, [data-balloon][data-balloon-pos="up"][data-balloon-visible]:before {
    -webkit-transform: translate(-50%, 0);
    -moz-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    transform: translate(-50%, 0); }
  [data-balloon][data-balloon-pos="up-left"]:after {
    bottom: 100%;
    left: 0;
    margin-bottom: 11px;
    -webkit-transform: translate(0, 10px);
    -moz-transform: translate(0, 10px);
    -ms-transform: translate(0, 10px);
    transform: translate(0, 10px);
    -webkit-transform-origin: top;
    -moz-transform-origin: top;
    -ms-transform-origin: top;
    transform-origin: top;
  }

  [data-balloon][data-balloon-pos="up-left"]:hover:after, [data-balloon][data-balloon-pos="up-left"][data-balloon-visible]:after {
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0); }
  [data-balloon][data-balloon-pos="up-left"]:hover:before, [data-balloon][data-balloon-pos="up-left"][data-balloon-visible]:before {
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0); }
  [data-balloon][data-balloon-pos="up-right"]:after {
    bottom: 100%;
    right: 0;
    margin-bottom: 11px;
    -webkit-transform: translate(0, 10px);
    -moz-transform: translate(0, 10px);
    -ms-transform: translate(0, 10px);
    transform: translate(0, 10px);
    -webkit-transform-origin: top;
    -moz-transform-origin: top;
    -ms-transform-origin: top;
    transform-origin: top;
  }

  [data-balloon][data-balloon-pos="up-right"]:hover:after, [data-balloon][data-balloon-pos="up-right"][data-balloon-visible]:after {
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0); }
  [data-balloon][data-balloon-pos="up-right"]:hover:before, [data-balloon][data-balloon-pos="up-right"][data-balloon-visible]:before {
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0); }
  [data-balloon][data-balloon-pos='down']:after {
    left: 50%;
    margin-top: 11px;
    top: 100%;
    -webkit-transform: translate(-50%, -10px);
    -moz-transform: translate(-50%, -10px);
    -ms-transform: translate(-50%, -10px);
    transform: translate(-50%, -10px);
  }

  [data-balloon][data-balloon-pos='down']:hover:after, [data-balloon][data-balloon-pos='down'][data-balloon-visible]:after {
    -webkit-transform: translate(-50%, 0);
    -moz-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    transform: translate(-50%, 0); }
  [data-balloon][data-balloon-pos='down']:hover:before, [data-balloon][data-balloon-pos='down'][data-balloon-visible]:before {
    -webkit-transform: translate(-50%, 0);
    -moz-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  }
  [data-balloon][data-balloon-pos='down-left']:after {
    left: 0;
    margin-top: 11px;
    top: 100%;
    -webkit-transform: translate(0, -10px);
    -moz-transform: translate(0, -10px);
    -ms-transform: translate(0, -10px);
    transform: translate(0, -10px); }

  [data-balloon][data-balloon-pos='down-left']:hover:after, [data-balloon][data-balloon-pos='down-left'][data-balloon-visible]:after {
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0); }
  [data-balloon][data-balloon-pos='down-left']:hover:before, [data-balloon][data-balloon-pos='down-left'][data-balloon-visible]:before {
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  [data-balloon][data-balloon-pos='down-right']:after {
    right: 0;
    margin-top: 11px;
    top: 100%;
    -webkit-transform: translate(0, -10px);
    -moz-transform: translate(0, -10px);
    -ms-transform: translate(0, -10px);
    transform: translate(0, -10px); }

  [data-balloon][data-balloon-pos='down-right']:hover:after, [data-balloon][data-balloon-pos='down-right'][data-balloon-visible]:after {
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0); }
  [data-balloon][data-balloon-pos='down-right']:hover:before, [data-balloon][data-balloon-pos='down-right'][data-balloon-visible]:before {
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  [data-balloon][data-balloon-pos='left']:after {
    margin-right: 11px;
    right: 100%;
    top: 50%;
    -webkit-transform: translate(10px, -50%);
    -moz-transform: translate(10px, -50%);
    -ms-transform: translate(10px, -50%);
    transform: translate(10px, -50%);
  }

  [data-balloon][data-balloon-pos='left']:hover:after, [data-balloon][data-balloon-pos='left'][data-balloon-visible]:after {
    -webkit-transform: translate(0, -50%);
    -moz-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%); }
  [data-balloon][data-balloon-pos='left']:hover:before, [data-balloon][data-balloon-pos='left'][data-balloon-visible]:before {
    -webkit-transform: translate(0, -50%);
    -moz-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%); }
  [data-balloon][data-balloon-pos='right']:after {
    left: 100%;
    margin-left: 11px;
    top: 50%;
    -webkit-transform: translate(-10px, -50%);
    -moz-transform: translate(-10px, -50%);
    -ms-transform: translate(-10px, -50%);
    transform: translate(-10px, -50%);
  }

  [data-balloon][data-balloon-pos='right']:hover:after, [data-balloon][data-balloon-pos='right'][data-balloon-visible]:after {
    -webkit-transform: translate(0, -50%);
    -moz-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%); }
  [data-balloon][data-balloon-pos='right']:hover:before, [data-balloon][data-balloon-pos='right'][data-balloon-visible]:before {
    -webkit-transform: translate(0, -50%);
    -moz-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
  }
  [data-balloon][data-balloon-length='small']:after {
    white-space: normal;
    width: 80px; }
  [data-balloon][data-balloon-length='medium']:after {
    white-space: normal;
    width: 150px; }
  [data-balloon][data-balloon-length='large']:after {
    white-space: normal;
    width: 260px; }
  [data-balloon][data-balloon-length='xlarge']:after {
    white-space: normal;
    width: 380px; }
  @media screen and (max-width: 768px) {
    [data-balloon][data-balloon-length='xlarge']:after {
        white-space: normal;
        width: 90vw; } }
  [data-balloon][data-balloon-length='fit']:after {
    white-space: normal;
    width: 100%; }
`;

export default KlaraTooltipStyle;
