import { FC } from 'react';
import styled from 'styled-components';
import { DesignTypes, Sizes, ButtonProps } from './ButtonType';
import { LoadingSpinner } from '../LoadingSpinner';
import colorExport from '../../tokens/exports/color/index.json';
import Icon from '../Icons/Icon';

const sizeStyles = {
  small: {
    padding: '4px',
    borderRadius: '6px',
  },
  medium: {
    padding: '8px',
    borderRadius: '8px',
  },
  large: {
    padding: '12px',
    borderRadius: '8px',
  },
};

const roundSizeStyles = {
  small: {
    padding: '4px',
    borderRadius: '16px',
    width: '32px',
    height: '32px',
  },
  medium: {
    padding: '8px',
    borderRadius: '20px',
    width: '40px',
    height: '40px',
  },
  large: {
    padding: '12px',
    borderRadius: '24px',
    width: '48px',
    height: '48px',
  },
};

const StyledButton = styled.button<{ size: 'small' | 'medium' | 'large'; roundButton?: boolean }>`
  border: none;
  cursor: pointer;
  outline: none;
  transition: all 200ms linear;
  display: flex;
  ${(props) => {
    return props.roundButton ? roundSizeStyles[props.size] : sizeStyles[props.size];
  }};
  text-align: center;
  position: relative;
  &:focus {
    box-shadow: 0 0 0 4px ${colorExport.color['grey-400'].rgba.replace('100%', '50%')};
  }
  &:disabled {
    pointer-events: none;
  }
`;

const PrimaryButton = styled(StyledButton)`
  background: ${colorExport.color['blue-700'].hex};
  color: ${colorExport.color['white'].hex};

  &:active {
    background: ${colorExport.color['blue-800'].hex};
  }

  &:disabled {
    background: ${colorExport.color['grey-400'].rgba.replace('100%', '22%')};
    color: ${colorExport.color['grey-400'].hex};
  }

  &:not(:disabled):hover {
    background: ${colorExport.color['blue-500'].hex};
  }
`;

const SecondaryButton = styled(StyledButton)`
  background-color: transparent;
  box-shadow: 0 0 0 1px ${colorExport.color['grey-300'].hex} inset;
  color: ${colorExport.color['grey-900'].hex};

  &:active {
    box-shadow: 0 0 0 1px ${colorExport.color['blue-800'].hex} inset;
    color: ${colorExport.color['blue-800'].hex};
  }

  &:disabled {
    color: ${colorExport.color['grey-300'].hex};
  }

  &:not(:disabled):hover {
    box-shadow: 0 0 0 1px ${colorExport.color['blue-500'].hex} inset;
    color: ${colorExport.color['blue-500'].hex};
  }
`;

const RedButton = styled(StyledButton)`
  background: ${colorExport.color['red-600'].hex};
  color: ${colorExport.color['white'].hex};

  &:active {
    background: ${colorExport.color['red-700'].hex};
  }

  &:disabled {
    background: ${colorExport.color['grey-400'].rgba.replace('100%', '22%')};
    color: ${colorExport.color['grey-400'].hex};
  }

  &:not(:disabled):hover {
    background: ${colorExport.color['red-400'].hex};
  }
`;

const GhostButton = styled(StyledButton)`
  background: transparent;
  color: ${colorExport.color['grey-900'].hex};

  &:active {
    background: ${colorExport.color['grey-400'].rgba.replace('100%', '22%')};
    color: ${colorExport.color['grey-900'].hex};
  }

  &:disabled {
    color: ${colorExport.color['grey-300'].hex};
  }

  &:not(:disabled):hover {
    background: ${colorExport.color['grey-400'].rgba.replace('100%', '22%')};
    color: ${colorExport.color['grey-900'].hex};
  }
`;

const GhostWhiteButton = styled(StyledButton)`
  background: transparent;
  color: ${colorExport.color['white'].hex};

  &:active {
    background: ${colorExport.color['grey-400'].rgba.replace('100%', '22%')};
    color: ${colorExport.color['grey-900'].hex};
  }

  &:disabled {
    color: ${colorExport.color['grey-300'].hex};
  }

  &:not(:disabled):hover {
    background: ${colorExport.color['grey-400'].rgba.replace('100%', '22%')};
    color: ${colorExport.color['white'].hex};
  }
`;

const StyledSpinner = styled(LoadingSpinner)`
  background-color: inherit;
  border-radius: inherit;
  position: absolute;
  left: 0;
  top: 0;
`;

const IconButton: FC<ButtonProps> = ({
  disabled,
  loading,
  dataTestId,
  designType = DesignTypes.secondary,
  size = Sizes.small,
  iconName,
  ...restProps
}) => {
  const children = (
    <>
      {loading && <StyledSpinner isLoading />}
      {restProps.children ? restProps.children : <Icon size={24} name={iconName} />}
    </>
  );
  const newProps = {
    ...restProps,
    children,
    size,
    disabled: loading ? true : disabled,
  };

  switch (designType) {
    case 'primary':
      return <PrimaryButton data-test-id={dataTestId} type="submit" {...newProps} />;
    case 'red':
      return <RedButton data-test-id={dataTestId} {...newProps} />;
    case 'ghost':
      return <GhostButton data-test-id={dataTestId} {...newProps} />;
    case 'ghostWhite':
      return <GhostWhiteButton data-test-id={dataTestId} {...newProps} />;
    case 'secondary':
    default:
      return <SecondaryButton data-test-id={dataTestId} {...newProps} />;
  }
};

export default IconButton;
