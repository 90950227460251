import { ReactElement } from 'react';
import styled from 'styled-components';
import Icon, { IconName } from '../Icons/Icon';
import colorExport from '../../tokens/exports/color/index.json';
import Block from '../Blocks';
import { T } from '../typography/T';

export interface TooltipProps {
  iconName: IconName;
  text: string;
  ic: keyof typeof colorExport.color;
  onClick?: () => void;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 153px;
  z-index: 100;
  position: absolute;
  top: -55px;
  left: -65px;
  &:hover .rectangle {
    background-color: #f7f7f7;
    transition: background-color 0.25s linear;
  }
  &:hover .triangle {
    border-top: 15px solid #f7f7f7;
    transition: background-color 0.3s linear;
  }
`;

const Rectangle = styled(Block)`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #eeeeee;
`;

const Triangle = styled.div`
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 15px solid #eeeeee;
  filter: drop-shadow(0px 4px 2px rgba(0, 0, 0, 0.25));
`;

export const Tooltip = (props: TooltipProps): ReactElement => {
  const { iconName, text, ic, onClick } = props;

  return (
    <Container data-test-id="tooltip-container" onClick={onClick}>
      <Rectangle brad={8} align="center" w={153} h={40} c={ic} className="rectangle">
        <Icon ic={ic} size={24} name={iconName} />
        <T variant="b2" htmlTag="span" fw="bold" pl={3}>
          {text}
        </T>
      </Rectangle>
      <Triangle className="triangle" />
    </Container>
  );
};

export default Tooltip;
