const ExchangeTokenEndpoint = {
  create(temporaryToken) {
    return {
      url: '/patient_exchange_tokens',
      method: 'POST',
      body: JSON.stringify({
        temporary_token: temporaryToken,
      }),
    };
  },
};

export default ExchangeTokenEndpoint;
