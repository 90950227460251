import styled from 'styled-components';

const StyledInput = styled.input`
  background: transparent;
  border: none;
  outline: none;
  padding: 0;
  font-size: 24px;
  width: 100%;
  height: 45px;
`;

export default StyledInput;
