export const DOCTOR_ROLES = [
  'Biller',
  'Call Center',
  'Care Coordinator',
  'Doctor',
  'Front Desk',
  'Lab specialist',
  'Patient Support Team',
  'Medical Assistant',
  'Mental Health Professional',
  'Nurse',
  'Nurse Practitioner',
  'Office Manager',
  'Other',
  'Owner',
  'Pharmacist',
  'Physician Assistant',
  'Technician',
] as const;

export const CONTACT_RELATIONSHIPS = [
  { label: 'Patient', value: 'own' },
  { label: 'Guardian', value: 'guardian' },
];

export const OUTDATED_CHROME_VERSION = 92;
export const OUTDATED_SAFARI_VERSION = 15.4;
export const TIMELINE_WIDTH = 620;
export const CONVERSATION_CONTEXT_WIDTH = 380;
export const CONVERSATION_CONTEXT_WIDTH_COLLAPSED = 50;
export const SIDEBAR_SCREEN_BREAKPOINT = 1248;
export const ACCEPTED_IMAGES_TO_UPLOAD = [
  'image/bmp',
  'image/gif',
  'image/heic',
  'image/jpeg',
  'image/jpg',
  'image/png',
  'image/tiff',
  'image/webp',
];
export const ACCEPTED_UPLOAD = [
  ...ACCEPTED_IMAGES_TO_UPLOAD,
  'application/msword',
  'application/pdf',
  'application/vnd.ms-excel',
  'application/vnd.ms-publisher',
  'application/vnd.ms-xpsdocument',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'audio/mp3',
  'audio/mpeg',
  'audio/x-m4a',
  'text/csv',
  'text/plain',
  'video/3gpp',
  'video/mp4',
  'video/quicktime',
  'video/webm',
];

// CORE team labels
export const TEAM_LABELS = {
  CC: 'cc',
  EHR: 'ehr',
  '2FA': '2fa',
  EFORM: 'eform',
  INTAKE: 'intake',
  IMPORT: 'import',
  PAYING: 'paying',
  PAYMENT: 'payment',
  JOTFORM: 'jotform',
  COMBINED: 'combined',
  BROADCAST: 'broadcast',
  MULTITEAM: 'multiteams',
  FORWARDING: 'forwarding',
  TELEMEDICINE: 'telemedicine',
  JOURNEY_CONFIG: 'journey-config',
  ENHANCED_INBOX: 'enhanced_inbox',
  MESSAGE_ROUTING: 'message-routing',
  INTAKE_PAYMENTS: 'intake-payments',
  INTAKE_INSURANCE: 'intake-insurance',
  PATIENT_PROFILES: 'patient-profiles',
  INBOUND_REFERRALS: 'inbound-referrals',
  OPT_OUT_FROM_HEAP: 'opt-out-from-heap',
  FILTER_UNASSIGNED: 'filter-unassigned',
  MESSAGE_AUTOMATION: 'message-automation',
  ASSIGN_RESPONSE_TO_ME: 'assign-response-to-me',
  TOMORROW_APPOINTMENTS: 'tomorrow-appointments',
  CUSTOM_PASSWORD_RESET: 'custom-password-reset',
  E_STATEMENTS: 'estatement',
};

// Questionnaire "Other" option in SingleSelect
export const OTHER_TEXT_VALUE = 'Other';
export const OTHER_RESPONSE_SUFFIX = '-other';
