/* eslint-disable react/jsx-filename-extension */
import PropTypes from 'prop-types';

import Col from '../Grid/Col';
import Row from '../Grid/Row';

const InboxScreenContainer = ({ children, ...props }) => {
  const [Inbox, RightSidebar] = children;

  return (
    <Row>
      <Col xs={false} md={3} lg={2.5} />

      <Col xs={12} md={6} lg={7}>
        <Row {...props} center="xs">
          <Col xs={12}>{Inbox}</Col>
        </Row>
      </Col>

      <Col xs={false} md={3} lg={2.5} pt={7} ph={5}>
        {RightSidebar}
      </Col>
    </Row>
  );
};

InboxScreenContainer.propTypes = {
  children: PropTypes.node,
};

export default InboxScreenContainer;
