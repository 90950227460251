/* eslint-disable @typescript-eslint/no-floating-promises */
import CloseButton  from 'components/CloseButton';
import useFilePreview from 'hooks/useFilePreview';
import Placeholder from 'images/illu_upload.svg';
import { LoadingIcon, LoadingOverlay } from 'klara-ui';
import { Button, theme } from 'klara-ui/old';
import { first, isArray } from 'lodash';
import { FC } from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';

import { ACCEPTED_IMAGES_TO_UPLOAD } from 'util/constants';


const ImagePreview = styled.div<{ src: string }>`
  width: 296px;
  height: 180px;
  background: url('${(props) => props.src}');
  background-color: ${theme.colors.black};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 6px;
  position: relative;
  border: 1px solid ${theme.colors.grey25};
  &:hover {
    border: 1px solid ${theme.colors.primaryBrand};
  }
  &:hover:before {
    content: '\A';
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    left: 0;
  }
  cursor: pointer;
`;

const PlaceholderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  border-radius: 6px;
  border: 2px dashed ${theme.colors.grey25};
  &:hover {
    border: 2px dashed ${theme.colors.primaryBrand};
  }
  cursor: pointer;
`;

const ButtonContainer = styled.div`
  position: absolute;
  bottom: 10px;
  left: 10px;
`;

const CloseIconContainer = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  color: ${theme.colors.white};
`;

const dropZoneDefaultStyles = {
  width: '300px',
  height: '180px',
  borderRadius: '6px',
  backgroundColor: `${theme.colors.grey0}`,
};

const Label = styled.label`
  min-width: 100px;
  font-weight: ${theme.fonts.weights.medium};
`;

const dropZoneActiveStyles = {
  ...dropZoneDefaultStyles,
  border: '2px solid transparent',
};
const acceptedMimeTypes = ACCEPTED_IMAGES_TO_UPLOAD.join(',');

type Props = {
  handler(files: File[]): void;
  label: string;
  input: {
    name: string;
    value?: File[];
  };
  removeItem(): void;
  isLoading?: boolean;
};

const Field: FC<Props> = ({ handler, label, input, removeItem, isLoading }) => {
  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    accept: acceptedMimeTypes,
    multiple: false,
    onDrop: (droppedFiles) => {
      if (droppedFiles.length > 0) {
        handler(droppedFiles);
      }
    },
  });

  const onButtonClick = (e: React.SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    open();
  };

  const style = isDragActive ? dropZoneActiveStyles : dropZoneDefaultStyles;

  const { previewURL, loading: previewLoading } = useFilePreview(
    isArray(input.value) ? first(input.value) : input.value,
    input.name
  );

  return (
    <div {...getRootProps({ style })}>
      <LoadingOverlay
        isLoading={isLoading || previewLoading}
        isGrey
        isOpaque
        animationComponent={<LoadingIcon isGrey />}
        zIndex={400}
      >
        <input name={input.name} {...getInputProps()} disabled={isLoading} />
        {!previewURL ? (
          <PlaceholderContainer>
            <img src={Placeholder} alt={label} style={{ height: 120 }} />
            <Label>{label}</Label>
          </PlaceholderContainer>
        ) : (
          <ImagePreview src={previewURL} data-test-id="upload-preview">
            <CloseIconContainer>
              <CloseButton
                onClick={removeItem}
                data-test-id="remove-preview-item"
                backgroundType="image"
              />
            </CloseIconContainer>
            <ButtonContainer>
              <Button onClick={onButtonClick}>Update</Button>
            </ButtonContainer>
          </ImagePreview>
        )}
      </LoadingOverlay>
    </div>
  );
};

export default Field;
