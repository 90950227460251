import { css, createGlobalStyle } from 'styled-components';

export const globalStyle = css`
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
  input[type='file']:focus,
  input[type='radio']:focus,
  input[type='checkbox']:focus {
    outline: none;
  }
`;

export const GlobalStyle = createGlobalStyle(globalStyle);

export default GlobalStyle;
