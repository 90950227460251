import PropTypes from 'prop-types';
import { Children, cloneElement } from 'react';
import styled, { css } from 'styled-components';

const TabsContainer = styled.div`
  line-height: 25px;
  word-wrap: break-word;
  ${(props) =>
    props.hasMargin &&
    css`
      margin-bottom: 24px;
      margin-top: 20px;
    `}
`;

const Tabs = ({ children, activeTab, className, hasMargin = false }) => (
  <TabsContainer activeTab={activeTab} className={className} hasMargin={hasMargin}>
    {Children.map(
      children,
      (child) =>
        child &&
        cloneElement(child, {
          active: child.props.name === activeTab,
        })
    )}
  </TabsContainer>
);

Tabs.propTypes = {
  activeTab: PropTypes.string,
  className: PropTypes.string,
  hasMargin: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
};

Tabs.displayName = 'Tabs';

export default Tabs;
