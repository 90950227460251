import { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from '../../config/theme/klara';
import TextBlock from '../typography/TextBlock';
import withWindowSize from '../../util/withWindowSize';

export const containerTypes = {
  normal: 'normal',
  alert: 'alert',
  warning: 'warning',
};

const StyledContainer = styled.div`
  background-color: ${(props) => props.type === containerTypes.alert && theme.colors.yellowLight};
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: ${(props) => (props.isMobile ? '100%' : 'auto')};
  margin-bottom: ${(props) => (props.isMobile ? '10px' : 'auto')};
`;

const RightCol = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex: 1;
  justify-content: space-between;
  white-space: normal;
  border-top: ${(props) => props.borderTop && `1px solid ${theme.colors.grey10}`};
`;

const ButtonsContainer = styled.div`
  display: flex;
`;

const StyledIcon = (Icon) => styled(Icon)`
  fill: ${(props) => props.type === containerTypes.warning && theme.colors.errorRed};
  margin: 0 20px;
`;

export class Container extends Component {
  renderStyledIcon = () => {
    const { renderIcon, type } = this.props;
    const Icon = renderIcon ? StyledIcon(renderIcon()) : 0;

    return renderIcon ? <Icon type={type} /> : null;
  };

  renderText = () => {
    const { renderTitle, renderBody, isMobile, type } = this.props;

    return (
      <TextContainer isMobile={isMobile}>
        {renderTitle && (
          <TextBlock color={type === containerTypes.warning ? theme.colors.errorRed : null}>
            {renderTitle()}
          </TextBlock>
        )}
        {renderBody && <TextBlock>{renderBody()}</TextBlock>}
      </TextContainer>
    );
  };

  render() {
    const { type, dataTestId, renderButtonGroup, borderTop } = this.props;

    return (
      <StyledContainer data-test-id={dataTestId} type={type}>
        {this.renderStyledIcon()}
        <RightCol borderTop={borderTop}>
          {this.renderText()}
          <ButtonsContainer>{renderButtonGroup && renderButtonGroup()}</ButtonsContainer>
        </RightCol>
      </StyledContainer>
    );
  }
}

Container.propTypes = {
  renderIcon: PropTypes.func,
  renderTitle: PropTypes.func,
  renderBody: PropTypes.func,
  renderButtonGroup: PropTypes.func,
  isMobile: PropTypes.bool.isRequired,
  type: PropTypes.oneOf(Object.values(containerTypes)),
  dataTestId: PropTypes.string,
  borderTop: PropTypes.bool,
};

Container.defaultProps = {
  dataTestId: 'container',
  type: containerTypes.normal,
};

Container.displayName = 'Container';

export default withWindowSize(Container);
