import PropTypes from 'prop-types';
import styled from 'styled-components';
import FlexRow from '../layout/flexRow';
import Pill, { PillType } from './Pill';

const PILL_HEIGHT = 20;
const ICON_HEIGHT = 12;
const TEXT_SIZE = 13;

const FixedWidthPill = styled(Pill)`
  width: ${({ width }) => width}px;
  height: ${PILL_HEIGHT}px;
`;

const CenteredFlexRow = styled(FlexRow)`
  justify-content: center;
  height: ${PILL_HEIGHT}px;
`;

const IconContainer = styled.span`
  height: ${({ iconHeight }) => iconHeight}px;
  & * {
    height: ${({ iconHeight }) => iconHeight}px;
  }
  margin-right: 1px;
  margin-left: 2px;
  line-height: ${({ iconHeight }) => iconHeight + 2}px;
`;

const TextContainer = styled.span`
  font-size: ${TEXT_SIZE}px;
  height: ${Math.round(TEXT_SIZE * 1.25)}px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: ${Math.round(TEXT_SIZE * 1.15)}px;
`;

const IconPill = ({ icon, children, width, pillType, iconHeight }) => (
  <FixedWidthPill width={width} type={pillType}>
    <CenteredFlexRow>
      <IconContainer iconHeight={iconHeight || ICON_HEIGHT}>{icon}</IconContainer>
      <TextContainer>{children}</TextContainer>
    </CenteredFlexRow>
  </FixedWidthPill>
);

IconPill.propTypes = {
  icon: PropTypes.element.isRequired,
  width: PropTypes.number,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
  iconHeight: PropTypes.number,
  // Enums are numbers by default in value if not specified otherwise.
  pillType: PropTypes.number,
};

IconPill.defaultProps = {
  width: 120,
  pillType: PillType.default,
};

export default IconPill;
