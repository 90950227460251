import { FC, ReactNode } from 'react';
import styled from 'styled-components';

const WellContainer = styled.div`
  background-color: ${(props) => props.theme.well.backgroundColor};
  border-radius: 5px;
  padding: 12px 16px;
  display: flex;
  flex-wrap: wrap;
`;

const WellChildContainer = styled.div`
  flex-grow: 1;
  align-self: start;
  width: min-content;
`;

const WellActionButtonsContainer = styled.div<{ center: boolean }>`
  align-self: ${({ center }) => (center ? 'center' : 'start')};
  align-items: center;
  display: inline-flex;
  max-width: 50%;
  min-width: fit-content;
  flex-direction: row-reverse;
  gap: 12px;
`;

interface Props {
  /**
   * The action buttons will be displayed on the right top side of the well.
   * These elements will have a default spacing applied to them.
   */
  actionButtons?: ReactNode;

  /** If true the actions buttons will be centered to the content instead of keeping it at the top. */
  centerActionButtons?: boolean;

  /**
   * The main body of the well. You can pass any valid react component
   * to display inside the well.
   */
  children?: ReactNode;
}

const Well: FC<Props> = ({ actionButtons, centerActionButtons = false, children, ...props }) => (
  <WellContainer {...props}>
    <WellChildContainer>{children}</WellChildContainer>
    <WellActionButtonsContainer center={centerActionButtons}>
      {actionButtons}
    </WellActionButtonsContainer>
  </WellContainer>
);

export default Well;
