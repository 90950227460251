/* tslint:disable: max-classes-per-file */
import store from 'store';

class KlaraLocalStorage {
  setItem(key: string, value: string) {
    try {
      store.set(key, value);
    } catch (err) {
      return;
    }
  }

  getItem(key: string): string | undefined {
    try {
      const val = store.get(key);
      if (typeof val === 'string') return val;
      return JSON.stringify(val);
    } catch (err) {
      return;
    }
  }

  removeItem(key: string) {
    try {
      store.remove(key);
    } catch (err) {
      return;
    }
  }
}

class KlaraSessionStorage {
  setItem(key: string, value: string) {
    try {
      store.set(key, value);
    } catch (err) {
      return;
    }
  }

  getItem(key: string): string | undefined {
    try {
      const val = store.get(key);
      if (typeof val === 'string') return val;
      return JSON.stringify(val);
    } catch (err) {
      return;
    }
  }

  removeItem(key: string) {
    try {
      store.remove(key);
    } catch (err) {
      return;
    }
  }
}

export const klaraLocalStorage = new KlaraLocalStorage();
export const klaraSessionStorage = new KlaraSessionStorage();
