import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from '../config/theme/klara';
import getDisplayName from '../tools/getDisplayName';

const StyledClassName = 'circled-hoc';

const StyledComponent = styled.div`
  margin-right: 10px;
  border-radius: 50%;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  min-width: ${(props) => props.size}px;
  min-height: ${(props) => props.size}px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${theme.colors.white};
  border: 1px solid ${theme.colors.grey100};
  cursor: pointer;
  padding: 0;
  color: ${theme.colors.black};
  overflow: hidden;
  font-size: 14px;
  letter-spacing: 1px;
  text-transform: uppercase;
  &:hover {
    color: ${theme.colors.primaryBrand};
    border-color: ${theme.colors.primaryBrand};
    transition: all 200ms linear;
  }
`;

export const Circled = (props) => {
  const { InnerComponent, children, size, ...rest } = props;

  return (
    <StyledComponent size={size}>
      <InnerComponent {...rest}>{children}</InnerComponent>
    </StyledComponent>
  );
};

Circled.propTypes = {
  InnerComponent: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.element]),
  size: PropTypes.number,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.element, PropTypes.array]),
};

Circled.defaultProps = {
  size: 33,
};

const CircledHoc = (InnerComponent) => (props) => {
  Circled.displayName = `Circled(${getDisplayName(InnerComponent)})`;
  return <Circled {...props} InnerComponent={InnerComponent} />;
};
CircledHoc.styledClassName = StyledClassName;

export default CircledHoc;
