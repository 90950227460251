import { Block, Icon, withTooltip } from 'klara-ui/old';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import HiddenFileInput from './HiddenInputField';

const WithTooltip = withTooltip('span');

class Field extends PureComponent {
  constructor(props) {
    super(props);

    this.fileRef = null;
  }

  setFileRef = (el) => {
    this.fileRef = el;
  };

  onAttachmentButtonClick = () => {
    if (this.fileRef) this.fileRef.click();
  };

  defaultRenderTarget = () => (
    <Block pr={5}>
      <WithTooltip tooltipContent="Add a file" tooltipPosition="up">
        <Icon name="paperclip" className="composer-attachment-icon" />
      </WithTooltip>
    </Block>
  );

  renderTarget = () => {
    const { renderTarget, dataTestId } = this.props;

    return (
      <span data-test-id={dataTestId} onClick={this.onAttachmentButtonClick}>
        {renderTarget ? renderTarget() : this.defaultRenderTarget()}
      </span>
    );
  };

  onFileInputChange = (e) => {
    e.preventDefault();
    const {
      input: { onChange },
      accept,
    } = this.props;
    const filetypes = Array.isArray(accept) ? accept : accept.split(',');
    let files;
    if (filetypes.includes('*')) {
      files = e.target.files;
    } else {
      const fileList = e.target.files || [];
      files = [];
      for (let i = 0; i < fileList.length; i += 1) {
        if (filetypes.includes(fileList[i].type)) {
          files.push(fileList[i]);
        }
      }
    }
    if (onChange.length === 0) {
      onChange(files);
    } else {
      onChange(e, files);
    }

    this.fileRef.value = null;
  };

  render() {
    const { multiple, disabled, accept } = this.props;
    return (
      <>
        <HiddenFileInput
          ref={this.setFileRef}
          type="file"
          onChange={this.onFileInputChange}
          multiple={multiple}
          accept={accept}
          disabled={disabled}
        />
        {this.renderTarget()}
      </>
    );
  }
}

Field.propTypes = {
  dataTestId: PropTypes.string,
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool.isRequired,
    submitFailed: PropTypes.bool.isRequired,
  }).isRequired,
  multiple: PropTypes.bool,
  accept: PropTypes.string,
};

Field.defaultProps = {
  dataTestId: 'file-field',
  multiple: false,
  accept: '*',
};

export default Field;
