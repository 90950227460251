import logger from '@klara/logger'
import { FORM_NAME as VERIFICATION_CODE_FORM_NAME } from 'concerns/common/phoneVerification/verificationCode';
import PhoneVerificationEndpoint from 'persistence/PhoneVerificationEndpoint';
import { defineTypes } from 'redux/helpers';
import { actions as AlertActions } from 'redux/modules/common/alerts';
import {
  exchangeTokenSaga,
  exchangeTokenSignupSaga,
  selectors as exchangeTokenSelectors,
} from 'redux/modules/patient/exchangeToken';
import { startSubmit, stopSubmit } from 'redux-form';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import * as LocalStorageService from 'services/LocalStorageService';
import SessionService from 'services/SessionService';

import trimString from 'util/trimString';

export const initialState = {
  isPhoneVerificationStarted: false,
  phoneVerificationToken: null,
  phoneVerificationLoading: false,
  phoneNumber: null,
};

export const startPhoneVerificationTypes = defineTypes('PATIENT/START_PHONE_VERIFICATION');
export const retryPhoneVerificationTypes = defineTypes('PATIENT/RETRY_PHONE_VERIFICATION');
export const verifyPhoneNumberType = defineTypes('PATIENT/VERIFY_PHONE_NUMBER');
export const verifyPhoneNumberSignupType = defineTypes('PATIENT/VERIFY_PHONE_NUMBER_SIGNUP');
export const verifyPhoneNumberSchedulingType = defineTypes(
  'PATIENT/VERIFY_PHONE_NUMBER_SCHEDULING'
);
export const backToLoginForm = defineTypes('PATIENT/BACK_TO_LOGIN_FORM');

export const actions = {
  startPhoneVerification({ phoneNumber }) {
    return {
      type: startPhoneVerificationTypes.REQUEST,
      phoneNumber,
    };
  },

  retryPhoneVerification() {
    return {
      type: retryPhoneVerificationTypes.REQUEST,
    };
  },

  verifyPhoneNumber({ phoneNumber, code }) {
    return {
      type: verifyPhoneNumberType.REQUEST,
      phoneNumber,
      code,
    };
  },

  verifyPhoneNumberSignup({ phoneNumber, code, teamId }) {
    return {
      type: verifyPhoneNumberSignupType.REQUEST,
      phoneNumber,
      code,
      teamId,
    };
  },

  backToLoginForm: () => ({ type: backToLoginForm }),
};

export const selectors = {
  isPhoneVerificationStarted: (state) => state.patientPhoneVerification.isPhoneVerificationStarted,
  phoneNumber: (state) => state.patientPhoneVerification.phoneNumber,
  phoneVerificationToken: (state) => state.patientPhoneVerification.phoneVerificationToken,
  phoneVerificationLoading: (state) => state.patientPhoneVerification.loading,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case startPhoneVerificationTypes.SUCCESS:
      return {
        ...state,
        isPhoneVerificationStarted: true,
        phoneNumber: action.phoneNumber,
      };
    case startPhoneVerificationTypes.REQUEST:
    case retryPhoneVerificationTypes.REQUEST:
      return {
        ...state,
        isPhoneVerificationStarted: false,
        phoneVerificationToken: null,
      };
    case verifyPhoneNumberType.SUCCESS:
    case verifyPhoneNumberSignupType.SUCCESS:
    case verifyPhoneNumberSchedulingType.SUCCESS:
      return {
        ...state,
        isPhoneVerificationStarted: false,
        phoneVerificationToken: action.phoneVerificationToken,
      };
    case backToLoginForm:
      return { ...state, isPhoneVerificationStarted: false };
    default:
      return state;
  }
};

export function* startPhoneVerificationSaga({ phone_number }) {
  try {
    yield call(LocalStorageService.saveLogin, 'patient', trimString(phone_number));
    yield call(PhoneVerificationEndpoint.start, { phone_number });
    yield put({ type: startPhoneVerificationTypes.SUCCESS, phoneNumber: phone_number });
  } catch (error) {
    if (error.status === 401) {
      yield put({ type: startPhoneVerificationTypes.FAILURE, payload: { error: error.message } });
    }

    if (error.status === 502 || error.status === 504) {
      // We need to pass some payload to get submitErrors.submit_failed into LoginForm
      yield put({ type: startPhoneVerificationTypes.FAILURE, payload: { submit_failed: true } });
    }

    yield put({ type: startPhoneVerificationTypes.FAILURE });
    if (error.isUnprocessableEntity && error.isUnprocessableEntity()) {
      let errors = {
        phone_number: 'invalid',
      };

      if (error.response.error && error.response.error.code === 'validation_error') {
        errors = error.response.error.params;
      }

      yield put({ type: startPhoneVerificationTypes.FAILURE, payload: { phone_number: errors } });
    } else {
      logger.error('Starting phone verification failed', {}, error);
      throw error;
    }
  }
}

export function* verifyPhoneNumberTypeSaga({ phoneNumber, code }) {
  yield put(startSubmit(VERIFICATION_CODE_FORM_NAME));

  try {
    const { token: temporaryToken } = yield call(PhoneVerificationEndpoint.check, {
      phoneNumber,
      code,
    });

    yield call(exchangeTokenSaga, {
      client: 'patient',
      temporaryToken,
    });
    const exchangeTokenError = yield select(exchangeTokenSelectors.error);

    if (exchangeTokenError) {
      yield put(stopSubmit(VERIFICATION_CODE_FORM_NAME));
    }

    yield put({
      type: verifyPhoneNumberType.SUCCESS,
    });
  } catch (error) {
    yield put({
      type: verifyPhoneNumberType.FAILURE,
      error,
    });

    if (error?.response?.error?.code === 'validation_error') {
      const errors = error.response.error.params;

      yield put(stopSubmit(VERIFICATION_CODE_FORM_NAME, errors));
    } else {
      yield put(AlertActions.pushDefaultError());
    }
  }
}

export function* verifyPhoneNumberTypeSignupSaga({ phoneNumber, code, teamId }) {
  yield put(startSubmit(VERIFICATION_CODE_FORM_NAME));
  yield call(SessionService.setSignupPhoneNumber, phoneNumber);

  try {
    const { token: temporaryToken } = yield call(PhoneVerificationEndpoint.check, {
      phoneNumber,
      code,
    });

    yield call(exchangeTokenSignupSaga, {
      temporaryToken,
      teamId,
    });
    const exchangeTokenError = yield select(exchangeTokenSelectors.error);

    // NOTE: I don't know why this here exists. I may just remove it later. :shrug:
    if (exchangeTokenError) {
      yield put(stopSubmit(VERIFICATION_CODE_FORM_NAME));
    }

    yield put({
      type: verifyPhoneNumberSignupType.SUCCESS,
    });
  } catch (error) {
    yield put({
      type: verifyPhoneNumberSignupType.FAILURE,
      error,
    });

    let errors = {
      code: 'invalid',
    };

    if (
      error.response &&
      error.response.error &&
      error.response.error.code === 'validation_error'
    ) {
      errors = error.response.error.params;
    } else {
      logger.error('Verify phone number failed', { code }, error);
    }

    yield put(stopSubmit(VERIFICATION_CODE_FORM_NAME, errors));
  }
}

export function* rootSaga() {
  yield takeLatest(startPhoneVerificationTypes.REQUEST, startPhoneVerificationSaga);
  yield takeLatest(verifyPhoneNumberType.REQUEST, verifyPhoneNumberTypeSaga);

  yield takeLatest(verifyPhoneNumberSignupType.REQUEST, verifyPhoneNumberTypeSignupSaga);
}
