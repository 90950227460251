export function InterceptorError(interceptor, args, error) {
  const message = `Interceptor ${interceptor} raised '${error}' when invoked with arguments '${args}'`;
  Error.call(this, message);
  return Object.assign(this, { message, args, error });
}
InterceptorError.prototype = Object.create(Error.prototype);

function invokeInterceptor(interceptor, params, value) {
  try {
    return Promise.resolve(interceptor(value, params)).catch((error) => {
      throw new InterceptorError(interceptor, [value], error);
    });
  } catch (error) {
    throw new InterceptorError(interceptor, [value], error);
  }
}

function reduceInterceptors(interceptors, value, params) {
  return interceptors.reduce(
    (resultPromise, interceptor) =>
      resultPromise.then(invokeInterceptor.bind(null, interceptor, params)),
    Promise.resolve(value)
  );
}

function interceptRequest(interceptors, initialRequest) {
  return reduceInterceptors(interceptors, initialRequest);
}

function interceptResponse(interceptors, initialRequest, initialResponse) {
  return reduceInterceptors(interceptors, initialResponse, initialRequest);
}

function interceptRejection(interceptors, error) {
  return reduceInterceptors(interceptors, error).then((result) => Promise.reject(result));
}

export function HTTPClient(interceptors = {}) {
  const {
    requestInterceptors = [],
    responseInterceptors = [],
    errorInterceptors = [],
  } = interceptors;

  return {
    requestInterceptors,
    responseInterceptors,
    errorInterceptors,

    fetch(url, options = {}) {
      if (url instanceof Request) {
        throw new Error("Don't use Request instances, they're evil");
      }

      const initialRequest = { ...options, url };
      return interceptRequest(requestInterceptors, initialRequest).then((processedRequest) =>
        // We attach the response and error interceptors directly to the `fetch` call, so that if
        // a request interceptor raises an error it's not processed by the error interceptors.
        // Error interceptors should be used to handle `fetch` failures; developer mistakes in the
        // interceptors should propagate to the top level as fast as possible so they're visible
        // and can be easily addressed.
        fetch(processedRequest.url, processedRequest).then(
          interceptResponse.bind(null, responseInterceptors, processedRequest),
          interceptRejection.bind(null, errorInterceptors)
        )
      );
    },
  };
}

export default HTTPClient;
