import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from '../../config/theme/klara';
import Icon from '../Icons/Icon';

const ButtonSend = styled.button`
  background: transparent;
  cursor: pointer;
  height: 24px;
  width: 24px;
  border: none;
  position: relative;
  color: ${theme.colors.black};
  :hover {
    opacity: 0.9;
  }
`;

const DisabledPlaceholder = () => <p> ... </p>;

const Component = ({ disabled, className, onSubmit }) =>
  disabled ? (
    <DisabledPlaceholder />
  ) : (
    <ButtonSend onSubmit={onSubmit} className={className}>
      <Icon name="paperplane" />
    </ButtonSend>
  );

Component.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

Component.defaultProps = {
  disabled: false,
};

Component.displayName = 'ButtonSend';

export default Component;
