import i18n from 'i18next-client/i18next.amd';
import createDateTime from './date/dateParser';

/**
 * Format date with localization support
 *
 * @param {Date} date object to be formatted
 * @param {String} format from translations file (like 'time', 'date', 'datetime')
 *   or just format string to be passed to MomentJS (like 'MM DD'). Default value is 'datetime'.
 *
 * @example
 * formatDate(new Date(), 'time')
 * formatDate(new Date(), 'date')
 * formatDate(new Date(), 'datetime')
 * formatDate(new Date(), 'datetime', 0) // Display the result in UTC
 *
 * @returns {String} formatted string representation of date
 */
export default function formatDate(inputDate, formatName = 'datetime', timezone = undefined) {
  let formatString = i18n.t(`date.formats.${formatName}`, { defaultValue: formatName });
  const dateTime = createDateTime(inputDate);

  if (timezone) {
    // TODO: the user's locale should come from a Store (Trello card #50)
    // Luxon doesn't tolerate undefined values
    return formatString
      ? dateTime.setZone(timezone).setLocale(i18n.lng()).toFormat(formatString)
      : dateTime.setZone(timezone).setLocale(i18n.lng()).toISO();
  }

  // Luxon doesn't tolerate undefined values
  return formatString
    ? dateTime.setLocale(i18n.lng()).toFormat(formatString)
    : dateTime.setLocale(i18n.lng()).toISO();
}

function isSameDate(a, b) {
  return formatDate(a, 'date') === formatDate(b, 'date');
}

/**
 * Format date time so that it can be used in a sentence.
 * Returns a phrase like " on Jan 07, 2019 at 4:40 PM".
 *
 * If `referenceTime` is given and it's on the same date as the `time`,
 * the output omit the date, so that it will look more natural to humans.
 */
export function formatDateTimeForSentence(time, { referenceTime } = {}) {
  const showDate = !referenceTime || !isSameDate(time, referenceTime);
  const datePart = showDate ? ` on ${formatDate(time, 'date')}` : '';
  const timePart = ` at ${formatDate(time, 'time')}`;
  return `${datePart}${timePart}`;
}
