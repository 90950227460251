import config from 'config';
import ExternalClient from 'services/ExternalClient';

export function PhoneVerificationEndpoint(httpClient) {
  return {
    start({ phone_number }) {
      return httpClient.fetch('/start', {
        method: 'POST',
        body: JSON.stringify({ phone_number }),
      });
    },

    check({ phoneNumber, code }) {
      return httpClient.fetch('/check', {
        method: 'POST',
        body: JSON.stringify({ phone_number: phoneNumber, code }),
      });
    },
  };
}

export default PhoneVerificationEndpoint(
  ExternalClient(config.PHONE_VERIFICATION_SERVICE_URL || '')
);
