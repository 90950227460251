import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Field } from 'redux-form';

const renderOption = (value, label) => (
  <option key={value} value={value}>
    {label}
  </option>
);

export const PlainSelect = ({
  options,
  emptyLabel,
  className,
  meta: { touched, error, submitFailed },
  input,
  ...selectProps
}) => {
  const fieldClassName = () =>
    classnames('form__input form__input--select', className, {
      'form__input-wrapper--error': touched && error && submitFailed,
    });

  return (
    <select className={fieldClassName()} {...input} {...selectProps}>
      <option value="">{emptyLabel}</option>
      {options.map(({ value, label }) => renderOption(value, label))}
    </select>
  );
};

PlainSelect.propTypes = {
  className: PropTypes.string,
  emptyLabel: PropTypes.string.isRequired,
  options: PropTypes.array,
  meta: PropTypes.shape({
    error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    touched: PropTypes.bool.isRequired,
    submitFailed: PropTypes.bool.isRequired,
  }),
  input: PropTypes.shape({}).isRequired,
  selectProps: PropTypes.shape({}),
};

PlainSelect.defaultProps = {
  emptyLabel: '---',
};

export default (props) => <Field component={PlainSelect} {...props} />;
