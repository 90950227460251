import { Fragment } from 'react';
import styled from 'styled-components';
import { TextBlock } from 'klara-ui/old';
import ImageGearsUrl from 'images/gears.png';
import BlankSlate from 'elements/BlankSlate';

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 30px 0 30px;
`;

const FlexRow = styled.div`
  justify-content: center;
  text-align: center;
`;

const FlexRowItalic = styled(FlexRow)`
  margin-bottom: 20px;
`;

export default () => (
  <Fragment>
    <FlexContainer>
      <TextBlock>
        <FlexRow>
          <BlankSlate
            title="You’ve caught us during our routine checkup!"
            imageUrl={ImageGearsUrl}
          />
        </FlexRow>
        <FlexRowItalic>
          <p>
            Klara is currently offline for scheduled maintenance, but we’ll be back within the next
            hour.
          </p>
          <p>
            Any messages sent during the maintenance will be delivered when we come back online, and
            any voicemails will receive an auto-response asking to call back later.
          </p>
          <p>
            We apologize for any inconvenience, and we thank you for your patience as we continue
            making Klara even better!
          </p>
        </FlexRowItalic>
      </TextBlock>
    </FlexContainer>
  </Fragment>
);
