import { call, select, takeLatest } from 'redux-saga/effects';
import { defineTypes } from 'redux/helpers';
import DeviceTokenEndpoint from 'persistence/DeviceTokenEndpoint';
import { fetch } from 'persistence/reduxFetch';
import { getAppClient } from 'boot/application';
import logger from '@klara/logger';

export const createTypes = defineTypes('COMMON/DEVICES/POST');
export const SET_DEVICE_TOKEN = 'SET_DEVICE_TOKEN';
export const REGISTER_EXISTING_DEVICE = 'REGISTER_EXISTING_DEVICE';
export const RESET_DEVICE_TOKEN = 'RESET_DEVICE_TOKEN';

export const initialState = {
  deviceToken: null,
};

export const actions = {
  create(deviceToken) {
    return { type: createTypes.REQUEST, deviceToken };
  },
  registerExistingDevice() {
    return { type: REGISTER_EXISTING_DEVICE };
  },
  setDeviceToken(deviceToken) {
    return { type: SET_DEVICE_TOKEN, deviceToken };
  },
  resetDeviceToken() {
    return { type: RESET_DEVICE_TOKEN };
  },
};

export const selectors = {
  deviceToken: (state) => state.devices.deviceToken,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DEVICE_TOKEN:
      return { ...state, deviceToken: action.deviceToken };
    case RESET_DEVICE_TOKEN:
      return { ...state, deviceToken: null };
    default:
      return state;
  }
};

export function* createSaga({ deviceToken }) {
  if (deviceToken) {
    try {
      const client = yield getAppClient();
      yield call(fetch, DeviceTokenEndpoint.create({ deviceToken, client }));
    } catch (error) {
      logger.error('Error while registering device', error);
    }
  }
}

export function* registerExistingDeviceSaga() {
  const deviceToken = yield select(selectors.deviceToken);
  yield call(createSaga, { deviceToken });
}

export function* rootSaga() {
  yield takeLatest(createTypes.REQUEST, createSaga);
  yield takeLatest(REGISTER_EXISTING_DEVICE, registerExistingDeviceSaga);
  yield takeLatest(SET_DEVICE_TOKEN, registerExistingDeviceSaga);
}
