import { FC, ReactNode } from 'react';
import styled from 'styled-components';

interface Props {
  /** Defines if the box is higlighted - makes it purple. */
  isHighlighted?: boolean;

  /** Defines if the contents of the box is disabled - makes it grey. */
  isDisabled?: boolean;

  /** If true makes the box to have more breathing space around the content inside. */
  hasGutter?: boolean;

  /** The content of the box. */
  children?: ReactNode;
}

const BoxWrapper = styled.div<Props>`
  border-radius: 8px;
  padding: ${({ hasGutter }) => (hasGutter ? '30px 20px' : '16px 12px')};

  border: ${({ isHighlighted, theme }) =>
    isHighlighted ? 'none' : `1px solid ${theme.box.borderColor}`};

  background-color: ${({ isHighlighted, isDisabled, theme }) => {
    if (isHighlighted) return theme.box.backgroundColor.highlighted;
    if (isDisabled) return theme.box.backgroundColor.disabled;
    return theme.box.backgroundColor.default;
  }};
`;

const Box: FC<Props> = ({ children, ...rest }) => (
  <BoxWrapper data-component-name="bordered-box" {...rest}>
    {children}
  </BoxWrapper>
);

export default Box;
