import { datadogRum } from '@datadog/browser-rum';
import logger from '@klara/logger';
import ErrorBoundary from 'boot/ErrorBoundary';
import MaintenanceApp from 'boot/maintenance_app';
import OutdatedApp from 'boot/OutdatedApp';
import config from 'config';
import DetectRTC from 'detectrtc';
import { FlagsProvider } from 'flagged';
import flagsmith from 'flagsmith';
import { FlagsmithProvider } from 'flagsmith/react';
import { render } from 'react-dom';
import { actions as locationActions } from 'redux/modules/common/location';
import { store as patientStore } from 'redux/store/patient';
import StartAutoUpdateService from 'services/AutoUpdateService';
import { isBrowserOutdated } from 'services/CheckOutdatedBrowserService';
import { startConnectivityService } from 'services/ConnectionService';
import 'whatwg-fetch';

import { setAppClient } from './application';
import featureFlags from './featureFlags';
import { initFirebaseRemoteConfig } from './firebase';
import { pusherToDispatch } from '../redux/modules/common/pusher';


function waitForDOMToLoad() {
  return new Promise((resolve) => {
    if (document.readyState === 'complete') {
      resolve();
    } else {
      window.onload = resolve;
    }
  });
}

function unlockAudio() {
  const sound = new Audio(
    'https://klara-static.s3-us-west-2.amazonaws.com/message-notification.mp3'
  );
  sound.preload = 'auto';
  sound.load();

  document.body.removeEventListener('click', unlockAudio);
  document.body.removeEventListener('touchstart', unlockAudio);
}

export default (client, App, afterLoginRoute) => {
  DetectRTC.load(() => {});
  const store = patientStore;

  datadogRum.init({
    applicationId: config.DATADOG_APP_ID,
    clientToken: config.DATADOG_CLIENT_KEY,
    service: `web-clients-${client}`,
    site: 'datadoghq.com',
    env: config.APP_ENV,
    version: config.APP_VERSION,
    sessionSampleRate: 1,
    onNewLocation(newLocation) {
      return { viewName: newLocation.hash };
    },
    sessionReplaySampleRate: 0,
    trackResources: true,
    trackLongTasks: true,
    trackUserInteractions: true,
    allowedTracingUrls: [/https:\/\/.*\.klara.*\.(com|care)(?!.*shorten)/],
  });

  logger.init(
    {
      enableDatadog: true,
      saveLogs: false,
      logLevel: config.NODE_ENV === 'production' ? 'critical' : 'debug',
    },
    {
      clientToken: config.DATADOG_CLIENT_KEY,
      service: `web-clients-${client}`,
      site: 'datadoghq.com',
      sessionSampleRate: 100,
      forwardErrorsToLogs: false,
      env: config.APP_ENV,
      version: config.APP_VERSION,
    }
  );

  setAppClient(client);
  pusherToDispatch.setStore(store);


  if (config.MAINTENANCE_MODE === 'ON') {
    waitForDOMToLoad().then(() => render(<MaintenanceApp />, document.getElementById('app')));
  } else if (isBrowserOutdated()) {
    waitForDOMToLoad().then(() => render(<OutdatedApp />, document.getElementById('app')));
  } else {
    waitForDOMToLoad().then(() =>
      render(
        <ErrorBoundary>
          <FlagsProvider features={featureFlags}>
            <FlagsmithProvider
              options={{
                environmentID: config.FLAGSMITH_ENVIRONMENT_ID,
                api: config.FLAGSMITH_URL,
              }}
              flagsmith={flagsmith}
            >
              <App store={store} />
            </FlagsmithProvider>
          </FlagsProvider>
        </ErrorBoundary>,
        document.getElementById('app')
      )
    );

    document.body.addEventListener('click', unlockAudio);
    document.body.addEventListener('touchstart', unlockAudio);
    initFirebaseRemoteConfig();
    StartAutoUpdateService(client, store);
    startConnectivityService(store);
    store.dispatch(locationActions.setUrlAfterLogin(afterLoginRoute));
  }
};
