import config from 'config';
import store from 'store';

// Return random number between 1 and 100, memoized in localStorage
const memoizedRandomNumber = () => {
  let num = store.get('memoized_random_number');
  if (num) {
    num = parseFloat(num);
  }
  if (num && num >= 0 && num <= 100) {
    return num;
  }

  num = Math.random() * 100;
  store.set('memoized_random_number', num);
  return num;
};

const chooseBaseURL = () => {
  if (!config.NEW_API_PERCENTAGE || !config.NEW_API_BASE_URL) {
    return config.API_BASE_URL;
  }
  const percentage = parseFloat(config.NEW_API_PERCENTAGE);
  const randomNumber = memoizedRandomNumber();

  if (randomNumber < percentage) {
    return config.NEW_API_BASE_URL;
  }
  return config.API_BASE_URL;
};

const apiConfig = {
  baseURL: chooseBaseURL(),
};

export default apiConfig;
