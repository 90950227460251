import { getAppClient } from 'boot/application';
import SessionService from 'services/SessionService';
import ExchangeKeycloakTokenEndpoint, {
  PatientAuthCodeExchaneResponse,
} from 'persistence/patient/ExchangeKeycloakTokenEndpoint';

let refreshTokenPromise: Promise<any> = null;

export const refreshTokenIfAccessTokenExpired = (res: Response, initialRequest: Request) => {
  const client = getAppClient();
  const isAccessTokenExpired = res.status === 401;

  if (client === 'patient' && isAccessTokenExpired) {
    // Only fire refresh token if it was not fired yet
    if (refreshTokenPromise === null) {
      refreshTokenPromise = ExchangeKeycloakTokenEndpoint.refreshToken(
        SessionService.getRefreshToken()
      );

      // Que up the new request for expiration otherwise we will never requery
      setTimeout(() => {
        refreshTokenPromise = null;
      }, 20000); // 20s seems like a big enough window
    }

    // When refresh token returns continue checking its result
    return refreshTokenPromise.then((response) => {
      return response
        .clone()
        .json()
        .then((body: PatientAuthCodeExchaneResponse) => {
          const { status } = response;
          if (status > 299) {
            // Actually we do not handle Unauthorized errors so let's do it manually
            // This is hotfix
            window.location.href = '#/logout';
            throw Error('Unauthorized');
          }

          SessionService.setPatientRefreshToken(body.refresh_token);
          SessionService.setPatientAccessToken(body.access_token);

          const finalRequest = {
            ...initialRequest,
            headers: { ...initialRequest.headers, 'X-AUTH-TOKEN': body.access_token },
          };
          // repeat the call with new access token
          return fetch(initialRequest.url, finalRequest);
        });
    });
  }
  return Promise.resolve(res);
};
