import { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { LabelText2 } from '../../old';

const ToolbarItemContainer = styled.div`
  display: block;
`;

interface Props {
  /** The main input of the toolbar item. For example a dropdow. */
  children: ReactNode;

  /** The label for the input field that is going to be displayed above the field. */
  label: string;
}

const ToolbarItem: FC<Props> = ({ children, label = '', ...props }) => (
  <ToolbarItemContainer data-component-name="tooltip-item" {...props}>
    <LabelText2 pb={2}>{label}</LabelText2>
    {children}
  </ToolbarItemContainer>
);

export default ToolbarItem;
