import * as React from 'react';

import withDSTokens from '../../hoc/withDSTokens';
import { Col, Row } from '../Grid';
import HeadingText1 from './HeadingText1';

const H1: React.FC<{ children?: React.ReactNode }> = ({ children, ...props }) => (
  <Row {...props}>
    <Col p={0} xs={12} pb={3}>
      <HeadingText1>{children}</HeadingText1>
    </Col>
  </Row>
);

H1.displayName = 'H1';

export default withDSTokens(H1);
