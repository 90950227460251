/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import createDateTime from 'util/date/dateParser';
import find from 'lodash/find';

// TODO add types
export const sortProvidersByEarliestSlot = (groupedSlotsByProviderId, providers) => {
  const sortedByEarliestSlot = [];

  for (const providerId in groupedSlotsByProviderId) {
    // Sort slots for each provider by start_time;
    groupedSlotsByProviderId[providerId].sort(
      (a, b) => createDateTime(a.startTime) - createDateTime(b.startTime)
    );
    sortedByEarliestSlot.push([providerId, groupedSlotsByProviderId[providerId]]);
  }

  // Take first slot because it's earliest available time for provider;
  sortedByEarliestSlot.sort((a, b) => {
    const aFirstSlotStartTime = a[1][0]?.startTime;
    const bFirstSlotStartTime = b[1][0]?.startTime;

    const timeDiff = createDateTime(aFirstSlotStartTime) - createDateTime(bFirstSlotStartTime);
    if (timeDiff) return timeDiff;

    const aProviderId = a[0];
    const bProviderId = b[0];

    const aProviderName = find(providers, { id: aProviderId })?.name || '';
    const bProviderName = find(providers, { id: bProviderId })?.name || '';

    return aProviderName.localeCompare(bProviderName);
  });

  return sortedByEarliestSlot;
};
