import styled from 'styled-components';

const FormWithMobileStickyBottom = styled.form`
  position: relative;
  display: flex;
  flex-flow: column wrap;
  flex: 1;
  justify-content: space-between;

  @media screen and (min-width: 768px) {
    justify-content: flex-start;
  }
`;

export default FormWithMobileStickyBottom;
