import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import config from 'config';
import SessionService, { sessionId } from 'services/SessionService';

import generateUuid from 'util/uuid';

const coreApi = createApi({
  reducerPath: 'coreApi',
  baseQuery: fetchBaseQuery({
    baseUrl: config.API_BASE_URL,
    prepareHeaders: async (headers) => {
      const transactionRootId = generateUuid();
      const accessToken = SessionService.getToken();

      if (accessToken) {
        headers.set('X-AUTH-TOKEN', accessToken);
      }
      headers.set('X-Request-Id', `${sessionId}_${generateUuid()}`);
      headers.set('X-Transaction-Root-Id', `${sessionId}_${transactionRootId}`);

      return headers;
    },
  }),
  endpoints: () => ({}),
  tagTypes: ['insurance-card', 'questions', 'message-resource-token'],
});

export default coreApi;
