import logger from '@klara/logger';

export default function setOneSignalTag(token) {
  if (window.klaraInterface?.buildTag)
    window.OneSignal?.sendTag('klara_id', window.klaraInterface?.buildTag(token));
}

export function setOneSignalExternalUserId(token) {
  window.OneSignal.push(function () {
    window.OneSignal.setExternalUserId(window.klaraInterface?.buildTag(token));
  });
}

export function removeOneSignalExternalUserId() {
  window.OneSignal.push(function () {
    window.OneSignal.removeExternalUserId();
  });
}

export function registerOneSignalDevice() {
  window.OneSignal.push(() => {
    window.OneSignal.getUserId((userId) => {
      try {
        window.klaraInterface.registerBrowser(userId);
      } catch (e) {
        logger.error('window.klaraInterface was not found');
      }
    });
  });
}
