import styled, { css, CSSObject } from 'styled-components';
import React, { FC, MouseEventHandler } from 'react';
import theme from '../../config/theme/klara';

type OptionRowContainerProps = {
  disabled?: boolean;
  clickable: boolean;
  onlyDesktop?: boolean;
  style?: CSSObject;
};

const OptionRowContainer = styled.div<OptionRowContainerProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 0;
  color: ${theme.colors.white};
  transition: all 200ms;
  font-size: ${theme.fonts.sizes.m};
  cursor: ${(props) => (props.disabled || !props.clickable ? 'default' : 'pointer')};

  & svg {
    transition: all 200ms;
  }
  ${(props) =>
    props.onlyDesktop &&
    css`
      @media screen and (max-width: 1024px) {
        display: none;
      }
    `}

  ${(props) => props.style}
`;

OptionRowContainer.displayName = 'OptionRowContainer';

const RightContainer = styled.div`
  margin-left: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

RightContainer.displayName = 'OptionRowRightContainer';

type LeftContainerProps = {
  disabled?: boolean;
};
const LeftContainer = styled.div<LeftContainerProps>`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;

  ${(props) =>
    props.disabled
      ? css`
          color: ${theme.colors.grey50};
        `
      : null}
`;

LeftContainer.displayName = 'OptionRowLeftContainer';

export type OptionRowProps = {
  renderLeft?: () => JSX.Element;
  renderRight?: () => JSX.Element;
  onClick?: MouseEventHandler<HTMLDivElement>;
  className?: string;
  dataTestId?: string;
  disabled?: boolean;
  disabledLeft?: boolean;
  clickable?: boolean;
  onlyDesktop?: boolean;
  style?: CSSObject;
};
export const OptionRow: FC<OptionRowProps> = ({
  renderLeft,
  renderRight,
  onClick,
  className,
  dataTestId = 'option-row',
  disabled = false,
  disabledLeft = false,
  clickable = true,
  onlyDesktop = false,
  style = {},
}) => (
  <OptionRowContainer
    className={className}
    onClick={onClick}
    data-test-id={dataTestId}
    data-cy="checkbox-dropdown-row"
    disabled={disabled}
    clickable={clickable}
    onlyDesktop={onlyDesktop}
    style={style}
  >
    <LeftContainer disabled={disabledLeft}>{renderLeft()}</LeftContainer>
    {renderRight && <RightContainer>{renderRight()}</RightContainer>}
  </OptionRowContainer>
);

OptionRow.displayName = 'OptionRow';

export default OptionRow;
