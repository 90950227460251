import CasperEndpoint from 'persistence/doctor/CasperEndpoint';
import { actions as AlertActions } from 'redux/modules/common/alerts';
import { change, getFormValues } from 'redux-form';
import { put, putResolve, cancelled, cancel, takeEvery } from 'redux-saga/effects';
import { select, call, SagaGenerator, take, fork } from 'typed-redux-saga';

export type CasperFileType = {
  file_name: string;
  content_type: string;
  content_url: string;
  uuid: string;
  versions?: {
    thumb_url?: string;
    medium_url?: string;
  };
  virus_status: string;
  file_access_token: string;
  type: string;
  clientUuid: string;
  hmac?: string;
};

type GlobalState = {
  casper: CasperState;
};

type CasperState = {
  numberOfFilesBeingUploaded: number;
  casperFile: CasperFileType[];
  currentlyUploading: {
    [id: string]: boolean;
  };
};

interface FileWithMeta extends File {
  meta?: Record<string, unknown>;
}

export const initialState: CasperState = {
  numberOfFilesBeingUploaded: 0,
  casperFile: [],
  currentlyUploading: {},
};

export const CREATE_REQUEST = 'CASPER/CREATE/REQUEST';
export const CREATE_SUCCESS = 'CASPER/CREATE/SUCCESS';
export const CREATE_FAILURE = 'CASPER/CREATE/FAILURE';

export const RESET_CASPER_FILE = 'RESET_CASPER_FILE';
export const REMOVE_FILE_FROM_CASPER_STORE = 'REMOVE_FILE_FROM_CASPER_STORE';
export const ADD_FILE_TO_CASPER_STORE = 'ADD_FILE_TO_CASPER_STORE';
export const CANCEL_CASPER_UPLOAD = 'CANCEL_CASPER_UPLOAD';

type CreateRequestAction = {
  type: typeof CREATE_REQUEST;
  clientUuid: string;
  file: FileWithMeta;
  formName: string;
};
type CreateSuccessAction = { type: typeof CREATE_SUCCESS; casperFile: CasperFileType };
type CreateFailureAction = { type: typeof CREATE_FAILURE };

type ResetCasperFileAction = { type: typeof RESET_CASPER_FILE };
type RemoveFileFromCasperStoreAction = {
  type: typeof REMOVE_FILE_FROM_CASPER_STORE;
  file: File | CasperFileType;
  clientUuid: string;
};
type AddFileToCasperStoreAction = { type: typeof ADD_FILE_TO_CASPER_STORE; file: CasperFileType[] };
type CancelCasperUploadAction = { type: typeof CANCEL_CASPER_UPLOAD };

type CasperAction =
  | CreateRequestAction
  | CreateSuccessAction
  | CreateFailureAction
  | ResetCasperFileAction
  | RemoveFileFromCasperStoreAction
  | AddFileToCasperStoreAction
  | CancelCasperUploadAction;

export const actions = {
  uploadFileToCasper(
    clientUuid: string,
    file: FileWithMeta,
    formName: string
  ): CreateRequestAction {
    return { type: CREATE_REQUEST, clientUuid, file, formName };
  },
  uploadPatientFileToCasper(file: FileWithMeta, formName: string) {
    return actions.uploadFileToCasper(undefined, file, formName);
  },
  addFileToCasperStore(file: CasperFileType[]): AddFileToCasperStoreAction {
    return { type: ADD_FILE_TO_CASPER_STORE, file };
  },
  removeFileFromCasperStore(
    file: FileWithMeta | CasperFileType,
    clientUuid: string
  ): RemoveFileFromCasperStoreAction {
    return { type: REMOVE_FILE_FROM_CASPER_STORE, file, clientUuid };
  },
  resetCasperFile(): ResetCasperFileAction {
    return { type: RESET_CASPER_FILE };
  },
  cancelCasperUpload() {
    return { type: CANCEL_CASPER_UPLOAD };
  },
};

export const selectors = {
  isUploading: ({ casper }: GlobalState): boolean => casper.numberOfFilesBeingUploaded > 0,
  currentlyUploadingFiles: ({ casper }: GlobalState) => {
    const keys = Object.keys(casper.currentlyUploading);
    return keys.filter((key) => casper.currentlyUploading[key]);
  },
  casperFile: ({ casper }: GlobalState): CasperFileType[] => casper.casperFile,
  isUploadingFile: (state: GlobalState, uuid: string): boolean => {
    return selectors.currentlyUploadingFiles(state).includes(uuid);
  },
};

export const reducer = (state = initialState, action: CasperAction): CasperState => {
  switch (action.type) {
    case CREATE_REQUEST:
      return {
        ...state,
        numberOfFilesBeingUploaded: state.numberOfFilesBeingUploaded + 1,
        currentlyUploading: { ...state.currentlyUploading, [action.clientUuid]: true },
      };
    case CREATE_SUCCESS: {
      return {
        ...state,
        casperFile: [...state.casperFile, action.casperFile],
        numberOfFilesBeingUploaded: state.numberOfFilesBeingUploaded - 1,
        currentlyUploading: { ...state.currentlyUploading, [action.casperFile.clientUuid]: false },
      };
    }
    case CREATE_FAILURE:
      return { ...state, numberOfFilesBeingUploaded: state.numberOfFilesBeingUploaded - 1 };

    case ADD_FILE_TO_CASPER_STORE: {
      return {
        ...state,
        casperFile: [...state.casperFile, ...action.file],
      };
    }

    case REMOVE_FILE_FROM_CASPER_STORE: {
      if (action.file instanceof File || !action.file.content_url) {
        return {
          ...state,
          casperFile: state.casperFile.filter((f) => f.clientUuid !== action.clientUuid),
        };
      }
      const fileUrl = action.file.content_url;
      const newCasperFile = state.casperFile.filter((file) => file.content_url !== fileUrl);

      return { ...state, casperFile: newCasperFile };
    }

    case RESET_CASPER_FILE:
      return { ...state, casperFile: [] };

    case CANCEL_CASPER_UPLOAD:
      return { ...state, numberOfFilesBeingUploaded: 0 };

    default:
      return state;
  }
};

type FormData = { files: { id: string; file: FileWithMeta }[] };

export function* handleCasperUploadError(
  file: FileWithMeta,
  formName: string
): SagaGenerator<void> {
  const formValues = yield* select(getFormValues(formName) as () => FormData);
  const files = formValues?.files;

  yield put(
    change(
      formName,
      'files',
      files.filter((f: { id: string; file: FileWithMeta }) => f.file.name !== file.name)
    )
  );
}

export function* handleRequest(
  clientUuid: string | undefined,
  file: FileWithMeta,
  formName: string
): SagaGenerator<void> {
  const controller = new AbortController();
  const { signal } = controller;

  try {
    let response = yield* call(CasperEndpoint.upload, file, signal);

    if (clientUuid) {
      response = { ...response, clientUuid };
    }
    yield putResolve({ type: CREATE_SUCCESS, casperFile: { ...response, meta: file.meta } });
  } catch (error) {
    yield put(AlertActions.pushError({ content: `Failed to upload ${file.name}` }));
    yield put({ type: CREATE_FAILURE });
  } finally {
    if (yield cancelled()) {
      controller.abort();
    }
  }
}

export function* uploadFileToCasperSaga({
  clientUuid,
  file,
  formName,
}: CreateRequestAction): SagaGenerator<void> {
  const task = yield* fork(handleRequest, clientUuid, file, formName);

  const cancleAction = yield* take('CANCEL_CASPER_UPLOAD');

  if (cancleAction.type === 'CANCEL_CASPER_UPLOAD') {
    yield cancel(task);
  }
}

export function* rootSaga(): SagaGenerator<void> {
  yield takeEvery<CreateRequestAction>(CREATE_REQUEST, uploadFileToCasperSaga);
}
