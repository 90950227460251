import { OUTDATED_CHROME_VERSION, OUTDATED_SAFARI_VERSION } from 'util/constants';
import { browserUtil } from 'klara-ui/old';

const isFlutterApp = browserUtil.isFlutterApp();

const isChromeAgent = (userAgent: string) => userAgent.includes('chrome');

// The user-agent of the Chrome browser also includes the Safari browser’s user-agent.
const isSafariAgent = (userAgent: string) => {
  return !isChromeAgent(userAgent) && userAgent.includes('safari');
};

const lowerUserAgent = (userAgent: string) => userAgent.toLowerCase();

export const isChromeOutdated = (userAgent: string): boolean => {
  const userAgentLower = lowerUserAgent(userAgent);
  const raw = userAgentLower.match(/chrom(e|ium)\/([0-9]+)\./);

  return raw ? parseInt(raw[2], 10) < OUTDATED_CHROME_VERSION : false;
};

export const isSafariOutdated = (userAgent: string): boolean => {
  const userAgentLower = lowerUserAgent(userAgent);

  if (!isSafariAgent(userAgentLower)) return false;

  const version = userAgentLower.match(/version\/([0-9]+\.*[0-9]*)/)?.[1];

  return version ? parseFloat(version) < OUTDATED_SAFARI_VERSION : false;
};

export const isFlutterIOSOutdated = (userAgent: string): boolean => {
  const raw = userAgent.match('OS ([0-9].*?) like')?.[1]?.split('_')?.join('.');

  return raw ? parseFloat(raw) < OUTDATED_SAFARI_VERSION : false;
};

export const isBrowserOutdated = (): boolean =>
  isChromeOutdated(navigator?.userAgent) ||
  isSafariOutdated(navigator?.userAgent) ||
  (isFlutterApp && isFlutterIOSOutdated(navigator?.userAgent));
