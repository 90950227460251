
import commonSagas from 'redux/modules/common/sagas';
import { rootSaga as documentSaga } from 'redux/modules/patient/documents';
import { rootSaga as schedulingQuestionnaireSaga } from 'redux/modules/patient/ehr/scheduling/schedulingQuestionnaire';
import { rootSaga as exchangeTokenSaga } from 'redux/modules/patient/exchangeToken';
import { rootSaga as patientPhoneVerificationSaga } from 'redux/modules/patient/phoneVerification';
import { all, spawn } from 'redux-saga/effects';

import { rootSaga as exchangeKeycloakSaga } from './exchangeKeycloakToken';

export default function* () {
  return yield all([
    ...commonSagas,
    spawn(exchangeTokenSaga),
    spawn(patientPhoneVerificationSaga),
    spawn(documentSaga),
    spawn(schedulingQuestionnaireSaga),
    spawn(exchangeKeycloakSaga),
  ]);
}
