import PropTypes from 'prop-types';
import { H2, TextBlock } from 'klara-ui/old';
import styled from 'styled-components';

const BlankSlateContainer = styled.div`
  margin: 0 auto;
  max-width: 800px;
  padding: 30px 0;
  position: relative;
  text-align: center;
  min-height: 155px;
`;

const BlankSlateImage = styled.img`
  padding-bottom: ${(props) => (props.small ? '0' : '20px')};
  ${(props) => props.small && 'width: 220px;'}
`;

const BlankSlate = ({ title, imageUrl, children = null, small }) => (
  <BlankSlateContainer data-test-id="no_search_results">
    <BlankSlateImage src={imageUrl} small={small} />
    {small ? (
      <TextBlock small={small}>
        <strong>{title}</strong>
      </TextBlock>
    ) : (
      <H2>{title}</H2>
    )}
    <TextBlock data-cy="blank-slate-content" small={small}>
      {children}
    </TextBlock>
  </BlankSlateContainer>
);

BlankSlate.propTypes = {
  title: PropTypes.string,
  imageUrl: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.element])),
  ]),
  small: PropTypes.bool,
};

export default BlankSlate;
