function HTTPError(status, body) {
  Error.call(this, status);

  this.status = status;
  this.response = body;
  return this;
}

HTTPError.prototype = Object.create(Error.prototype);

Object.assign(HTTPError.prototype, {
  isUnauthorized() {
    return this.status === 401;
  },
  isForbidden() {
    return this.status === 403;
  },
  isUnprocessableEntity() {
    return this.status === 422;
  },
  isNotFound() {
    return this.status === 404;
  },
  isConflicted() {
    return this.status === 409;
  },
});

Object.assign(HTTPError, {
  fromStatusBody(status, body) {
    return new HTTPError(status, body);
  },
});

export { HTTPError };
export default HTTPError;
