import { theme } from 'klara-ui/old';
import { FC } from 'react';
import ReactCodeInput, { ReactCodeInputProps } from 'react-code-input';
import { CSSProperties } from 'styled-components';

const commonStyle = {
  fontFamily: 'Cera ModMed, Adjusted Arial Fallback, sans-serif',
  margin: '4px',
  MozAppearance: 'textfield',
  WebkitAppearance: 'textfield',
  width: '40px',
  borderRadius: '6px',
  fontSize: theme.fonts.sizes.xl,
  height: '40px',
  paddingLeft: '7px',
  color: theme.colors.grey100,
};

const validStyles = {
  border: `1px solid ${theme.colors.grey10}`,
};

const invalidStyles = {
  border: `1px solid ${theme.colors.errorRed}`,
};

const styles = {
  inputStyle: { ...commonStyle, ...validStyles } as CSSProperties,
  inputStyleInvalid: { ...commonStyle, ...invalidStyles } as CSSProperties,
};

const DEFAULT_INPUT_SIZE = 4;

type NumberFieldProps = {
  handleSubmit: ({ code }: { code: string }) => void;
  disabled: boolean;
  inputSize: number;
  meta: {
    error: string;
    submitFailed: boolean;
  };
  input?: ReactCodeInputProps;
};

const NumberField: FC<NumberFieldProps> = ({
  handleSubmit,
  disabled,
  inputSize = DEFAULT_INPUT_SIZE,
  meta: { error, submitFailed },
  input,
}) => {
  const handleChange = (code: string): void => {
    if (code.length === inputSize && !disabled) handleSubmit({ code });
  };

  const isValid = !(error && submitFailed);

  return (
    <ReactCodeInput
      {...input}
      {...styles}
      type="number"
      fields={inputSize}
      inputMode="numeric"
      name="number_field"
      pattern="[0-9]*"
      onChange={handleChange}
      isValid={isValid}
    />
  );
};

export default NumberField as 'input' & typeof NumberField;
