import { rootSaga as casperSaga } from 'redux/modules/common/casper';
import { rootSaga as devicesSaga } from 'redux/modules/common/devices';
import { rootSaga as manifestSaga } from 'redux/modules/common/manifest';
import { rootSaga as mobileSaga } from 'redux/modules/common/mobile';
import { rootSaga as sessionSaga } from 'redux/modules/common/session';
import { spawn } from 'redux-saga/effects';

export default [
  spawn(mobileSaga),
  spawn(devicesSaga),
  spawn(sessionSaga),
  spawn(manifestSaga),
  spawn(casperSaga),
];
