import { FC, ReactNode, SyntheticEvent } from 'react';
import styled from 'styled-components';

import theme from '../../config/theme/klara';

const StyledLink = styled.a<Props>`
  color: ${(props) => (props.disabled ? theme.colors.grey50 : theme.colors.primaryBrand)};
  text-decoration: none;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
  font-weight: ${(props) => props.weight || theme.fonts.weights.regular};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
`;

interface Props {
  href?: string;
  dataTestId?: string;
  weight?: number;
  disabled?: boolean;
  target?: string;
  rel?: string;
  onClick?: (e?: SyntheticEvent) => void;
  children?: ReactNode;
}

export const Link: FC<Props> = ({ children, ...props }) => (
  <StyledLink className={props.dataTestId} data-test-id={props.dataTestId} {...props}>
    {children}
  </StyledLink>
);

Link.displayName = 'Link';

export default Link;
