import { FC, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import iconsExport from '../../tokens/exports/icons/index.json';
import colorExport from '../../tokens/exports/color/index.json';
import withDSTokens, { DSTokenProps } from '../../hoc/withDSTokens';

export type IconName = keyof typeof iconsExport;

export interface IconProps extends DSTokenProps {
  /** Defines the icon to be rendered. Accepts only existing icon name. */
  name: IconName;

  /** DEPRECATED: ic is being replaced by currentColor of the parent element */
  ic?: keyof typeof colorExport.color;

  /** DEPRECATED: hc is being replaced by currentColor of the parent element */
  hc?: keyof typeof colorExport.color;

  /** DEPRECATED: disabled is being replaced by buttonIcon disabled */
  disabled?: boolean;

  /** DEPRECATED: Size is being replaced by isBig, isSmall */
  size?: 16 | 24 | 72;

  /** Defines if the icon is bigger than normal. */
  isBig?: boolean;

  /** Defines if the icon is smaller than normal. */
  isSmall?: boolean;

  /** Defines if the icon should be used within a text inline. Overrides size. */
  isInline?: boolean;

  /** Prop used only in video calls: defines the default icon opacity. */
  opacity?: number;
  /** Prop used only in video calls: defines icon opacity on hover. */
  hoverOpacity?: number;
}

interface IconContainerProps extends Omit<IconProps, 'size'> {
  size: number;
}

const IconContainer = styled.span`
  ${({
    opacity = 1,
    hoverOpacity = opacity,
    c,
    hc,
    disabled,
    size,
    isInline,
  }: IconContainerProps) => css`
    display: inline-block;
    width: ${size}px;
    height: ${size}px;
    min-width: ${size}px;
    min-height: ${size}px;
    ${isInline &&
    `margin: 1px 2px;
      vertical-align: bottom;`}

    // Probably remove cursor when we are using buttons where it is clickable
    cursor: ${disabled ? 'default' : 'pointer'};

    opacity: ${opacity} !important;
    transition: 200ms all;
    &:hover {
      opacity: ${hoverOpacity} !important;
    }

    & svg {
      width: 100%;
      height: 100%;
      & * {
        fill: ${c ? colorExport.color[c].hex : 'currentColor'};
        &:hover {
          fill: ${hc ? colorExport.color[hc].hex : 'currentColor'};
        }
      }
    }
  `}
`;

const Icon: FC<IconProps> = ({
  name,
  ic,
  hc,
  disabled = false,
  size = 24,
  isBig = false,
  isSmall = false,
  isInline = false,
  ...props
}) => {
  const iconRef = useRef(null);
  const [calculatedSize, setCalculatedSize] = useState<number>(size);

  /**
   * If inline is used we need to wait for the element to mount and the ref to change.
   * Otherwise this effect should not affect non inline icons, and should be run only once.
   */
  useEffect(() => {
    /** We have a double ternary for big (72) small (16) and default (24) size */
    let tempSize: number = isBig ? 72 : isSmall ? 16 : size;

    /** If it's an inline icon we wanna get the font size of the element. */
    if (isInline && iconRef.current) {
      tempSize = +window
        .getComputedStyle(iconRef.current) // Get the span around the icon.
        .getPropertyValue('font-size') // Get the font-size in pixels (eg.: 16px)
        .slice(0, -2); // Remove px from the end.
    }

    setCalculatedSize(tempSize);
  }, [iconRef.current, isInline, size, isBig, isSmall]);

  return (
    <IconContainer
      c={ic}
      hc={hc}
      name={name}
      disabled={disabled}
      size={calculatedSize}
      dangerouslySetInnerHTML={{ __html: iconsExport[name] }}
      data-test-id={name}
      isInline={isInline}
      ref={iconRef}
      {...props}
    />
  );
};

export default withDSTokens(Icon);
