import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import config from 'config';
import SessionService, { sessionId } from 'services/SessionService';

import generateUuid from 'util/uuid';

const ehrIntegrationApi = createApi({
  reducerPath: 'ehrIntegrationApi',
  baseQuery: fetchBaseQuery({
    baseUrl: config.EHR_API_BASE_URL,
    prepareHeaders: async (headers) => {
      const transactionRootId = generateUuid();
      const accessToken = SessionService.getToken();

      if (accessToken && !headers.get('Authorization')) {
        headers.set('Authorization', `Bearer ${accessToken}`);
      }

      headers.set('X-Request-Id', `${sessionId}_${generateUuid()}`);
      headers.set('X-Transaction-Root-Id', `${sessionId}_${transactionRootId}`);

      return headers;
    },
  }),
  endpoints: () => ({}),
  tagTypes: [
    'patient-visits',
    'appointments',
    'slots',
    'next-available-slots',
    'locations',
    'providers',
    'reasons',
    'scheduling-config',
  ],
});

export default ehrIntegrationApi;
