import logger from '@klara/logger';
import { history } from 'actions/LocationActions';
import { routeBuilders } from 'boot/patient/routes';
import { registerExistingDeviceSaga } from 'redux/modules/common/devices';
import { selectors as locationSelectors } from 'redux/modules/common/location';
import { reloadWindow, updateIsRequiredSaga } from 'redux/modules/common/manifest';
import { actions as pusherActions } from 'redux/modules/common/pusher';
import {
  getAccountSaga,
  selectors as currentPatientSelectors,
} from 'redux/modules/patient/currentPatient';
import { trackSaga } from 'redux/modules/patient/tracking';
import { select, call, takeLatest, put } from 'redux-saga/effects';
import { klaraLocalStorage } from 'services/KlaraStorage';


export const PATIENT_AFTER_LOGIN = 'PATIENT/AFTER_LOGIN';

export const actions = {
  afterLogin() {
    return { type: PATIENT_AFTER_LOGIN };
  },
};

export function* afterLoginSaga() {
  try {
    yield call(trackSaga, { client_type: 'patient-client' });
    yield call(getAccountSaga);

    const account = yield select(currentPatientSelectors.currentPatientAccount);

    if (account) {
      // for WalkMe to track
      window.userId = account.id;
      // Notify the mobile app if avaiable about the login
      window.flutter_inappwebview?.callHandler?.('onPatientLogin', account);

      yield put(pusherActions.login());
      const urlAfterLogin = yield select(locationSelectors.urlAfterLogin);

      // Display 2fa screen?
      const isOnInbox = urlAfterLogin === null && window.location.hash === '#/inbox';
      const hasNeverSetup2FA = !account.email;
      const patientDecidedToSet2faLater =
        klaraLocalStorage.getItem('patient_decided_to_set_2fa_later') === 'true';

      if (hasNeverSetup2FA && !patientDecidedToSet2faLater && isOnInbox) {
        yield call(history.replace, routeBuilders.askFor2FARoute());
      } else {
        // continue with the normal workflow
        yield call(history.replace, urlAfterLogin);
      }
    }
    const updateIsRequired = yield call(updateIsRequiredSaga, 'patient');

    if (updateIsRequired) {
      yield call(reloadWindow);
    }

    yield call(registerExistingDeviceSaga);
  } catch (error) {
    logger.error('Patient after login failed', {}, error);
  }
}

export function* rootSaga() {
  yield takeLatest(PATIENT_AFTER_LOGIN, afterLoginSaga);
}
