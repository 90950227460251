import { FC } from 'react';
import styled, { css } from 'styled-components';
import { FloatingButtonsContainerProps } from './ButtonType';

const StyledButtonsContainer = styled.div<{ position?: string }>`
  position: fixed;
  bottom: 35px;
  display: flex;
  ${(props) =>
    props.position === 'right'
      ? css`
          z-index: 99999;
          right: 100px;
          @media screen and (max-width: 768px) {
            display: none;
          }
        `
      : css`
          z-index: 10;
          left: 30px;
          @media screen and (max-width: 1024px) {
            display: none;
          }
        `}
`;

const FloatingButtons: FC<FloatingButtonsContainerProps> = ({ children, position }) => (
  <StyledButtonsContainer position={position}>{children}</StyledButtonsContainer>
);

export default FloatingButtons;
