const assertString = (str: string): void => {
  if (typeof str !== 'string') {
    throw new TypeError('This function validates strings only');
  }
};

export const isEmpty = (str: string): boolean => {
  assertString(str);
  return str.length === 0;
};

export const isEmail = (str: string): boolean => {
  assertString(str);

  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    str?.toLowerCase()
  );
};
