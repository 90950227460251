import * as CSS from 'csstype';
import { FC, CSSProperties, ReactNode } from 'react';

import withDSTokens from '../../hoc/withDSTokens';
import typographyExport from '../../tokens/exports/typography/index.json';

export type TypographyVariant = keyof typeof typographyExport.typography;
export type TypographySupportedHtmlTag =
  | 'div'
  | 'span'
  | 'article'
  | 'p'
  | 'section'
  | 'a'
  | 'label'
  | 'h1'
  | 'h2'
  | 'pre';
export type TypographySupportedWS = 'initial' | 'pre-line' | 'normal' | 'nowrap';
export type TypographySupportedFW = 'bold' | 'normal';
export interface TProps {
  variant?: TypographyVariant;
  htmlTag?: TypographySupportedHtmlTag;
  ta?: CSS.Property.TextAlign;
  ws?: TypographySupportedWS;
  fw?: TypographySupportedFW;
  ellipsis?: boolean;
  style?: CSSProperties;
  onClick?: () => void;
  fs?: number | string;
  for?: string;
  standartPb?: boolean;
  children: ReactNode;
}

const getVariantStyles = (
  variant: TypographyVariant,
  ta: CSS.Property.TextAlign,
  ws: TypographySupportedWS,
  ellipsis: boolean,
  fw: TypographySupportedFW,
  fs?: number | string,
  standartPb?: boolean
): CSSProperties => {
  const styleExport = typographyExport.typography[variant];
  const style: CSSProperties = {
    fontSize: fs ?? `${styleExport['font-size'].value / 16}rem`,
    letterSpacing: styleExport['letter-spacing'].value,
    lineHeight: `${styleExport['line-height'].value / 16}rem`,
    textAlign: ta,
    margin: 0,
    whiteSpace: ws,
    fontWeight: fw,
  };

  if (standartPb) {
    style.paddingBottom = `${styleExport['padding-bottom'].value / 16}rem`;
  }

  if (ellipsis) {
    style.overflow = 'hidden';
    style.textOverflow = 'ellipsis';
    style.whiteSpace = 'nowrap';
  }

  return style;
};

const Typography: FC<TProps> = ({
  variant = 'b1',
  htmlTag = 'div',
  ta = 'inherit',
  ws = 'initial',
  fw = 'normal',
  ellipsis = false,
  style = {},
  fs,
  children,
  standartPb = false,
  ...props
}) => {
  const Component = htmlTag;
  return (
    <Component
      {...props}
      style={{ ...style, ...getVariantStyles(variant, ta, ws, ellipsis, fw, fs, standartPb) }}
    >
      {children}
    </Component>
  );
};

Typography.displayName = 'Typography';

export const T = withDSTokens<TProps>(Typography);
