const buildDocumentParams = (documentCategoryId, files) => {
  let media = [];

  for (let i = 0; i < files.length; i++) {
    let fileData = {
      content_type: files[i].content_type,
      file_name: files[i].file_name,
      uuid: files[i].uuid,
      content_url: files[i].content_url,
      hmac: files[i].hmac,
    };
    if (files[i].versions) {
      fileData = { ...fileData, versions: files[i].versions };
    }
    media.push(fileData);
  }
  return JSON.stringify({ document_category_id: documentCategoryId, media });
};

export default {
  create(documentCategoryId, files) {
    return {
      url: '/patient/documents',
      method: 'POST',
      body: buildDocumentParams(documentCategoryId, files),
    };
  },
};
