import Router from 'boot/patient/router';
import { lightTheme as theme } from 'klara-ui';
import AppContainer from 'klara-ui/old/components/containers/AppContainer';
import tokens from 'klara-ui/old/tokens';
import { Provider } from 'react-redux';
import { persistor } from 'redux/store/patient';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from 'styled-components';

const selectedTheme = {
  // These global toxens will be removed probably in the future
  flexboxgrid: tokens.flexboxgrid,
  // This was broken so we probably don't need it.
  breakpoints: tokens.flexboxgrid.breakpoints,
  // The new way to style components
  ...theme,
};

export default ({ store, className, ...props }) => {
  const componentTree = () => (
    <ThemeProvider theme={selectedTheme}>
      <AppContainer fluid>
        <Router {...props} store={store} />
      </AppContainer>
    </ThemeProvider>
  );

  return (
    <Provider store={store} key="store">
      <PersistGate loading={null} persistor={persistor}>
        {componentTree()}
      </PersistGate>
    </Provider>
  );
};
