import { FC } from 'react';
import styled from 'styled-components';

import LoadingComponent from './LoadingComponent';

const Overlay = styled.div`
  background-color: ${(props) => props.color || '#fff'};
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0.5;
`;

interface Props {
  isLoading: boolean;
  color?: string;
}

const OverlayLoadingSpinner: FC<Props> = ({ isLoading, color = '#fff' }) => {
  if (!isLoading) {
    return null;
  }

  return (
    <>
      <Overlay color={color} />
      <LoadingComponent isLoading />
    </>
  );
};

export default OverlayLoadingSpinner;
