import { FC } from 'react';
import styled, { keyframes } from 'styled-components';

const figureAnimation = ({ size }: { size: number }) => keyframes`
  0% {
    height: 1px;
    width: 1px;
    border-width: 0px;
  }
  50% {
    height: ${size / 2}px;
    width: ${size / 2}px;
    border-width: ${size / 2}px;
  }
  100% {
    height: ${size}px;
    width: ${size}px;
    border-width: 0px;
  }
`;

interface FigureProps {
  size: 16 | 24 | 72;
  isGrey: boolean;
}

const LoaderFigure = styled.div<FigureProps>`
  margin: auto auto;
  box-sizing: border-box;
  border-style: solid;
  border-color: ${(props) =>
    props.isGrey ? props.theme.loadingAnimation.grey : props.theme.loadingAnimation.default};
  border-radius: ${(props) => props.size}px;
  animation: ${(props) => figureAnimation(props)} 1s infinite linear;
`;

interface LoaderFigureWrapperProps {
  size: 16 | 24 | 72;
}

const LoaderFigureWrapper = styled.div<LoaderFigureWrapperProps>`
  display: grid;
  height: ${(props) => props.size}px;
  width: ${(props) => props.size}px;
`;

interface Props {
  isBig?: boolean;
  isSmall?: boolean;
  isGrey?: boolean;
}

const getSize = (isBig: boolean, isSmall: boolean) => {
  if (isBig) return 72;
  if (isSmall) return 16;
  return 24;
};

const LoadingIcon: FC<Props> = ({ isBig = false, isSmall = false, isGrey = false, ...props }) => (
  <LoaderFigureWrapper size={getSize(isBig, isSmall)} data-component-name="loading-icon" {...props}>
    <LoaderFigure size={getSize(isBig, isSmall)} isGrey={isGrey} />
  </LoaderFigureWrapper>
);

export default LoadingIcon;
