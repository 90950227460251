import config from 'config';
import { HttpClientType } from 'models/common.types';
import { FetchQuestionsResponse, FetchQuestionsParams } from 'models/questionnaire.types';
import coreApi from 'redux/api/coreApi';
import ExternalClient from 'services/ExternalClient';

import { buildTeamPath } from './paths';

// TODO: Should be removed after start using RTKQ hooks
export const QuestionnaireEndpoint = (httpClient: HttpClientType) => ({
  getQuestions({
    encryptedCoreTeamId,
    phoneVerificationToken,
    authToken,
  }: FetchQuestionsParams): Promise<FetchQuestionsResponse> {
    let url = `patient/teams/${encryptedCoreTeamId}/questionnaire/questions`;
    let headers = {};

    if (authToken) {
      headers = {
        AUTHORIZATION: `Bearer ${authToken}`,
      };
    } else {
      url += `?phone_verification_token=${phoneVerificationToken}`;
    }

    return httpClient.fetch(url, {
      method: 'GET',
      headers,
    });
  },
});

export default QuestionnaireEndpoint(ExternalClient(config.SCHEDULING_API_BASE_URL));

export const questionnaireApi = coreApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchQuestions: builder.query<FetchQuestionsResponse, FetchQuestionsParams>({
      query: ({ encryptedCoreTeamId, phoneVerificationToken }) => ({
        url: `${buildTeamPath(encryptedCoreTeamId)}/questionnaire/questions`,
        params: phoneVerificationToken ? { phone_verification_token: phoneVerificationToken } : {},
      }),
      providesTags: ['questions'],
    }),
  }),
});

export const { useFetchQuestionsQuery } = questionnaireApi;
