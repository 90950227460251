import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import styled from 'styled-components';
import theme from '../../config/theme/klara';
import Icon from '../Icons/Icon';

const BackLink = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: 200ms linear;
  &:hover {
    color: ${theme.colors.primaryBrand};
    fill: ${theme.colors.primaryBrand};
  }
  margin-left: -8px;
`;

const BackButtonText = styled.span`
  font-weight: ${theme.fonts.weights.bold};
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: ${theme.fonts.sizes.xs};
`;

class BackButton extends PureComponent {
  static propTypes = {
    onClickHandler: PropTypes.func.isRequired,
    buttonText: PropTypes.string,
    dataTestId: PropTypes.string,
  };

  static defaultProps = {
    buttonText: 'Back',
  };

  render() {
    const { onClickHandler, buttonText, dataTestId } = this.props;

    return (
      <BackLink data-test-id={dataTestId} className="back-link" onClick={onClickHandler}>
        <Icon name="chevron-left" />
        <BackButtonText>{buttonText}</BackButtonText>
      </BackLink>
    );
  }
}

export default BackButton;
