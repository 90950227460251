import { createSelector } from '@reduxjs/toolkit';
import { every, find, flatten, flow, isEmpty, isObject, map } from 'lodash';
import { DateTime } from 'luxon';
import { AssetType, DateString } from 'models/common.types';
import {
  AvailableSlotsForSelectedDateType,
  ConvertedProviderType,
  SlotType,
  SlotsByDateType,
} from 'models/patientTypes';
import { FORM_NAME } from 'redux/forms/patient/documentUploadForm';
import { getFormValues } from 'redux-form';

import { isDateDisabled, isDateOutOfBookableWindow } from 'util/checkBookablePeriod';
import { sortProvidersByEarliestSlot } from 'util/sortProvidersByEarliestSlot';

import { PatientRootState } from '../../reducers';

// const selectProviders = (isPublicScheduling?: boolean) => {
//   const api = isPublicScheduling ? publicSchedulingApi : inAppSchedulingApi;

//   publicSchedulingApi.endpoints.fetchProvidersPublic.select({
//     appointmentReasonId,
//     encryptedTeamId,
//   })();
// };

// const selectSchedulingConfig = (isPublicScheduling?: boolean) => {
//   return isPublicScheduling
//     ? publicSchedulingApi.endpoints.fetchSchedulingConfigPublic.select({
//         messageResourceToken,
//       })().data
//     : inAppSchedulingApi.endpoints.fetchSchedulingConfigInApp.select({
//         encryptedTeamId,
//         messageResourceToken,
//       })().data;
// };

export const selectUnavailableProvidersRenderedAt = ({
  scheduling: {
    slotSelection: { unavailableProvidersRenderedAt },
    selectedProvider,
  },
}: PatientRootState) => {
  if (selectedProvider?.id === null) return unavailableProvidersRenderedAt;

  if (isObject(unavailableProvidersRenderedAt))
    return unavailableProvidersRenderedAt[selectedProvider?.id];

  return unavailableProvidersRenderedAt;
};

export const selecteEncryptedTeamIdInApp = createSelector(
  [({ patientConversations }) => patientConversations?.conversation],
  //TODO: Integrate conversation module / pass teamId as prop to SchedulingScreen
  () => 123 //find(teams, { id: teamId })?.encrypted_id
);

export const selectExistingAndNewInsuranceImages = createSelector(
  [
    getFormValues(FORM_NAME) as () => { files: string[] },
    (_, insuranceImages: AssetType[]) => insuranceImages,
  ],
  (formValues, insuranceImages) => {
    const { files: insuranceImagesFormFiles } = formValues ?? { files: [] };

    return flow(flatten, (images) =>
      map(images, (selectedImage, index) => {
        const isFront = index === 0;

        // image empty/deleted, use null
        if (!selectedImage) return { value: null, needsUpload: false };

        // use already stored & fetched casper file
        if (typeof selectedImage === 'string')
          return {
            value: { ...insuranceImages[isFront ? 0 : 1], meta: { isFront } },
            needsUpload: false,
          };

        return {
          value: Object.assign(selectedImage, { meta: { isFront } }),
          needsUpload: true,
        };
      })
    )(insuranceImagesFormFiles ?? []);
  }
);

export const selectAvailableSlotsForSelectedDate = createSelector(
  [
    (state: PatientRootState) => state.scheduling.slotSelection.selectedDate,
    (state, slotsByDate: SlotsByDateType[], providers: ConvertedProviderType[]) => ({
      slotsByDate,
      providers,
    }),
  ],
  (selectedDate, { slotsByDate, providers }) => {
    let slots: SlotType[] = [];

    if (slotsByDate.length > 0 && selectedDate) {
      // find slots from slotsByDate where date is equal to selected date
      const slotByDate = slotsByDate.find((item: SlotsByDateType) => item.date === selectedDate);
      slots = slotByDate && slotByDate.slots;
    }

    const groupedSlotsByProviderId: Record<string, SlotType[]> = {};

    slots?.forEach((slot) => {
      const alreadyHasProviderSlots = !!groupedSlotsByProviderId[slot.providerId];

      if (alreadyHasProviderSlots) {
        groupedSlotsByProviderId[slot.providerId].push(slot);
      } else {
        groupedSlotsByProviderId[slot.providerId] = [slot];
      }
    });

    return sortProvidersByEarliestSlot(
      groupedSlotsByProviderId,
      providers
    ) as AvailableSlotsForSelectedDateType;
  }
);

/**
 * Returns all unavailable providers for currently selected date, sorted by name & mapped
 * to include next available date field, if next available slots have been fetched.
 * If a specific provider is selected, then only this provider will be returned if unavailable.
 */
export const selectUnavailableProviders = createSelector(
  [
    ({
      scheduling: {
        selectedProvider,
        slotSelection: { selectedDate, preselectedDate },
      },
    }: PatientRootState) => ({
      selectedDate,
      selectedProvider,
      preselectedDate,
    }),
    (state, providers: ConvertedProviderType[], slotsByDate: SlotsByDateType[]) => ({
      providers,
      slotsByDate,
    }),
  ],
  (
    { selectedDate, selectedProvider, preselectedDate },

    { providers, slotsByDate }
  ) => {
    const slotsForSelectedDate = slotsByDate.find(
      ({ date }) => date === selectedDate || date === preselectedDate
    )?.slots;
    const allProvidersSelected = selectedProvider === null;

    return (
      providers
        // filter out available providers
        .filter(
          ({ id: providerId }) =>
            // make sure that every slot does NOT contain provider id (unavailability)
            every(
              slotsForSelectedDate,
              ({ providerId: slotProviderId }) => slotProviderId !== providerId
            ) &&
            // condition to only keep selected provider (if applicable)
            (allProvidersSelected || providerId === selectedProvider?.id)
        )
        .sort(({ label: aProviderName }, { label: bProviderName }) =>
          aProviderName.localeCompare(bProviderName)
        )
    );
  }
);

export const selectFirstDateWithUnavailableProviders = createSelector(
  [
    (
      state,
      payload: {
        slotsByDate: SlotsByDateType[];
        providerIds: string[];
        bookablePeriodStartDate: DateString;
        bookablePeriodEndDate: DateString;
        allDaysDisabled: boolean;
      }
    ) => payload,
    (state) => selectUnavailableProvidersRenderedAt(state),
  ],
  (
    { slotsByDate, providerIds, bookablePeriodStartDate, bookablePeriodEndDate, allDaysDisabled },
    unavailableProvidersRenderedAt
  ) => {
    return slotsByDate.find(
      // for a specific date's slots
      ({ slots, date }) => {
        // skip calculation if feature has already been rendered
        if (unavailableProvidersRenderedAt) return date === unavailableProvidersRenderedAt;

        // for at least 1 provider id
        return providerIds.some((providerId) =>
          // that provider id is missing from every slot for that date
          slots.every(({ providerId: slotProviderId }) => providerId !== slotProviderId)
        ) &&
          /*
        If all days in period are disabled, we take into account disabled dates in order to show the feature
        with all providers listed on the first date.
        Otherwise we don't, in order to avoid it being shown on a disabled date.
        */
          allDaysDisabled
          ? true
          : !isDateDisabled({ date, bookablePeriodEndDate, bookablePeriodStartDate, slots }) &&
              !isDateOutOfBookableWindow({
                selectedDate: date,
                bookablePeriodEndDate,
                bookablePeriodStartDate,
              });
      }
    )?.date;
  }
);

export const selectIsCurrentPeriodUnavailableProvidersCandidate = createSelector(
  [
    ({
      scheduling: {
        slotSelection: { selectedDate, preselectedDate },
      },
    }: PatientRootState) => ({ selectedDate, preselectedDate }),
    (
      state,
      payload: {
        bookablePeriodStartDate: DateString;
        unavailableProviders: ConvertedProviderType[];
        firstDateWithUnavailableProviders: DateString;
      }
    ) => payload,
  ],
  (
    { selectedDate, preselectedDate },
    { bookablePeriodStartDate, unavailableProviders, firstDateWithUnavailableProviders }
  ) => {
    const currentlyDisplayedDate = selectedDate || preselectedDate;
    const currentlyDisplayedDatetime = DateTime.fromJSDate(new Date(currentlyDisplayedDate));
    const bookablePeriodStartDatetime = DateTime.fromJSDate(new Date(bookablePeriodStartDate));
    const differenceInDays = currentlyDisplayedDatetime
      .diff(bookablePeriodStartDatetime, ['days'])
      .toObject().days;

    return (
      !isEmpty(unavailableProviders) &&
      currentlyDisplayedDate === firstDateWithUnavailableProviders &&
      differenceInDays <= 4 &&
      differenceInDays >= 0
    );
  }
);

export const selectShouldRenderUnavailableProviders = createSelector(
  [
    ({
      scheduling: {
        selectedProvider,
        slotSelection: { selectedDate, preselectedDate },
      },
    }: PatientRootState) => ({ selectedProvider, selectedDate, preselectedDate }),
    (state) => selectUnavailableProvidersRenderedAt(state),
    (state, isCurrentPeriodUnavailableProvidersCandidate) =>
      isCurrentPeriodUnavailableProvidersCandidate,
  ],

  (
    { selectedDate, preselectedDate, selectedProvider },
    unavailableProvidersRenderedAt,
    isCurrentPeriodUnavailableProvidersCandidate
  ) => {
    const currentlyDisplayedDate = selectedDate || preselectedDate;

    const isInitialRender = selectedProvider
      ? typeof unavailableProvidersRenderedAt === 'undefined'
      : unavailableProvidersRenderedAt === null;

    return (
      isCurrentPeriodUnavailableProvidersCandidate &&
      (unavailableProvidersRenderedAt === currentlyDisplayedDate || isInitialRender)
    );
  }
);
