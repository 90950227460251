import { history } from 'actions/LocationActions';
import { actions } from 'redux/modules/common/manifest';

const isInbox = (pathname) =>
  pathname.includes('inbox') ||
  pathname.includes('lists') ||
  pathname.includes('video-visits') ||
  pathname.includes('mentions');

export default (client, store) => {
  store.dispatch(actions.checkForUpdate(client));

  let counter = 0;

  history.listen((location) => {
    if (location.pathname.includes('conversations')) {
      counter++;
    }

    if (isInbox(location.pathname)) {
      if (counter === 10) {
        store.dispatch(actions.checkForUpdate(client));
      }

      // Needed to avoid memory leak until react issue is fixed
      // https://github.com/facebook/react/issues/18790
      if (counter > 20) {
        store.dispatch(actions.forceUpdate());
      }
    }
  });
};
