/*eslint-disable*/
//@ts-nocheck
import classnames from 'classnames';
import { HTMLAttributes, ReactElement } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { WrappedFieldProps } from 'redux-form';

interface TextareaProps extends HTMLAttributes<HTMLTextAreaElement> {
  autoSize: boolean;
  label?: string;
}

const Field = ({
  className,
  label,
  autoSize,
  meta: { touched, error, submitFailed },
  ...inputProps
}: WrappedFieldProps & TextareaProps): ReactElement | null => {
  const fieldClassName = classnames(
    `form__input-wrapper form__input-wrapper--${inputProps.input.name}`,
    { 'form__input-wrapper--error': touched && error && submitFailed }
  );
  const { input, ...props } = inputProps;

  if (autoSize) {
    return label ? (
      <div className={fieldClassName}>
        <label className="form__label">
          {label}
          <TextareaAutosize {...{ ...input, ...props, className }} />
        </label>
      </div>
    ) : (
      <div className={fieldClassName}>
        <TextareaAutosize {...{ ...input, ...props, className }} />
      </div>
    );
  }

  return label ? (
    <div className={fieldClassName}>
      <label className="form__label">
        {label}
        <textarea {...{ ...input, ...props, className }} />
      </label>
    </div>
  ) : (
    <div className={fieldClassName}>
      <textarea {...{ ...input, ...props, className }} />
    </div>
  );
};

export default Field;
