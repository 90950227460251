export default function returnJsonIfSuccessful(response) {
  return response
    .text()
    .then((text) => {
      let json;
      if (text.length > 0) {
        json = JSON.parse(text);
      }
      const headers = {};

      if (response.headers.forEach) {
        // WARNING: the `whatwg-fetch` polyfill fails to polyfill iterators if Symbols aren't
        // available, and due to a configuration conversation with our outdated version of Babel, or
        // CoreJS, or babel-runtime. This is a hack to fix.
        response.headers.forEach((value, key) => {
          headers[key] = value;
        });
      } else {
        for (let pair of response.headers.entries()) {
          headers[pair[0]] = pair[1];
        }
      }

      return {
        status: response.status,
        headers,
        body: json,
      };
    })
    .catch(() => response);
}
