import PropTypes from 'prop-types';
import { Component, Fragment } from 'react';
import styled from 'styled-components';
import Dropdown, { DropdownTrigger, DropdownContent } from 'react-simple-dropdown';
import { Avatar } from '../Avatar';
import theme from '../../config/theme/klara';
import withWindowSize from '../../util/withWindowSize';

const StyledDropDownMenu = styled(Dropdown)`
  display: inline-block;
  position: relative;

  .dropdown__content {
    display: none;
    position: absolute;
    left: ${(props) => (props.$dropdownPosition ? props.$dropdownPosition : 'auto')};
    right: 0;
    z-index: 9999;
  }

  // For now used only in TeamDropdown (https://klara.atlassian.net/browse/CORE-6849)
  &.mobile-align-left {
    .dropdown__content {
      left: ${(props) => (props.$isMobile ? '0' : 'auto')};
    }
  }

  &.dropdown--active {
    .dropdown__content {
      display: block;
    }
  }
`;

const ContentContainer = styled.div`
  padding-left: 0;
  z-index: 1000;
  min-width: ${({ containerWidth, isMobile }) =>
    containerWidth && !isMobile ? containerWidth : '220px'};
  max-width: ${(props) => (props.isMobile ? '342px' : '400px')};
  margin: 2px 0 0;
  list-style: none;
  font-size: ${theme.fonts.sizes.m};
  background-color: ${theme.colors.grey100};
  opacity: 0.9;
  border-radius: 6px;
  margin: 5px 0;
  padding: 20px 30px;
`;

const StyledLi = styled.li`
  list-style: none;
`;

const StyledA = styled.a`
  padding: 12px 10px;
  display: flex;
  align-items: center;
  clear: both;
  font-weight: ${theme.fonts.weights.regular};
  line-height: 1.3;
  color: ${theme.colors.white};
  white-space: nowrap;
  text-decoration: none;
  cursor: pointer;
  margin: 0px auto;
  justify-content: center;

  &:focus,
  &:hover {
    text-decoration: none;
    color: ${theme.colors.white};
    background-color: ${theme.colors.grey90};
  }
`;

const HeadingContainer = styled.div`
  color: ${theme.colors.white};
  margin: 0 -10px 12px;
  font-size: ${theme.fonts.sizes.m};
  font-weight: ${theme.fonts.weights.regular};
  text-align: center;
`;

export const Seperator = styled.div`
  margin: 15px -30px 8px;
  border-bottom: 1px solid ${theme.colors.white};
  opacity: 0.25;
`;

const MenuItem = ({ linkTo, href, title, ...rest }) => (
  <StyledLi>
    <StyledA href={linkTo ? `#${linkTo}` : href} {...rest}>
      {title}
    </StyledA>
  </StyledLi>
);

MenuItem.propTypes = {
  linkTo: PropTypes.string,
  href: PropTypes.string,
  title: PropTypes.string.isRequired,
};

const defaultRenderTitle = (props) => <Avatar url={props.thumbUrl} name={props.name} size={35} />;

defaultRenderTitle.propTypes = {
  thumbUrl: PropTypes.string.isRequired,
  name: PropTypes.string,
};

const defaultRenderItem = (props) => <MenuItem key={props.title} {...props} />;

defaultRenderItem.propTypes = {
  title: PropTypes.string.isRequired,
};

class DropdownMenu extends Component {
  handleLinkClick = () => {
    this.dropdown.hide();
  };

  attachRefToDropDown = (e) => {
    this.dropdown = e;
  };

  renderSeperator = (index) => {
    const { seperators } = this.props;

    return seperators.includes(index) && <Seperator />;
  };

  render() {
    const {
      items,
      renderTitle,
      renderItem,
      className,
      dropdownPosition,
      isMobile,
      heading,
      width,
      containerWidth,
      ...rest
    } = this.props;

    return (
      <StyledDropDownMenu
        className={`account-dropdown ${className}`}
        ref={this.attachRefToDropDown}
        $dropdownPosition={dropdownPosition}
        $isMobile={isMobile}
      >
        <DropdownTrigger>{renderTitle(rest)}</DropdownTrigger>

        <DropdownContent>
          <ContentContainer containerWidth={containerWidth} isMobile={isMobile}>
            {heading && (
              <HeadingContainer>
                {' '}
                {heading} <Seperator />{' '}
              </HeadingContainer>
            )}
            <div onClick={this.handleLinkClick}>
              {items.map((item, index) => (
                <Fragment key={`dd-menu-${item.key || index}`}>
                  {renderItem(item)} {this.renderSeperator(index)}
                </Fragment>
              ))}
            </div>
          </ContentContainer>
        </DropdownContent>
      </StyledDropDownMenu>
    );
  }
}

DropdownMenu.propTypes = {
  thumbUrl: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  renderTitle: PropTypes.func,
  renderItem: PropTypes.func,
  items: PropTypes.array,
  dropdownPosition: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  heading: PropTypes.string,
  seperators: PropTypes.array,
  width: PropTypes.string,
  isMobile: PropTypes.bool,
};

DropdownMenu.defaultProps = {
  renderTitle: defaultRenderTitle,
  renderItem: defaultRenderItem,
  dropdownPosition: false,
  seperators: [],
};

DropdownMenu.displayName = 'DropdownMenu';

export default withWindowSize(DropdownMenu);
