import { tokenTypes, destroySessionTypes } from 'redux/modules/common/session';
import { PATIENT_AFTER_LOGIN } from 'redux/modules/patient/login';

export default (_) => (next) => (action) => {
  if (!window.nativeAppInterface || !window.nativeAppInterface.hooks) {
    return next(action);
  }

  switch (action.type) {
    case tokenTypes.SUCCESS:
      window.nativeAppInterface.hooks.userLoggedIn();
      window.nativeAppInterface.hooks.initAppRating();
      break;
    case destroySessionTypes.SUCCESS:
      window.nativeAppInterface.hooks.userLoggedOut();
      break;
    case PATIENT_AFTER_LOGIN:
      if (window.nativeAppInterface.hooks.patientAfterLogin)
        window.nativeAppInterface.hooks.patientAfterLogin();
      break;
    default:
  }

  return next(action);
};
