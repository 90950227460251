import { FC, ReactElement, ReactNode } from 'react';

import { Spacing } from '../../hoc/withDSTokens';
import WithWindowSize from '../../util/withWindowSize';
import IconButton from '../Button/IconButton';
import AbsoluteContainer from '../containers/AbsoluteContainer';
import FixedContainer from '../containers/FixedContainer';
import Box from '../Grid/Box';
import Col from '../Grid/Col';
import Row from '../Grid/Row';
import BackButton from '../Links/BackButton';
import H1 from '../typography/h1';
import { T } from '../typography/T';

const NormalContainer: FC<{children: ReactNode}> = ({ children }) => <T pos="relative">{children}</T>;

const FullScreenContainer: FC<{children: ReactNode}>  = ({ children }) => (
  <Box
    z={500}
    bgc="white"
    pos="fixed"
    style={{ top: 0, left: 0, overflowY: 'scroll' }}
    fullHeight
    fullWidth
  >
    {children}
  </Box>
);

interface ModalProps {
  title?: string;
  onClose?: () => void;
  backLinkHandler?: () => void;
  backLinkText?: string;
  footer?: ReactNode;
  contentPaddingBottom?: Spacing;
  center?: boolean;
  fullscreen?: boolean;
  renderAction?: () => ReactNode;
  dataTestId?: string;
  wideContentContainer?: boolean;
  children?: ReactNode;
}

interface ModalTitleProps {
  title: string;
  renderAction: () => ReactNode;
}

const ModalTitle: FC<ModalTitleProps> = ({ title, renderAction }) => (
  <Row middle="xs" between="xs">
    <Col fullWidth={!renderAction}>{title && <H1 data-test-id="modal-title">{title}</H1>}</Col>
    {renderAction && <Col>{renderAction()}</Col>}
  </Row>
);

const Modal: FC<ModalProps> = ({
  title,
  children,
  onClose,
  backLinkHandler,
  backLinkText,
  footer,
  contentPaddingBottom = 12,
  fullscreen = false,
  center,
  renderAction,
  dataTestId,
  wideContentContainer = false,
}) => {
  const Container = fullscreen ? FullScreenContainer : NormalContainer;
  const hasHeader = backLinkHandler || onClose;
  return (
    <Container>
      {hasHeader && (
        <AbsoluteContainer top="0" left="0" right="0" fluid>
          <Row ph={3} pt={3} center="xs">
            <Col xs={12}>
              <Row between="xs">
                <Col p={3}>
                  {backLinkHandler && (
                    <BackButton
                      dataTestId={dataTestId}
                      onClickHandler={backLinkHandler}
                      buttonText={backLinkText}
                    />
                  )}
                </Col>
                <Col p={3}>
                  {onClose && (
                    <IconButton
                      iconName="close"
                      onClick={onClose}
                      dataTestId="modal-close"
                      designType="ghost"
                      size="medium"
                      roundButton
                    />
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </AbsoluteContainer>
      )}

      <Box>
        <Row center="xs" fullHeight>
          <Col xs={12} sm={12} md={wideContentContainer ? 12 : 8} fullHeight pt={10}>
            <Row>
              <Col xs={12}>
                <ModalTitle title={title} renderAction={renderAction} />
              </Col>
            </Row>
            <Row
              pt={center ? 12 : undefined}
              pb={footer ? contentPaddingBottom : 0}
              align={center ? 'center' : 'left'}
              center="xs"
              middle="xs"
            >
              <Col xs={12}>{children}</Col>
            </Row>
          </Col>
        </Row>
      </Box>

      {footer && (
        <FixedContainer bottom={0} left={0} right={0} bt={1} bc="grey-100">
          <Box>
            <Row center="xs" bgc="white" data-test-id="sticky-footer">
              <Col xs={12} sm={12} md={8} fullHeight>
                {footer}
              </Col>
            </Row>
          </Box>
        </FixedContainer>
      )}
    </Container>
  );
};

export default WithWindowSize(Modal);
