import { FC } from 'react';
import styled, { css } from 'styled-components';
import { DesignTypes, Sizes, ButtonProps } from './ButtonType';
import { LoadingSpinner } from '../LoadingSpinner';
import colorExport from '../../tokens/exports/color/index.json';
import { LabelText1, LabelText2 } from '../typography';
import Icon from '../Icons/Icon';
import { Spacing } from 'klara-ui/old/hoc/withDSTokens';

const sizeStyles = {
  small: {
    padding: '7px 12px',
    borderRadius: '6px',
    minWidth: '64px',
  },
  medium: {
    padding: '10px 16px',
    borderRadius: '8px',
    minWidth: '72px',
  },
  large: {
    padding: '14px 20px',
    borderRadius: '8px',
    minWidth: '84px',
  },
};

const sizeStylesIconLeft = {
  small: {
    padding: '7px 12px 7px 8px',
    borderRadius: '6px',
  },
  medium: {
    padding: '8px 16px 8px 10px',
    borderRadius: '8px',
  },
  large: {
    padding: '12px 20px 12px 14px',
    borderRadius: '8px',
  },
};

const sizeStylesIconRight = {
  small: {
    padding: '7px 8px 7px 12px',
    borderRadius: '6px',
  },
  medium: {
    padding: '8px 10px 8px 16px',
    borderRadius: '8px',
  },
  large: {
    padding: '12px 20px 12px 14px',
    borderRadius: '8px',
  },
};

const iconPadding: { [key in Sizes]: Spacing } = {
  small: 2,
  medium: 4,
  large: 4,
};

const StyledButton = styled.button<{
  size: 'small' | 'medium' | 'large';
  fullWidth?: boolean;
  icon?: 'left' | 'right' | 'no';
}>`
  border: none;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  outline: none;
  ${(props) => {
    switch (props.icon) {
      case 'left':
        return sizeStylesIconLeft[props.size];
      case 'right':
        return sizeStylesIconRight[props.size];
      default:
        return sizeStyles[props.size];
    }
  }};
  text-align: center;
  position: relative;
  ${(props) =>
    props.fullWidth
      ? css`
          width: 100%;
        `
      : null}
  &:focus {
    box-shadow: 0 0 0 4px ${colorExport.color['grey-400'].rgba.replace('100%', '50%')};
  }
`;

const PrimaryButton = styled(StyledButton)`
  background: ${colorExport.color['blue-700'].hex};
  color: ${colorExport.color['white'].hex};

  &:active {
    background: ${colorExport.color['blue-800'].hex};
  }

  &:disabled {
    background: ${colorExport.color['grey-400'].rgba.replace('100%', '22%')};
    color: ${colorExport.color['grey-400'].hex};
  }

  &:not(:disabled):hover {
    background: ${colorExport.color['blue-500'].hex};

    & svg {
      fill: ${colorExport.color['blue-500'].hex};
    }
  }
`;

const SecondaryButton = styled(StyledButton)`
  background: ${colorExport.color['white'].hex};
  color: ${colorExport.color['grey-900'].hex};
  box-shadow: 0 0 0 1px ${colorExport.color['grey-300'].hex} inset;

  &:active {
    box-shadow: 0 0 0 1px ${colorExport.color['blue-800'].hex} inset;
    color: ${colorExport.color['blue-800'].hex};
  }

  &:disabled {
    box-shadow: 0 0 0 1px ${colorExport.color['grey-300'].hex} inset;
    color: ${colorExport.color['grey-300'].hex};
  }

  &:not(:disabled):hover {
    box-shadow: 0 0 0 1px ${colorExport.color['blue-500'].hex} inset;
    color: ${colorExport.color['blue-500'].hex};
  }
`;

const RedButton = styled(StyledButton)`
  background: ${colorExport.color['red-600'].hex};
  color: ${colorExport.color['white'].hex};

  &:active {
    background: ${colorExport.color['red-700'].hex};
  }

  &:disabled {
    background: ${colorExport.color['grey-400'].rgba.replace('100%', '22%')};
    color: ${colorExport.color['grey-400'].hex};
  }

  &:not(:disabled):hover {
    background: ${colorExport.color['red-400'].hex};
  }
`;

const GhostButton = styled(StyledButton)`
  background: transparent;
  color: ${colorExport.color['blue-700'].hex};

  &:active {
    background: ${colorExport.color['blue-50'].hex};
    color: ${colorExport.color['blue-800'].hex};
  }

  &:disabled {
    color: ${colorExport.color['grey-300'].hex};
  }

  &:not(:disabled):hover {
    background: ${colorExport.color['grey-400'].rgba.replace('100%', '22%')};
    color: ${colorExport.color['blue-500'].hex};

    & svg {
      fill: ${colorExport.color['blue-500'].hex};
    }
  }
`;

const StyledSpinner = styled(LoadingSpinner)`
  background-color: inherit;
  border-radius: inherit;
  position: absolute;
  left: 0;
  top: 0;
`;

const Button: FC<ButtonProps> = ({
  disabled,
  loading,
  dataTestId,
  designType = DesignTypes.secondary,
  size = Sizes.small,
  icon = 'no',
  ...restProps
}) => {
  const children = (
    <>
      {loading && <StyledSpinner isLoading />}
      {icon === 'left' && (
        <Icon
          pr={iconPadding[Sizes.small]}
          size={size === Sizes.small ? 16 : 24}
          name={restProps.iconName}
        />
      )}
      {size === Sizes.small ? (
        //Applied white-space: nowrap to tag inside <button>
        <LabelText2 ws="nowrap" pb={1}>
          {restProps.children}
        </LabelText2>
      ) : (
        <LabelText1 ws="nowrap" pb={1}>
          {restProps.children}
        </LabelText1>
      )}
      {icon === 'right' && (
        <Icon
          pl={iconPadding[Sizes.small]}
          size={size === Sizes.small ? 16 : 24}
          name={restProps.iconName}
        />
      )}
    </>
  );

  const newProps = {
    ...restProps,
    children,
    size,
    disabled: loading ? true : disabled,
  };

  switch (designType) {
    case 'primary':
      return <PrimaryButton data-test-id={dataTestId} type="submit" {...newProps} />;
    case 'red':
      return <RedButton data-test-id={dataTestId} {...newProps} />;
    case 'ghost':
      return <GhostButton data-test-id={dataTestId} {...newProps} />;
    case 'secondary':
    default:
      return <SecondaryButton data-test-id={dataTestId} {...newProps} />;
  }
};

export default Button;
