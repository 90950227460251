import config from 'config';
import { CasperFileType } from 'redux/modules/common/casper';
import ExternalClient from 'services/ExternalClient';
import SessionService from 'services/SessionService';

const headers = () => ({
  'X-AUTH-TOKEN': SessionService.getToken(),
});

function CasperEndpoint(httpClient: any) {
  return {
    upload(file: File, signal: AbortSignal): Promise<CasperFileType> {
      const formData = new FormData();
      formData.append('file', file);

      return httpClient.fetch('/v1/files', {
        method: 'POST',
        headers: headers(),
        body: formData,
        signal: signal,
      });
    },
  };
}

export default CasperEndpoint(ExternalClient(config.CASPER_API_BASE_URL));
