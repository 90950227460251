import i18next from 'i18next-client/i18next.amd';
import translationsTextEN from 'locales/en/translation.json';
import translationsTextENUS from 'locales/en_us/translation.json';

const translationsEN = translationsTextEN;
const translationsENUS = { ...translationsEN, ...translationsTextENUS };

function init() {
  i18next.init({
    fallbackLng: 'en',
    lowerCaseLng: true,
    getAsync: false, // if we want to use i18next's loading mechanism
    resStore: {
      en: { translation: translationsEN },
      'en-us': { translation: translationsENUS },
    },
  });
}

// This isn't really a service, it just sets up the i18next library.

init();

const translate = i18next.t;

export default translate;
