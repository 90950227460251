import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from '../../config/theme/klara';
import WithWindowSize from '../../util/withWindowSize';
import Col from '../Grid/Col.tsx';
import Icon from '../Icons/Icon.tsx';
import Block from '../Blocks/index.tsx';

const colors = {
  error: theme.colors.errorRed,
  warning: theme.colors.yellowLight,
  success: theme.colors.successGreen,
};

const AlertContainer = styled(Col)`
  display: flex;
  justify-content: ${(props) => !props.isMobile && 'center'};
  align-items: ${(props) => !props.isMobile && 'center'};
  background-color: ${(props) => colors[props.alertType]};
  border-color: ${(props) => colors[props.alertType]};
  color: ${(props) => ['success', 'error'].includes(props.alertType) && theme.colors.white};
  border: 1px solid transparent;
  cursor: pointer;
  text-align: center;
  box-shadow: ${({ flat }) => (flat ? 'none' : '0px 3px 5px rgba(0, 0, 0, 0.06)')};
  padding: 15px;
`;

const Content = styled.div`
  flex: 1;
  margin-right: auto;
`;

const CloseButton = styled.div`
  color: ${(props) => ['success', 'error'].includes(props.alertType) && theme.colors.white};
`;

const Alert = ({ type, content, onClick, className, flat, isMobile, persistent, children }) => (
  <AlertContainer
    className={className}
    alertType={type}
    flat={flat}
    isMobile={isMobile}
    xs={12}
    data-test-id="alert"
  >
    <Content>
      {content}
      {children}
    </Content>
    {!persistent && (
      <Block onClick={onClick}>
        <CloseButton data-test-id="close-alert">
          <Icon name="close-circle" />
        </CloseButton>
      </Block>
    )}
  </AlertContainer>
);

Alert.propTypes = {
  type: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  onClick: PropTypes.func,
  className: PropTypes.string,
  flat: PropTypes.bool,
  isMobile: PropTypes.bool.isRequired,
  persistent: PropTypes.bool,
};

export default WithWindowSize(Alert);
