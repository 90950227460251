/**
 * This is where release feature toggle constants are defined.
 * More info here: https://klara.atlassian.net/wiki/spaces/KLARA/pages/1071546370/Using+feature+flags+during+development
 */
export enum FEATURES {
  NEXT_AVAILABLE_APPOINTMENT = 'NEXT_AVAILABLE_APPOINTMENT',
  E_STATEMENTS = 'E_STATEMENTS',
}

export default {
  [FEATURES.NEXT_AVAILABLE_APPOINTMENT]: true,
  [FEATURES.E_STATEMENTS]: true
};
