import { FC } from 'react';
import { DSTokenProps } from 'klara-ui/old/hoc/withDSTokens';
import { Row } from '../Grid';
import Block from '../Blocks';

export const CenteredOverlay: FC<DSTokenProps> = ({ children, ...props }) => {
  return (
    <Block pos="absolute" fullWidth fullHeight {...props}>
      <Row center="xs" middle="xs" fullHeight>
        {children}
      </Row>
    </Block>
  );
};

export default CenteredOverlay;
