export const weights = Object.freeze({
  regular: 400,
  medium: 500,
  bold: 700,
});

export const sizes = Object.freeze({
  xxl: '30px',
  xl: '24px',
  l: '16px',
  m: '15px',
  s: '13px',
  xs: '10px',
});

export const lineHeight = 1.3;
