import { FC } from 'react';
import styled from 'styled-components';
import { SpanValues } from './types';

/**
 * The Grid and GridItem is experimental. They only have basic alignment and cells have only
 * vertical alignment. I expect this to change in upcoming version where I plan to add more options
 * to the GridItem to make it much easier to use. So for now I would advise against using it
 * outside klara-ui as refactoring it later can cause unnecesarry overhead.
 */

interface Props {
  /** Defines a 4px gap between grid items if enabled. */
  hasGutter?: boolean;

  /** Defines column span if you are planning to nest it into another grid.  */
  span?: SpanValues;

  /** Grid items as children. */
  children: React.ReactNode;
}

interface GridContainerProps {
  hasGutter: boolean;
  span: SpanValues;
}

const GridContainer = styled.div<GridContainerProps>`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(12, 1fr);
  ${({ hasGutter }) => hasGutter && 'gap: 4px'}
  grid-column: ${({ span }) => `span ${span}`};
  align-items: center;
`;

const Grid: FC<Props> = ({ hasGutter = false, span = 1, children, ...props }) => {
  return (
    <GridContainer hasGutter={hasGutter} span={span} {...props}>
      {children}
    </GridContainer>
  );
};

export default Grid;
