import { isEmpty } from 'lodash';
import { DateTime } from 'luxon';
import createDateTime from 'util/date/dateParser';

export const isDateOutOfBookableWindow = ({
  selectedDate,
  bookablePeriodStartDate,
  bookablePeriodEndDate,
}) => {
  const today = createDateTime(DateTime.now().toFormat('yyyy-MM-dd'));

  const selectedDateIsInTheFuture = createDateTime(selectedDate).diff(today, 'days').days >= 0;
  const isBeforeBookablePeriod =
    createDateTime(selectedDate).diff(DateTime.fromISO(bookablePeriodStartDate), 'days').days < 0;

  const tooEarly = isBeforeBookablePeriod && selectedDateIsInTheFuture;
  const tooLate =
    createDateTime(selectedDate).diff(DateTime.fromISO(bookablePeriodEndDate), 'days').days >= 0;

  if (!tooEarly && !tooLate) return false;

  return {
    tooEarly,
    tooLate,
  };
};

export const findFirstRestrictedDate = (
  slotsByDate,
  bookablePeriodStartDate,
  bookablePeriodEndDate
) => {
  const datesRange = slotsByDate.map((obj) => obj.date);
  return datesRange.find((selectedDate) =>
    isDateOutOfBookableWindow({ selectedDate, bookablePeriodStartDate, bookablePeriodEndDate })
  );
};

export const isDateDisabled = ({ date, bookablePeriodStartDate, bookablePeriodEndDate, slots }) =>
  isEmpty(slots) &&
  !isDateOutOfBookableWindow({
    selectedDate: date,
    bookablePeriodStartDate,
    bookablePeriodEndDate,
  });
