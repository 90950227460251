/**
 * DEPRECATED
 * this file will be replaced with src/concerns/common/phoneVerification/VerificationCodeForm.tsx
 *
 */
import { ChangeEvent, FC, ReactElement } from 'react';
import styled from 'styled-components';
import {
  Field,
  reduxForm,
  getFormSyncErrors,
  getFormSubmitErrors,
  InjectedFormProps,
  FormErrors as Errors,
} from 'redux-form';
import { connect } from 'react-redux';
import { theme, OverlayLoadingSpinner, BodyText1, Block, Col, Row } from 'klara-ui/old';
import { NumberField } from 'redux/forms/elements';
import { isEmpty } from 'util/validator';
import FormErrors from 'redux/forms/elements/Errors';
import Link from 'elements/Link';

import FormWithMobileStickyBottom from 'elements/FormWithMobileStickyBottom';
import Checkbox from 'klara-ui/old/components/Checkbox';

const LinkContainer = styled.div`
  margin: 15px 0;
  color: ${theme.colors.primaryBrand};
  font-size: ${theme.fonts.sizes.m};
`;

/**
 * Used to wrap a loading overlay around the 4 code input.
 * `display: inline-block` is to not make the element to stretch to parent's width
 */
const CodeInputContainer = styled.div`
  position: relative;
  display: inline-block;
  margin-bottom: 12px;
`;

export const FORM_NAME = 'patient/verificationCode';

/* eslint-disable camelcase */
const validate = ({ code = '' }) => {
  const errors: { code?: string } = {};

  if (!code || isEmpty(code)) {
    errors.code = 'not_present';
  }

  return errors;
};

interface PassedProps {
  onSubmit: ({ code }: { code: string }) => void;
  handleRetry?: () => void;
  onCheckboxChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  hideDescription?: boolean;
  digits?: number;
  patient2FA?: boolean;
  submitErrors?: Errors;
  errors?: Errors;
  retryButton?: ReactElement;
}

type FormProps = InjectedFormProps;

type Props = PassedProps & FormProps;

export const Form: FC<Props> = ({
  onSubmit,
  handleSubmit,
  handleRetry,
  submitting,
  errors,
  submitErrors,
  submitFailed,
  hideDescription,
  digits = 4,
  patient2FA,
  onCheckboxChange,
  retryButton,
}) => (
  <FormWithMobileStickyBottom onSubmit={handleSubmit}>
    <div>
      {!hideDescription ? (
        <BodyText1>
          We just texted you a verification code.
          <br />
          Please enter it below:
        </BodyText1>
      ) : null}

      {onCheckboxChange && (
        <Row pt={3} pb={4} middle="xs">
          <Col xs={1}>
            <Checkbox defaultChecked onChange={onCheckboxChange} theme="light" />
          </Col>
          <Col pb={2}>
            <BodyText1>Do not ask again on login for 30 days</BodyText1>
          </Col>
        </Row>
      )}

      <BodyText1>
        {digits}
        -digit code
      </BodyText1>

      <CodeInputContainer>
        <OverlayLoadingSpinner isLoading={submitting} />
        <Field
          data-test-id="verificationCode"
          name="code"
          disabled={submitting}
          handleSubmit={onSubmit}
          autoFocus
          inputSize={digits}
          component={NumberField}
        />
      </CodeInputContainer>
      {submitFailed && (
        <FormErrors
          errors={{
            ...errors,
            ...submitErrors,
          }}
          formName={FORM_NAME}
        />
      )}
      {patient2FA && (
        <Block brad={8} p={4} bgc="yellow-50">
          <BodyText1>
            If you can&apos;t see the email in your inbox please check your spam folder
          </BodyText1>
        </Block>
      )}
      <LinkContainer>
        {!submitting && !patient2FA && (
          <Link data-test-id="request-new-code" onClick={handleRetry}>
            Request a new code
          </Link>
        )}
        {retryButton}
      </LinkContainer>
    </div>
  </FormWithMobileStickyBottom>
);

export const VerificationCodeForm = reduxForm({
  form: FORM_NAME,
  validate,
})(Form);

const connector = connect<unknown, unknown, PassedProps>((state) => ({
  errors: getFormSyncErrors(FORM_NAME)(state),
  submitErrors: getFormSubmitErrors(FORM_NAME)(state),
}));

export default connector(VerificationCodeForm);
