import styled from 'styled-components';

export default styled.div`
  margin-bottom: 40px;
`;

export const SectionElementSeparator = styled.div`
  margin-bottom: 10px;
`;

export const ElementsSeperator = styled.div`
  margin-bottom: 24px;
`;
