import { actions as AlertActions } from 'redux/modules/common/alerts';

export function startConnectivityService(store) {
  let isOnline = window.navigator && window.navigator.onLine;
  const startedInVideoVisit = window.location.toString().includes('video-visit');

  if (!isOnline && !startedInVideoVisit)
    store.dispatch(AlertActions.pushWarning({ content: 'You are offline!' }));
  window.addEventListener(
    'offline',
    () => {
      const inVideoVisit = window.location.toString().includes('video-visit');
      if (isOnline && !inVideoVisit) {
        store.dispatch(AlertActions.pushWarning({ content: 'You are offline!', sticky: true }));
        isOnline = false;
      }
    },
    false
  );

  window.addEventListener(
    'online',
    () => {
      const inVideoVisit = window.location.toString().includes('video-visit');
      if (!isOnline && !inVideoVisit) {
        store.dispatch(AlertActions.resetStickyAlerts());
        store.dispatch(AlertActions.pushWarning({ content: 'You are back online!' }));
        isOnline = true;
      }
    },
    false
  );
}
