import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from '../../config/theme/klara';

const StyledLink = styled.a`
  color: ${theme.colors.primaryBrand};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const ExternalMoreInfoLink = ({ href, children }) => {
  return (
    <StyledLink href={href} target="_blank">
      {children}
    </StyledLink>
  );
};

ExternalMoreInfoLink.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
};

ExternalMoreInfoLink.displayName = 'ExternalMoreInfoLink';

export default ExternalMoreInfoLink;
