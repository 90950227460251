import logger from '@klara/logger';
import Pusher from 'pusher-js';
import { Dispatch, Store } from 'redux';
import { Actions, PusherActionTypes } from './types';

const internalActions = {
  connected: (): Actions => ({
    type: PusherActionTypes.connected,
    payload: {},
  }),
  connecting: (): Actions => ({
    type: PusherActionTypes.connecting,
    payload: {},
  }),
  disconnected: (): Actions => ({
    type: PusherActionTypes.disconnected,
    payload: {},
  }),
  unavailable: (): Actions => ({
    type: PusherActionTypes.unavailable,
    payload: {},
  }),
  failed: (): Actions => ({
    type: PusherActionTypes.failed,
    payload: {},
  }),
};

type ActionFnc = (data: unknown) => void;
type Event = Record<string, ActionFnc>;
type Channel = Record<string, Event>;

class PusherToDispatch {
  private dispatch: Dispatch = null;

  private client: Pusher = null;

  private channels: Record<string, Channel> = {};

  public setStore(store: Store) {
    this.dispatch = store.dispatch;
  }

  public setClient(c: Pusher) {
    this.client = c;

    this.client.connection.bind('connected', () => {
      logger.debug('Pusher callback connected');
      this.dispatch(internalActions.connected());
    });
    this.client.connection.bind('disconnected', () => {
      logger.debug('Pusher callback disconnected');
      this.dispatch(internalActions.disconnected());
    });
    this.client.connection.bind('connecting', () => {
      logger.debug('Pusher callback connecting');
      this.dispatch(internalActions.connecting());
    });
    this.client.connection.bind('unavailable', () => {
      logger.debug('Pusher callback unavailable');
      this.dispatch(internalActions.unavailable());
    });
    this.client.connection.bind('failed', () => {
      logger.debug('Pusher callback failed');
      this.dispatch(internalActions.failed());
    });
  }

  public subscribe(channelName: string, eventName: string, action: string) {
    logger.debug('Pusher subscribe attempt for', { channelName, eventName, action });
    const channel = this.client.channel(channelName) || this.client.subscribe(channelName);

    // Create channel if not exists
    if (!this.channels[channelName]) this.channels[channelName] = {};

    // Create event if not exists
    if (!this.channels[channelName][eventName]) this.channels[channelName][eventName] = {};

    // Create action if not exists and bind
    if (!this.channels[channelName][eventName][action]) {
      this.channels[channelName][eventName][action] = (data: unknown) => {
        logger.debug('Pusher callback for:', { channelName, eventName, action });
        this.dispatch({ type: action, data });
      };
      channel.bind(eventName, this.channels[channelName][eventName][action]);
    }
  }

  public unsubscribe(channelName: string, eventName: string, action: string) {
    logger.debug('Pusher unsubscribe attempt for', { channelName, eventName, action });
    const channel = this.client.channel(channelName);
    if (!channel) {
      logger.error('Trying to unsubscribe from pusher event but not subscribed to channel.', {
        channelName,
        eventName,
        action,
      });

      return;
    }

    if (!this.channels[channelName][eventName]) {
      logger.error('Trying to unsubscribe from pusher event but not subscribed to event.', {
        channelName,
        eventName,
        action,
      });

      return;
    }

    if (!this.channels[channelName][eventName]) {
      logger.error('Trying to unsubscribe from pusher event but no action was found.', {
        channelName,
        eventName,
        action,
      });

      return;
    }

    channel.unbind(eventName, this.channels[channelName][eventName][action]);
    delete this.channels[channelName][eventName][action];
  }

  public trigger(channelName: string, eventName: string, data: unknown) {
    logger.debug('Pusher trigger attempt for', { channelName, eventName, data });
    const channel = this.client.channel(channelName);
    if (!channel.subscribed) {
      logger.error('Pusher: Tried to send a message to a closed channel.', {
        channelName,
        eventName,
      });

      return;
    }

    channel.trigger(eventName, data);
  }

  public disconnect() {
    logger.debug('Pusher disconnecing');
    this.client.disconnect();
  }
}

export const pusherToDispatch = new PusherToDispatch();
