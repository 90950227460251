import { ReactNode, createContext, useMemo, useState } from 'react';

type PreviewURLs = { [fileName: string]: string };

export type FilePreviewContextType = {
  previewURLs: PreviewURLs;
  updatePreviewURLs: (previewURLs: PreviewURLs) => void;
};

export const FilePreviewContext = createContext<FilePreviewContextType>({
  previewURLs: null,
  updatePreviewURLs: () => null,
});

const FilePreviewProvider: React.FC<{children: ReactNode}> = ({ children }) => {
  const [previewURLs, setPreviewURLs] = useState<PreviewURLs>(null);

  const updatePreviewURLs = (newPreviewURLs: PreviewURLs) => {
    setPreviewURLs((prevState) => ({ ...prevState, ...newPreviewURLs }));
  };

  const contextValue = useMemo(
    () => ({
      previewURLs,
      updatePreviewURLs,
    }),
    [previewURLs, updatePreviewURLs]
  );
  return <FilePreviewContext.Provider value={contextValue}>{children}</FilePreviewContext.Provider>;
};

export default FilePreviewProvider;
