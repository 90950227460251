import PropTypes from 'prop-types';
import { Component } from 'react';
import Select from 'react-select';
import debounce from 'lodash/debounce';
import { T } from '@klara/klarity';
import CustomComponents, { Control, NoOptionsMessage, Option } from './AutoCompleteElements';

class AutoCompleteInput extends Component {
  state = { focused: false };

  componentDidMount() {
    this.onInputChange = debounce(this.onInputChange, 300);
  }

  onChange = (value) => {
    const { input } = this.props;
    if (input.onChange) {
      input.onChange(value);
    }
  };

  onInputChange = (query) => {
    const { search } = this.props;

    search(query);
  };

  onBlur = () => {
    const {
      input: { onBlur },
    } = this.props;

    this.setState({ focused: false });
    onBlur();
  };

  onFocus = (...args) => {
    const {
      input: { onFocus },
    } = this.props;

    this.setState({ focused: true });
    onFocus(args);
  };

  renderControl = (props) => {
    const { focused } = this.state;
    const meta = {
      error: !!props.meta?.error,
      touched: !!props.meta?.touched,
      submitFailed: !!props.meta?.submitFailed,
    };
    return <Control {...props} focused={focused} meta={meta} />;
  };

  renderNoOptionsMessage = () => {
    const { noOptionsText } = this.props;
    return <NoOptionsMessage>{noOptionsText}</NoOptionsMessage>;
  };

  render() {
    const {
      placeholder,
      options,
      label,
      isLoading,
      isMulti,
      input: { value, name },
      isClearable,
      getOptionLabel,
      getOptionValue,
      renderOption,
    } = this.props;
    const { focused } = this.state;

    return (
      <T htmlTag="label">
        {label}

        <Select
          name={name}
          ref={name}
          isMulti={isMulti}
          autoBlur
          onBlur={this.onBlur}
          onFocus={this.onFocus}
          onChange={this.onChange}
          onInputChange={this.onInputChange}
          openMenuOnFocus
          isClearable={isClearable}
          value={value}
          options={options}
          isLoading={focused && isLoading}
          placeholder={placeholder}
          components={{
            ...CustomComponents,
            Control: this.renderControl,
            DropdownIndicator: null,
            NoOptionsMessage: this.renderNoOptionsMessage,
            Option: renderOption || Option,
          }}
          getOptionLabel={getOptionLabel}
          getOptionValue={getOptionValue}
          filterOption={null}
          classNamePrefix="Select"
        />
      </T>
    );
  }
}

AutoCompleteInput.propTypes = {
  placeholder: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.array,
  meta: PropTypes.shape({
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    touched: PropTypes.bool.isRequired,
    submitFailed: PropTypes.bool.isRequired,
  }),
  input: PropTypes.shape({
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape(),
      PropTypes.arrayOf(PropTypes.shape()),
    ]),
    name: PropTypes.string.isRequired,
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  isMulti: PropTypes.bool.isRequired,
  search: PropTypes.func.isRequired,
  isClearable: PropTypes.bool.isRequired,
  getOptionLabel: PropTypes.func,
  getOptionValue: PropTypes.func,
  noOptionsText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  renderOption: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.func]),
};

AutoCompleteInput.defaultProps = {
  options: [],
  placeholder: 'Add',
  noOptionsText: 'No opions2',
};

export default AutoCompleteInput;
