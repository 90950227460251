import PropTypes from 'prop-types';
import { AbsoluteContainer, AvatarOptionRow, Box, Col, Icon, Row, T } from 'klara-ui/old';

export const Container = ({ children }) => (
  <Box fluid pos="relative">
    <Row>
      <Col xs={12}>{children}</Col>
    </Row>
  </Box>
);

Container.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.element])),
  ]),
};

export const Control = ({ children, meta, focused }) => {
  const { touched, error, submitFailed } = meta;
  let borderColor = 'grey-500';
  if (touched && error && submitFailed) {
    borderColor = 'red-600';
  } else if (focused) {
    borderColor = 'blue-700';
  }
  return (
    <T data-test-id="select-control">
      <Box fluid>
        <Row bc={borderColor} b={1} brad={8}>
          <Col xs={12}>
            <Row middle="xs" between="xs">
              {children}
            </Row>
          </Col>
        </Row>
      </Box>
    </T>
  );
};

Control.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.element])),
  ]),
  meta: PropTypes.shape({
    error: PropTypes.bool.isRequired,
    touched: PropTypes.bool.isRequired,
    submitFailed: PropTypes.bool.isRequired,
  }),
  focused: PropTypes.bool,
};

export const ValueContainer = ({ children }) => (
  <Col data-test-id="select-value-container">
    <Row middle="xs" pv={1} ph={5}>
      {children}
    </Row>
  </Col>
);

ValueContainer.propTypes = {
  children: PropTypes.node,
};

export const IndicatorsContainer = ({ children }) => <Col>{children}</Col>;

IndicatorsContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.element])),
  ]),
};

export const Menu = ({ children, innerProps }) => (
  <AbsoluteContainer data-test-id="select-menu" fluid left={0} right={0} z={50} {...innerProps}>
    <Row pt={2} middle="xs">
      <Col xs={12} bgc="grey-800" brad={8}>
        <Row>
          <Col pv={5} ph={6} xs={12}>
            {children}
          </Col>
        </Row>
      </Col>
    </Row>
  </AbsoluteContainer>
);

Menu.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.element])),
  ]),
  innerProps: PropTypes.shape({}),
};

export const Option = ({ children, data, innerProps }) => {
  const renderOptionRight = () => <Icon name="plus" ic="white" />;

  return (
    <AvatarOptionRow
      {...innerProps}
      url={data.avatar}
      name={data.name}
      title={children}
      dataTestId="select-option"
      renderRight={renderOptionRight}
    />
  );
};

Option.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.element])),
  ]),
  data: PropTypes.shape({}),
  innerProps: PropTypes.shape({}),
};

export const NoOptionsMessage = ({ children }) => (
  <Row middle="xs" center="xs">
    <Col>{children}</Col>
  </Row>
);

NoOptionsMessage.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.element])),
  ]),
};

export const MultiValueContainer = ({ children }) => (
  <Col ph={4} data-test-id="select-multi-value-container">
    <Row middle="xs" bgc="grey-100" brad={8} c="grey-700" mh="">
      {children}
    </Row>
  </Col>
);

MultiValueContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.element])),
  ]),
};

export const MultiValueLabel = ({ children }) => (
  <Col>
    <T variant="b1">{children}</T>
  </Col>
);

MultiValueLabel.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.element])),
  ]),
};

// eslint-disable-next-line no-unused-vars
export const MultiValueRemove = ({ innerProps: { className, ...handlers } }) => (
  <Col>
    <T {...handlers}>
      <Icon name="close" size={13} ic="grey-700" />
    </T>
  </Col>
);

MultiValueRemove.propTypes = {
  innerProps: PropTypes.shape({
    className: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    onMouseDown: PropTypes.func.isRequired,
    onTouchEnd: PropTypes.func.isRequired,
  }),
};

export default {
  Container,
  ValueContainer,
  IndicatorsContainer,
  Menu,
  Option,
  NoOptionsMessage,
  MultiValueContainer,
  MultiValueLabel,
  MultiValueRemove,
};
