import { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from '../../config/theme/klara';

const HasMoreContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${theme.fonts.sizes.xs};
  font-weight: ${theme.fonts.weights.regular};
  color: ${theme.colors.grey50};
  padding-bottom: 20px;
`;

const LoadMoreLink = styled.div`
  text-align: center;
  color: ${theme.colors.primaryBrand};
  font-size: ${theme.fonts.sizes.m};
  cursor: pointer;
  margin-top: 20px;
`;

class LoadMore extends Component {
  static propTypes = {
    data: PropTypes.array.isRequired,
    loadMore: PropTypes.func.isRequired,
    displayedItemsCount: PropTypes.number.isRequired,
  };

  label = (number) => {
    const { data } = this.props;
    return `Showing ${number} of ${data.length} items`;
  };

  render() {
    const { data, displayedItemsCount, loadMore } = this.props;
    const canLoadMore = data.length > displayedItemsCount;
    const { label } = this;

    return (
      <div>
        {data.length > displayedItemsCount && (
          <LoadMoreLink onClick={loadMore}> Load More </LoadMoreLink>
        )}
        <HasMoreContainer key="hasMore">
          {canLoadMore ? label(displayedItemsCount) : label(data.length)}
        </HasMoreContainer>
      </div>
    );
  }
}

export default LoadMore;
