import colors from './colors';
import icons from './icons';
import zIndex from './zIndex';
import * as fonts from './fonts';

export default {
  colors,
  fonts,
  icons,
  zIndex,
};
