import { call, takeLatest } from 'redux-saga/effects';
import { fetch } from 'persistence/reduxFetch';
import PatientTrackingEndpoint from 'persistence/patient/TrackingEndpoint';
import { PATIENT_AFTER_LOGIN } from 'redux/modules/patient/login';

export function* trackSaga({ eventData }) {
  yield call(fetch, PatientTrackingEndpoint.trackClientOpened({ eventData }));
}

export function* rootSaga() {
  yield takeLatest(PATIENT_AFTER_LOGIN, trackSaga);
}
