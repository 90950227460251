import { Color } from 'klara-ui/old/hoc/withDSTokens';
import { MouseEvent } from 'react';
import * as React from 'react';
import { IconName } from '../Icons/Icon';

export enum DesignTypes {
  primary = 'primary',
  secondary = 'secondary',
  red = 'red',
  ghost = 'ghost',
  ghostWhite = 'ghostWhite',
}

export enum Sizes {
  small = 'small',
  medium = 'medium',
  large = 'large',
}

export interface ButtonProps {
  children?: string | React.ReactNode;
  dataTestId?: string;
  disabled?: boolean;
  loading?: boolean;
  designType?: 'primary' | 'secondary' | 'red' | 'ghost' | 'ghostWhite';
  size?: 'small' | 'medium' | 'large';
  type?: 'submit' | 'button' | 'reset';
  fullWidth?: boolean;
  roundButton?: boolean;
  ic?: Color;
  icon?: 'left' | 'right' | 'no';
  iconName?: IconName;
  onClick?: (event?: React.ChangeEvent<HTMLInputElement> | MouseEvent<HTMLButtonElement>) => void;
}

export interface FloatingButtonsContainerProps {
  children?: string | React.ReactNode;
  position?: string;
}
