import SessionService from 'services/SessionService';

export default {
  logout() {
    return {
      url: '/patient/logout',
      method: 'POST',
      body: JSON.stringify({ refresh_token: SessionService.getRefreshToken() }),
    };
  },
};
