import store from 'store';

export default {
  isMobile() {
    return (
      /(iPad|iPhone|iPod|Android|Blackberry|Nokia|BB|Phone|iOS)/g.test(navigator.userAgent) ||
      window.innerWidth <= 768
    );
  },

  isMobileUA() {
    return /(iPad|iPhone|iPod|Android|Blackberry|Nokia|BB|Phone|iOS)/g.test(navigator.userAgent);
  },

  isMobilePhoneViewport() {
    return window.innerWidth <= 425;
  },

  isTabletViewport() {
    return window.innerWidth <= 768;
  },

  isDesktop() {
    return window.innerWidth >= 1024;
  },

  isFlutterApp() {
    return !!store.get('TRUSTED_DEVICE');
  },

  isiFrame() {
    return window.self !== window.top;
  },

  isIOSApp() {
    return !!window.cordova;
  },

  explorer() {
    /(MSIE|Trident)/g.test(navigator.userAgent);
  },

  isMobileSafari() {
    return (
      navigator.userAgent.match(/(iPod|iPhone|iPad)/) &&
      navigator.userAgent.match(/AppleWebKit/) &&
      !navigator.userAgent.match(/CriOS/)
    );
  },

  isUnderCypressTest() {
    return !!window.Cypress;
  },
};
