import { ACCEPTED_UPLOAD } from 'util/constants';

const maxFileSizeInBytes = 104857600;
const maxFileSizeInMB = `${maxFileSizeInBytes / 1024 / 1024} MB`;

function filterOutInvalidFile(files: File[]): File[] {
  const ivalidFileType = [...files]
    .filter((f) => !ACCEPTED_UPLOAD.includes(f.type))
    .map((f) => f.name)
    .join();
  const validFileTypes = [...files].filter((f) => ACCEPTED_UPLOAD.includes(f.type));

  if (ivalidFileType) {
    alert(`Failed to upload "${ivalidFileType}". File type not supported.`);
  }

  const invaidFileName = [...validFileTypes]
    .filter((f) => f.size >= maxFileSizeInBytes)
    .map((f) => f.name)
    .join();

  if (invaidFileName) {
    alert(`Failed to upload ${invaidFileName}, file size cannot exceed ${maxFileSizeInMB}.`);
  }

  return [...validFileTypes].filter((f) => f.size < maxFileSizeInBytes);
}

export default filterOutInvalidFile;
