import { HTMLAttributes } from 'react';
import * as React from 'react';
import { WrappedFieldProps } from 'redux-form';
import { LabelText1 } from 'klara-ui/old';
import classnames from 'classnames';

interface TextFieldProps extends HTMLAttributes<HTMLInputElement> {
  label: string;
}

const RadioField = ({
  className,
  label,
  meta: { touched, error, submitFailed },
  ...inputProps
}: WrappedFieldProps & TextFieldProps): React.ReactElement => {
  const fieldClassName = classnames('form__input-wrapper form__input-wrapper--radio', {
    'form__input-wrapper--error': touched && error && submitFailed,
  });

  const { input, ...props } = inputProps;
  return (
    <div className={fieldClassName}>
      <LabelText1 htmlTag="label">
        <input {...{ ...input, ...props, className }} />
        {label}
      </LabelText1>
    </div>
  );
};

export default RadioField;
