import camelCase from 'lodash/camelCase';

export function camelCaseKeys(object: any) {
  const result: any = {};
  for (const key in object) {
    if (Object.prototype.hasOwnProperty.call(object, key)) {
      const element: any = object[key];
      result[camelCase(key)] = element;
    }
  }
  return result;
}
