import { Colors, FontSizes, lightTheme } from '@klara/klarity';

const theme = {
  focusColor: Colors.blue400,
  fontSize: FontSizes.default,
  fontWeight: 400,
  avatar: {
    // These colors are used when there is no image but placeholder
    fontSizeBig: '20px',
    fontSize: FontSizes.small,
    fontSizeSmall: '11px',
    textColor: Colors.black500,
    borderColor: Colors.black300,
    backgroundColor: Colors.black50,
  },
  loadingAnimation: {
    grey: Colors.grey100,
    default: Colors.blue700,
  },
  loadingOverlay: {
    grey: Colors.grey50,
    default: Colors.white1000,
  },
  box: {
    borderColor: Colors.grey500,
    backgroundColor: {
      disabled: Colors.grey50,
      highlighted: Colors.blue100,
      default: 'transparent',
    },
  },
  button: {
    fontSize: FontSizes.default,
    fontSizeSmall: FontSizes.small,
    focusShadow: Colors.grey200,
    border: {
      secondary: `1px solid ${Colors.grey200}`,
      primary: 'none',
      alert: 'none',
      ghost: 'none',
      disabled: 'none',
      ghostWhite: 'none',
    },
    backgroundColor: {
      primary: Colors.blue700,
      secondary: Colors.white1000,
      alert: Colors.red600,
      ghost: 'transparent',
      disabled: Colors.black100,
      ghostWhite: 'transparent',
    },
    textColor: {
      primary: Colors.white1000,
      secondary: Colors.grey900,
      alert: Colors.white1000,
      ghost: 'inherit',
      disabled: Colors.black300,
      ghostWhite: Colors.white1000,
    },
    backgroundColorHover: {
      primary: Colors.blue500,
      secondary: 'transparent',
      alert: Colors.red500,
      ghost: Colors.trasparecyWhite,
      disabled: Colors.black100,
      ghostWhite: Colors.trasparecyWhite,
    },
    textColorHover: {
      primary: Colors.white1000,
      secondary: Colors.blue500,
      alert: Colors.white1000,
      ghost: Colors.black1000,
      disabled: Colors.black100,
      ghostWhite: Colors.white1000,
    },
    borderHover: {
      secondary: `1px solid ${Colors.blue500}`,
      primary: 'none',
      alert: 'none',
      ghost: 'none',
      disabled: 'none',
      ghostWhite: 'none',
    },
  },
  dropdown: {
    fontSize: FontSizes.default,
    lineHeight: 1.2,
    backgroundColor: Colors.white1000,
    textColor: Colors.grey900,
    textHoverColor: Colors.rose200,
    backgroundHoverColor: Colors.grey50,
    altTextColor: Colors.grey400,
    altFontSize: FontSizes.small,
    loadMoreButtonColor: Colors.blue700,
    separatorColor: Colors.grey100,
    focusBorder: Colors.blue700,
    divider: Colors.grey100,
    dropShadow: `drop-shadow(0px 6px 8px ${Colors.black100})`,

    group: {
      textColor: Colors.grey400,
    },

    disabeledText: {
      textColor: Colors.blue200,
    },

    // The dropdown has the inverted theme, therefore all
    // elements like search bar and pill needs to be defined here
    // to fit with the theme.
    searchBar: {
      backgroundColor: Colors.grey50,
      placeholderColor: Colors.grey400,
      textColor: Colors.grey700,
      stickyBackgroundColor: Colors.white1000,
    },
    pill: {
      backgroundColor: Colors.grey100,
      textColor: Colors.grey500,
      fontSize: FontSizes.small,
    },
    loadMoreItem: {
      linkColor: Colors.blue700,
      subtitleColor: Colors.grey400,
      subtitleFontSize: FontSizes.small,
    },
  },
  list: {
    padding: {
      default: '12px 0',
      big: '16px 0;',
    },
    listItem: {
      padding: {
        default: '12px 4px',
        big: '16px 8px;',
        box: '16px',
      },
      backgroundColor: {
        default: Colors.white1000,
        disabled: Colors.black100,
        higlighted: Colors.yellow50,
        hover: Colors.grey50,
      },
      textColor: {
        default: Colors.grey900,
        disabled: Colors.black300,
      },
      border: `1px solid ${Colors.grey100}`,
    },
  },
  toolbar: {
    backgroundColor: Colors.grey50,
    border: `1px solid ${Colors.grey200}`,
  },
  tooltip: {
    backgroundColor: Colors.black1000,
    color: Colors.white1000,
  },
  well: {
    backgroundColor: Colors.grey50,
  },
  wizard: {
    backgroundColor: {
      disabled: Colors.grey100,
      enabled: Colors.blue100,
    },
    textColor: {
      disabled: Colors.grey500,
      enabled: Colors.blue700,
    },
    disabledOverlay: {
      opacity: 0.5,
      color: '#FFFFFF',
    },
  },
  pill: {
    fontSize: FontSizes.default,
    fontSizeSmall: FontSizes.small,
    lineHeight: 1.2,
    backgroundColor: {
      secondary: Colors.grey100,
      primary: Colors.blue100,
      success: Colors.green50,
      error: Colors.red50,
    },
    textColor: {
      secondary: Colors.grey500,
      primary: Colors.blue700,
      success: Colors.green700,
      error: Colors.red600,
    },
  },
  selectToggle: {
    borderColor: Colors.grey500,
    backgroundColor: Colors.white1000,
    placeholderColor: Colors.grey500,
    textColor: Colors.black1000,
  },
  page: {
    edgePadding: '12px',
    maxWidth: '700px',
    small: '@media screen and (max-width: 425px)',
  },
  ...lightTheme,
};

export default theme;
