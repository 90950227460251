import { FC, ReactNode } from 'react';
import styled from 'styled-components';

const ToolbarContainer = styled.div`
  background-color: ${({ theme }) => theme.toolbar.backgroundColor};
  padding: 24px;
  border-radius: 6px;
  border: ${({ theme }) => theme.toolbar.border};
`;

const ResponsiveContainer = styled.div`
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const ToolbarOptions = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  flex-flow: wrap;
`;

const ActionButtons = styled.div`
  padding-top: 20px;
  text-align: right;
  display: flex;
  gap: 12px;
`;

interface Props {
  /** The TooltipItems as a react node. The children will be rendered as is into the container. */
  children: ReactNode;

  /** The action buttons for the toolbar that are going to be displayed right to the children passed to this component. */
  actionButtons: ReactNode;

  /** You can specify an extra component that will be displayed on the top of the toolbar like an extra
   * checkbox or title */
  extraComponent?: ReactNode;
}

const Toolbar: FC<Props> = ({ children, extraComponent = null, actionButtons, ...props }) => (
  <ToolbarContainer data-component-name="toolbar" {...props}>
    {extraComponent}
    <ResponsiveContainer>
      <ToolbarOptions>{children}</ToolbarOptions>
      <ActionButtons>{actionButtons}</ActionButtons>
    </ResponsiveContainer>
  </ToolbarContainer>
);

export default Toolbar;
