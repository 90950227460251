import styled from 'styled-components';
import theme from '../../config/theme/klara';
import withDSTokens from '../../hoc/withDSTokens';

const sizeStyles = {
  small: theme.fonts.sizes.s,
  medium: theme.fonts.sizes.m,
  large: theme.fonts.sizes.l,
};

type TextBlockProps = {
  weight?: number;
  color?: string;
  size?: keyof typeof sizeStyles;
};

const TextBlock = styled.div<TextBlockProps>`
  color: ${(props) => props.color ?? theme.colors.grey100};
  font-weight: ${(props) => props.weight ?? theme.fonts.weights.regular};
  line-height: 1.3;
  white-space: pre-wrap;
  font-size: ${(props) => sizeStyles[props.size] ?? sizeStyles.medium};
`;

TextBlock.displayName = 'TextBlock';

export default withDSTokens(TextBlock, { lineHeight: 1.3 });
