import PropTypes from 'prop-types';
import { FC } from 'react';
import FormError from 'elements/FormError';
import { FormErrors } from 'redux-form';

export type ErrorsType = {
  [fieldName: string]: string | string[];
};
type ErrorsProps = {
  errors: FormErrors<ErrorsType>;
  formName: string;
};
const Errors: FC<ErrorsProps> = ({ errors, formName }) => {
  if (!errors || !Object.keys(errors).length) {
    return null;
  }

  return (
    <div>
      {Object.keys(errors).length === 1 ? (
        <div
          className="form__errors-box"
          style={{
            display: 'block',
          }}
        >
          {Object.keys(errors).map(
            (fieldName) =>
              errors[fieldName] && (
                <FormError
                  formName={formName}
                  errorToken={errors[fieldName]}
                  fieldName={fieldName}
                  key={fieldName + errors[fieldName]}
                  singleError
                />
              )
          )}
        </div>
      ) : (
        <ul
          className="form__errors-box"
          style={{
            display: 'block',
          }}
        >
          {Object.keys(errors).map(
            (fieldName) =>
              errors[fieldName] && (
                <FormError
                  formName={formName}
                  errorToken={errors[fieldName]}
                  fieldName={fieldName}
                  key={fieldName + errors[fieldName]}
                />
              )
          )}
        </ul>
      )}
    </div>
  );
};

Errors.propTypes = {
  formName: PropTypes.string.isRequired,
  errors: PropTypes.shape({}).isRequired,
};

export default Errors;
