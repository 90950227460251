import { call, put, takeLatest } from 'redux-saga/effects';
import { defineTypes } from 'redux/helpers';
import ManifestEndpoint from 'persistence/ManifestEndpoint';
import logger from '@klara/logger';

export const updateTypes = defineTypes('CHECK_FOR_UPDATE');
export const FORCE_UPDATE = 'FORCE_UPDATE';

let currentAppVersion;

export const actions = {
  checkForUpdate(client) {
    return { type: updateTypes.REQUEST, client };
  },
  forceUpdate() {
    return { type: FORCE_UPDATE };
  },
};

export const initialState = {
  requiresUpdate: false,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case updateTypes.SUCCESS:
      return { requiresUpdate: action.requiresUpdate };
    case FORCE_UPDATE:
      return { requiresUpdate: true };
    default:
      return state;
  }
};

export const selectors = {
  requiresUpdate: ({ manifest: { requiresUpdate } }) => requiresUpdate,
};

export const reloadWindow = () => window.location.reload();

export function* updateIsRequiredSaga(client) {
  try {
    const { assets } = yield call(ManifestEndpoint.get, client);
    const newAppVersion = assets.map((item) => item).join('||');

    if (!currentAppVersion) currentAppVersion = newAppVersion;

    return currentAppVersion !== newAppVersion;
  } catch (error) {
    logger.error('Error while accessing manifest endpoint', error);
    return null;
  }
}

export function* checkForUpdateSaga({ client }) {
  try {
    const updateIsRequired = yield call(updateIsRequiredSaga, client);

    if (updateIsRequired) {
      yield put({ type: updateTypes.SUCCESS, requiresUpdate: true });
    } else {
      yield put({ type: updateTypes.SUCCESS, requiresUpdate: false });
    }
  } catch (error) {
    yield put({ type: updateTypes.FAILURE, error });
  }
}

export function* rootSaga() {
  yield takeLatest(updateTypes.REQUEST, checkForUpdateSaga);
}
