import config from 'config';
import { HTTPClient } from 'persistence/HTTPClient';
import addJsonHeaders from 'persistence/interceptors/addJsonHeaders';
import prefixUrl from 'persistence/interceptors/prefixUrl';
import qs from 'query-string';

interface PatientAuthCodeExchangeProps {
  code: string;
  redirect_uri: string;
  client_id: string;
}

interface PatientExchangeTokenProps {
  code: string;
  redirectUri: string;
}

export interface PatientAuthCodeExchaneResponse {
  access_token: string;
  refresh_token: string;
}

// Custom client without middleware
const client = HTTPClient({
  requestInterceptors: [prefixUrl(config.API_BASE_URL), addJsonHeaders],
  responseInterceptors: [],
});

const ExchangeKeycloakTokenEndpoint = {
  exchange: (props: PatientAuthCodeExchangeProps) => {
    const query = qs.stringify(props, { arrayFormat: 'bracket' });
    return client.fetch(`/patient_auth/code_exchange?${query}`, {
      method: 'GET',
    });
  },
  // Temporary endpoint here to support old magic links before SSO
  exchangeLink: (props: PatientExchangeTokenProps) => {
    return client.fetch('/patient_exchange_tokens', {
      method: 'POST',
      body: JSON.stringify({
        temporary_token: props.code,
        redirect_uri: props.redirectUri,
      }),
    });
  },
  refreshToken: (refreshToken: string) => {
    return client.fetch('/refresh_token', {
      method: 'POST',
      body: JSON.stringify({ refresh_token: refreshToken }),
    });
  },
};

export default ExchangeKeycloakTokenEndpoint;
