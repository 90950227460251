import styled from 'styled-components';
import { Box } from '../Grid';

interface AbsoluteContainerProps {
  fluid?: boolean;
  bottom?: string;
  left?: string;
  right?: string;
  top?: string;
}

const AbsoluteContainer = styled(Box)<AbsoluteContainerProps>`
  position: absolute;
  bottom: ${(props) => props.bottom};
  left: ${(props) => props.left};
  right: ${(props) => props.right};
  top: ${(props) => props.top};
`;

export default AbsoluteContainer;
