export default function handleRedirect(response) {
  if (response.ok) {
    const headers = {};
    if (response.headers.forEach) {
      // WARNING: the `whatwg-fetch` polyfill fails to polyfill iterators if Symbols aren't
      // available, and due to a configuration conversation with our outdated version of Babel, or
      // CoreJS, or babel-runtime. This is a hack to fix.
      response.headers.forEach((value, key) => {
        headers[key] = value;
      });
    } else {
      for (let pair of response.headers.entries()) {
        headers[pair[0]] = pair[1];
      }
    }

    if (headers.location) window.location = headers.location;
  }

  return Promise.resolve(response);
}
