import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import styled from 'styled-components';
import { OptionRow } from './OptionRow';
import { Avatar } from '../Avatar';
import Pill from '../Pills/Pill';
import { LabelText2 } from '../typography';

const AvatarOptionRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: ${({ alignTop }) => (alignTop ? 'start' : 'center')};
  white-space: nowrap;
`;

const TitleContainer = styled.div`
  padding-left: 15px;
  word-break: break-word;
`;

AvatarOptionRowContainer.displayName = 'AvatarOptionRowContainer';

class AvatarOptionRow extends PureComponent {
  renderLeft = () => {
    const { title, url, name, meta = '' } = this.props;
    const avatarName = !name && typeof title === 'string' ? title : name;

    return (
      <AvatarOptionRowContainer alignTop={!!meta}>
        <Avatar url={url} name={avatarName} size={25} />
        <TitleContainer>
          {title}
          {meta && (
            <LabelText2 c="blue-100" pv={2} data-test-id="meta">
              {meta}
            </LabelText2>
          )}
        </TitleContainer>
      </AvatarOptionRowContainer>
    );
  };
  renderRight = () => {
    const { count, renderRight } = this.props;

    if (renderRight) {
      return renderRight();
    }

    if (count) {
      return <Pill>{count}</Pill>;
    } else {
      return null;
    }
  };

  render() {
    const { onClick, className, ...rest } = this.props;

    return (
      <OptionRow
        className={className}
        renderLeft={this.renderLeft}
        onClick={onClick}
        renderRight={this.renderRight}
        {...rest}
      />
    );
  }
}

AvatarOptionRow.propTypes = {
  dataTestId: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  url: PropTypes.string,
  name: PropTypes.string,
  meta: PropTypes.string,
  onClick: PropTypes.func,
  renderRight: PropTypes.func,
  className: PropTypes.string,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

AvatarOptionRow.defaultProps = {
  dataTestId: 'avatar-option-row',
};

AvatarOptionRow.displayName = 'AvatarOptionRow';

export default AvatarOptionRow;
