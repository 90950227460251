import { PusherEvents } from './pusherChannelTypes';

export enum Entities {
  conversation = 'conversation',
  doctor = 'doctor',
  team = 'team',
}

export const enum PusherActionTypes {
  subscribe = 'COMMON/PUSHER/SUBSCRIBE',
  unsubscribe = 'COMMON/PUSHER/UNSUBSCRIBE',
  trigger = 'COMMON/PUSHER/TRIGGER',
  logout = 'COMMON/PUSHER/LOGOUT',
  connect = 'COMMON/PUSHER/CONNECT',
  // Pusher statuses
  connected = 'COMMON/PUSHER/CONNECTED',
  disconnected = 'COMMON/PUSHER/DISCONNECTED',
  connecting = 'COMMON/PUSHER/CONNECTING',
  unavailable = 'COMMON/PUSHER/UNAVAILABLE',
  failed = 'COMMON/PUSHER/FAILED',
}

export interface PusherActionPayload {
  entity: Entities;
  entityId: number;
  event: PusherEvents;
  postfix: string;
}

export interface PusherActionTriggerPayload extends PusherActionPayload {
  data: Record<string, number | string | boolean>;
}

interface PusherSubscribeAction {
  type: PusherActionTypes.subscribe;
  payload: PusherActionPayload;
}

interface PusherUnSubscribeAction {
  type: PusherActionTypes.unsubscribe;
  payload: PusherActionPayload;
}

export interface PusherTriggerChannelAction {
  type: PusherActionTypes.trigger;
  payload: PusherActionTriggerPayload;
}

interface PusherLogoutAction {
  type: PusherActionTypes.logout;
  payload: Record<string, never>;
}

interface PusherConnectAction {
  type: PusherActionTypes.connect;
  payload: Record<string, never>;
}

interface PusherConnectedAction {
  type: PusherActionTypes.connected;
  payload: Record<string, never>;
}

interface PusherDisconnectedAction {
  type: PusherActionTypes.disconnected;
  payload: Record<string, never>;
}

interface PusherConnectingAction {
  type: PusherActionTypes.connecting;
  payload: Record<string, never>;
}

interface PusherUnavaiableAction {
  type: PusherActionTypes.unavailable;
  payload: Record<string, never>;
}

interface PusherFailedAction {
  type: PusherActionTypes.failed;
  payload: Record<string, never>;
}

export type Actions =
  | PusherSubscribeAction
  | PusherUnSubscribeAction
  | PusherTriggerChannelAction
  | PusherLogoutAction
  | PusherConnectAction
  | PusherConnectedAction
  | PusherDisconnectedAction
  | PusherConnectingAction
  | PusherUnavaiableAction
  | PusherFailedAction;
