export const SET_BACK_LINK = 'SET_BACK_LINK';
export const SET_URL_AFTER_LOGIN = 'SET_URL_AFTER_LOGIN';
export const SET_REDIRECT_URL = 'SET_REDIRECT_URL';

export const actions = {
  setUrlAfterLogin(url) {
    return { type: SET_URL_AFTER_LOGIN, url };
  },
  setBackLink(url) {
    return { type: SET_BACK_LINK, url };
  },
  setRedirectUrl(url) {
    return { type: SET_REDIRECT_URL, url };
  },
};

export const initialState = {
  urlAfterLogin: null,
  backLink: null,
  redirectUrl: null,
};

export const selectors = {
  urlAfterLogin: ({ location: { urlAfterLogin } }) => urlAfterLogin,
  backLink: ({ location: { backLink } }) => backLink,
  redirectUrl: ({ location: { redirectUrl } }) => redirectUrl,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_URL_AFTER_LOGIN:
      return { ...state, urlAfterLogin: action.url };
    case SET_BACK_LINK:
      return { ...state, backLink: action.url };
    case SET_REDIRECT_URL:
      return { ...state, redirectUrl: action.url };
    default:
      return state;
  }
};
