import styled from 'styled-components';

export default styled.input`
  visibility: hidden;
  opacity: 0;
  width: 0;
  height: 0;
  z-index: -1;
  appearance: none;
  overflow: hidden;
  position: absolute;
  display: contents !important;
`;
