import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import colorExport from '../../tokens/exports/color/index.json';
import AvatarOptionRow from '../Lists/AvatarOptionRow';

export const selectStyles = {
  container: (base) => ({
    ...base,
    borderRadius: '6px',
  }),
  control: (base) => ({
    ...base,
    padding: '4px 8px',
    borderRadius: '6px',
    backgroundColor: colorExport.color['white'].hex,
    '&:hover': {
      borderColor: colorExport.color['blue-700'].hex,
    },
  }),
  menu: (base) => ({
    ...base,
    position: 'absolute',
    marginTop: 4,
    border: 'none',
    overflowY: 'scroll',
    padding: '10px 30px',
    background: colorExport.color['grey-900'].hex,
    opacity: 0.9,
  }),
  menuList: (base) => ({
    ...base,
    overflowX: 'hidden',
  }),
  input: (base) => ({
    ...base,
    display: 'flex',
    flex: '1 0 auto',
    alignItems: 'center',
    color: colorExport.color['grey-900'].hex,
  }),
  placeholder: (base, state) => ({
    ...base,
    color: state.isDisabled ? colorExport.color['grey-300'].hex : colorExport.color['grey-500'].hex,
  }),
  singleValue: (base, state) => ({
    ...base,
    color: state.isDisabled ? colorExport.color['grey-300'].hex : colorExport.color['grey-900'].hex,
  }),
  indicatorSeparator: () => {},
  dropdownIndicator: (defaultStyles, state) => ({
    ...defaultStyles,
    color: state.isDisabled ? colorExport.color['grey-300'].hex : colorExport.color['grey-900'].hex,
  }),
};

export default class FancyDropdown extends PureComponent {
  static propTypes = {
    placeholder: PropTypes.string,
    className: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    renderOption: PropTypes.func,
    renderTarget: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
        avatar: PropTypes.string,
      })
    ).isRequired,
    maxItems: PropTypes.number,
    disabled: PropTypes.bool,
    blurAfterSelect: PropTypes.bool,
    isSearchable: PropTypes.bool,
    dataTestId: PropTypes.string,
  };

  static defaultProps = {
    blurAfterSelect: false,
    disabled: false,
    isSearchable: false,
    dataTestId: 'fancy-dropdown',
  };

  static displayName = 'FancyDropdown';

  selectRef = null;

  handleChange = (selectedValue) => {
    const { onChange, value: currentValue, blurAfterSelect } = this.props;

    if (onChange) onChange(selectedValue, currentValue);
    if (blurAfterSelect && this.selectRef) this.selectRef.blur();
  };

  renderOption = ({ children, data, innerProps }) => (
    <AvatarOptionRow
      {...innerProps}
      url={data.avatar}
      name={data.label}
      title={children}
      ref={this.setOptionRef}
      className="searchable-dropdown-option"
    />
  );

  // this is a styled-components hack
  // react-select calls getBoundingClientRect on a focused / active element
  setOptionRef = (el) => {
    this.option = el;
  };

  setSelectRef = (el) => {
    this.selectRef = el;
  };

  render() {
    const {
      renderOption,
      options,
      value: displayValue,
      className,
      disabled,
      isSearchable,
      dataTestId,
      ...rest
    } = this.props;

    const value = options.find(
      ({ value: valueId }) =>
        displayValue && valueId && valueId.toString() === displayValue.toString()
    );

    return (
      <Select
        components={{
          Option: renderOption || this.renderOption,
        }}
        styles={selectStyles}
        {...rest}
        isDisabled={disabled}
        value={value || null}
        options={options}
        isSearchable={isSearchable}
        onChange={this.handleChange}
        ref={this.setSelectRef}
        className={className}
        classNamePrefix="Select"
        data-test-id={dataTestId}
        menuPlacement="auto"
      />
    );
  }
}
