import { createSlice } from '@reduxjs/toolkit';
import { PatientMatchOptionsResponse } from 'concerns/patient/scheduling/types';
import { DateString } from 'models/common.types';
import {
  ConvertedProviderType,
  ExtendedDepartmentType,
  ProviderType,
  ReasonType,
  SlotType,
} from 'models/patientTypes';

import { extraReducers, reducers } from './reducers';

export enum SchedulingStep {
  REASON_GROUP_SELECTION_STEP,
  REASON_SELECTION_STEP,
  SLOT_SELECTION_STEP,
  PATIENT_PROFILE_STEP,
  PATIENT_PHONE_STEP,
  PATIENT_MATCHING_STEP,
  PATIENT_INFORMATION_STEP,
  QUESTIONNAIRE_STEP,
  APPOINTMENT_SUMMARY_STEP,
}
export type SchedulingState = {
  currentStep: SchedulingStep;
  allStepsCompleted: boolean;
  exitSchedulingModalVisible: boolean;
  failedBookingModalVisible: boolean;
  hasPrefilledInsuranceImages: boolean;
  patientMatchId: PatientMatchOptionsResponse['result'][number]['id'];

  selectedLocation: ExtendedDepartmentType;
  selectedProvider: ConvertedProviderType;
  selectedReason: ReasonType;
  selectedReasonGroup: ReasonType;

  slotSelection: {
    unavailableProvidersRenderedAt: null | DateString | { [id: ProviderType['id']]: DateString };
    startDate: DateString;
    selectedDate: DateString;
    preselectedDate: DateString;
    selectedSlot: SlotType;
  };

  patientInformation: {
    patientName: string;
    phoneNumber: string;
    patientLastname: string;
    patientDOB: string;
    patientIsDependent: boolean;
    patientEmail: string;
    patientZip: string | number;
    patientHasHealthInsurance: boolean;
    insuranceCarrier: string;
    insurancePlan: string;
    memberId: string;
  };
};

export const initialState: SchedulingState = {
  currentStep: SchedulingStep.REASON_GROUP_SELECTION_STEP,
  allStepsCompleted: false,
  exitSchedulingModalVisible: false,
  failedBookingModalVisible: false,
  hasPrefilledInsuranceImages: false,
  patientMatchId: null,

  selectedLocation: null,
  selectedProvider: null,
  selectedReason: null,
  selectedReasonGroup: null,

  slotSelection: {
    unavailableProvidersRenderedAt: null,
    startDate: null,
    selectedDate: null,
    preselectedDate: null,
    selectedSlot: null,
  },

  patientInformation: {
    patientName: '',
    phoneNumber: '',
    patientLastname: '',
    patientDOB: null,
    patientIsDependent: false,
    patientEmail: '',
    patientZip: null,
    patientHasHealthInsurance: true,
    insuranceCarrier: '',
    insurancePlan: '',
    memberId: '',
  },
};

const schedulingSlice = createSlice({
  initialState,
  name: 'scheduling',
  reducers,
  extraReducers,
});

// ACTIONS
export const {
  resetSlots,
  resetReasons,
  onSelectReason,
  onSelectReasonGroup,
  onLocationChange,
  onProviderChange,
  setStartDate,
  setSelectedDate,
  onGoToNextStep,
  onGoToPreviousStep,
  onGoToStep,
  onSelectSlot,
  setPatientInformation,
  setPatientProfileData,
  onSetExitSchedulingModalVisible,
  onSetFailedBookingModalVisible,
  resetSchedulingState,
  onSetHasPrefilledInsuranceImages,
  onSelectPatientMatch,
  resetState,
  onFirstUnavailableProvidersRender,
} = schedulingSlice.actions;

// REDUCER
export default schedulingSlice.reducer;
