import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactToggleButton from 'react-toggle-button';
import theme from '../../config/theme/klara';

const Container = styled.div`
  display: flex;
`;

export const SIZES = {
  small: 'small',
  large: 'large',
};

const PRESETS = {
  small: {
    trackSize: 24,
    thumbSize: 16,
    borderWidth: 2,
  },
  large: {
    trackSize: 27,
    thumbSize: 18,
    borderWidth: 2,
  },
};

export const TYPES = {
  normal: 'normal',
  danger: 'danger',
  inactive: 'inactive',
  active: 'active',
};

/* eslint-disable react/jsx-no-bind */
export const ToggleButton = (props) => {
  const { size, dataTestId, value, color } = props;
  const { trackSize, thumbSize, borderWidth } = PRESETS[size];

  return (
    <Container data-test-id={dataTestId}>
      <ReactToggleButton
        containerStyle={{
          width: trackSize,
          height: thumbSize + 2,
        }}
        activeLabel=""
        inactiveLabel=""
        thumbStyle={{
          width: value ? thumbSize - 4 : thumbSize - 8,
          height: value ? thumbSize - 4 : thumbSize - 8,
          boxShadow: 'none',
        }}
        trackStyle={{
          width: trackSize,
          height: thumbSize,
          borderColor: value ? color : theme.colors.grey60,
          backgroundColor: 'blue',
          color: 'blue',
          borderWidth,
          borderStyle: 'solid',
        }}
        colors={{
          activeThumb: {
            base: 'white',
          },
          inactiveThumb: {
            base: theme.colors.grey60,
          },
          active: {
            base: color,
          },
          inactive: {
            base: theme.colors.white,
          },
        }}
        thumbAnimateRange={[4, 10]}
        {...props}
      />
    </Container>
  );
};

/* eslint-enable react/jsx-no-bind */

ToggleButton.propTypes = {
  dataTestId: PropTypes.string,
  size: PropTypes.oneOf([SIZES.small, SIZES.large]),
  type: PropTypes.oneOf([TYPES.normal, TYPES.danger, TYPES.inactive, TYPES.active]),
};

ToggleButton.defaultProps = {
  dataTestId: 'toggle-button',
  size: 'small',
  type: 'normal',
};

export default ToggleButton;
