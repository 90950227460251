import { FC } from 'react';
import { Icon, lightTheme } from 'klara-ui';
import { ThemeProvider } from 'styled-components';
import { Link, Modal, T } from 'klara-ui/old';

const helpPageUrl = 'https://updatemybrowser.org/';

const OutdatedApp: FC<Record<never, never>> = () => (
  <ThemeProvider theme={lightTheme}>
    <Modal center fullscreen>
      <T variant="b1" c="yellow-500">
        <Icon isBig name="alert" />
      </T>
      <T variant="h1" pv={4}>
        Please update your browser
      </T>
      <T variant="h2" pv={4}>
        Klara requires a newer browser version to use the app.
      </T>
      <T variant="b1" pv={3}>
        For help updating your browser please follow <Link href={helpPageUrl}>this link</Link> or
        contact your administrator.
      </T>
    </Modal>
  </ThemeProvider>
);

export default OutdatedApp;
