import PropTypes from 'prop-types';
import { Component } from 'react';
import styled from 'styled-components';
import OptionRow from './OptionRow';
import theme from '../../config/theme/klara';
import Col from '../Grid/Col';
import Row from '../Grid/Row';
import Pill from '../Pills/Pill';
import LabelText2 from '../typography/LabelText2';

const RowContainer = styled(Row)`
  display: flex;
  flex-direction: row;
  align-items: ${({ alignTop }) => (alignTop ? 'start' : 'center')};
  flex-wrap: nowrap;
  width: 100%;
`;

const Subtitle = styled.span`
  font-size: ${theme.fonts.sizes.s};
  color: ${theme.colors.grey60};
`;

const ValueContainer = styled(Col)`
  color: ${(props) => (props.disabled ? theme.colors.grey50 : 'auto')};
  display: flex;
  flex-direction: column;
  align-items: left;
  word-break: break-word;
  max-width: calc(100% - 30px); // 30px = width of the icon + padding
`;

class IconOptionRow extends Component {
  renderLeft = () => {
    const { title, subtitle, icon: Icon, avatarSize, disabled, meta = '' } = this.props;

    return (
      <RowContainer middle="xs" alignTop={!!meta}>
        <Col style={{ display: 'flex', paddingLeft: '6px' }}>
          <Icon size={avatarSize} />
        </Col>
        <ValueContainer disabled={disabled}>
          <span>{title}</span>
          {subtitle && <Subtitle>{subtitle}</Subtitle>}
          {meta && (
            <LabelText2 c="blue-100" pv={2} data-test-id="meta">
              {meta}
            </LabelText2>
          )}
        </ValueContainer>
      </RowContainer>
    );
  };

  renderRight = () => {
    const { renderRight } = this.props;

    if (renderRight) {
      return renderRight();
    }
    if ('count' in this.props) {
      return <Pill>{this.props.count}</Pill>;
    }
    return null;
  };

  render() {
    const { onClick, renderRight, className, ...rest } = this.props;

    return (
      <OptionRow
        className={className}
        renderLeft={this.renderLeft}
        onClick={onClick}
        renderRight={this.renderRight}
        {...rest}
      />
    );
  }
}

IconOptionRow.propTypes = {
  icon: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  onClick: PropTypes.func,
  renderRight: PropTypes.func,
  className: PropTypes.string,
  avatarSize: PropTypes.number,
  disabled: PropTypes.bool,
};

IconOptionRow.defaultProps = {
  avatarSize: theme.icons.sizes.small,
};

IconOptionRow.displayName = 'IconOptionRow';

export default IconOptionRow;
