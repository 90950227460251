export const SignupEndpoint = {
  create(phoneVerificationToken, teamId) {
    return {
      url: '/signup',
      method: 'POST',
      body: JSON.stringify({
        phone_verification_token: phoneVerificationToken,
        team_id: teamId,
      }),
    };
  },
  submit2FA(phoneVerificationToken, code, remember_device) {
    return {
      url: '/signup',
      method: 'POST',
      body: JSON.stringify({
        phone_verification_token: phoneVerificationToken,
        code,
        remember_device,
      }),
    };
  },
};

export default SignupEndpoint;
