import logger from '@klara/logger';

import generateUuid from 'util/uuid';

export const initialState = [];

export const SET_ALERTS = 'SET_ALERTS';
export const PUSH_ALERT = 'PUSH_ALERT';
export const RESET_ALERTS = 'RESET_ALERTS';
export const POP_ALERT = 'POP_ALERT';
export const RESET_STICKY_ALERTS = ' RESET_STICKY_ALERTS';
export const REMOVE_ALERT = ' REMOVE_ALERT';

export const ALERT_TYPES = {
  ERROR: 'error',
  SUCCESS: 'success',
  WARNING: 'warning',
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ALERTS:
      return [
        ...[...state, ...action.alerts].filter((alert) => alert.sticky),
        ...[...state, ...action.alerts].filter((alert) => !alert.sticky),
      ];
    case PUSH_ALERT:
      return alert.sticky ? [action.alert, ...state] : [...state, action.alert];
    case RESET_ALERTS:
      return initialState;
    case RESET_STICKY_ALERTS:
      return state.filter((alert) => !alert.sticky);
    case REMOVE_ALERT:
      return state.filter((alert) => alert.key !== action.key);
    default:
      return state;
  }
};

export const actions = {
  set(alerts) {
    return { type: SET_ALERTS, alerts };
  },

  push(alert) {
    return { type: PUSH_ALERT, alert: { key: generateUuid(), ...alert } };
  },

  pushError(alert) {
    return actions.push({ ...alert, type: ALERT_TYPES.ERROR });
  },

  pushDefaultError() {
    return actions.push({
      content: 'Something went wrong. Please try again.',
      type: ALERT_TYPES.ERROR,
    });
  },

  pushDefaultErrorAndNotify(error) {
    logger.error('Something went wrong', {}, error);
    return this.pushDefaultError();
  },

  pushSuccess(alert) {
    return actions.push({ ...alert, type: ALERT_TYPES.SUCCESS });
  },

  pushWarning(alert) {
    return actions.push({ ...alert, type: ALERT_TYPES.WARNING });
  },

  reset() {
    return { type: RESET_ALERTS };
  },

  resetStickyAlerts() {
    return { type: RESET_STICKY_ALERTS };
  },
  removeAlert(key) {
    return { type: REMOVE_ALERT, key };
  },
};

export const selectors = {
  alerts(state) {
    return state.alerts;
  },
  stickyAlerts(state) {
    return state.alerts.filter((alert) => alert.sticky);
  },
};
