import { reactRouterNavigateTo } from 'actions/LocationActions';
import { getAppClient } from 'boot/application';
import { routeBuilders } from 'boot/patient/routes';
import apiConfig from 'persistence/apiConfig';
import HTTPClient from 'persistence/HTTPClient';
import HTTPError from 'persistence/HTTPError';
import prefixUrl from 'persistence/interceptors/prefixUrl';
import { refreshTokenIfAccessTokenExpired } from 'persistence/interceptors/refreshTokenIfNeeded';
import returnJsonIfSuccessful from 'persistence/interceptors/returnJsonIfSuccessful';
import { actions as AlertActions } from 'redux/modules/common/alerts';
import { actions as sessionActions } from 'redux/modules/common/session';
import { call, put } from 'redux-saga/effects';
import SessionService, { sessionId } from 'services/SessionService';

import generateUuid from 'util/uuid';


const MAINTENANCE_MODE_MESSAGE = {
  content:
    'Klara is currently offline for scheduled maintenance. Any messages sent during the maintenance will be delivered when we come back online, and any voicemails will receive an auto-response asking to call back later. We apologize for any inconvenience, and we thank you for your patience as we continue making Klara even better!',
  sticky: true,
  key: 'maintenanceMode',
};

let maintenanceMode = false;

const client = HTTPClient({
  requestInterceptors: [prefixUrl(apiConfig.baseURL)],

  responseInterceptors: [refreshTokenIfAccessTokenExpired, returnJsonIfSuccessful],
});

/*
 *
 * for now I am allowing only exchange_tokens and phone_update_confirmations endpoint to return a 403,
 * all others will result with the local store being wiped and patient being logged-out
 *
 */
const WHITELISTED_403_ENDPOINTS = [
  /\/patient_exchange_tokens/,
  /\/patient\/phone_update_confirmations/,
  /\/patient\/conversations\/\d+\/messages/,
  /\/patient\/conversations/,
  /\/patient\/conversations\/\d+\/access/,
  /patient\/conversations\/\d+\/failed_dob_verification/,
  /\/api\/doctor_signin/,
  /\/activation\/set_personal_info/,
  /\/reset_password/,
  /\/activation\/accept_invitation/,
  /\/patient\/video_call\/meeting/,
  /\/activation\/create_profile/,
];

const WHITELISTED_401_ENDPOINTS = [
  /\/patient_exchange_tokens/,
  /\/refresh_token/,
  /\/email_verification_codes\/validate/,
  /\/doctor\/logout/,
  /\/patient\/logout/,
  /\/api\/patient_exchange_tokens/,
  /\/signup/,
];

const is401Whitelisted = (url) => {
  return WHITELISTED_401_ENDPOINTS.some((endpoint) => endpoint.test(url));
};

const is403Whitelisted = (url) => {
  return WHITELISTED_403_ENDPOINTS.some((endpoint) => endpoint.test(url));
};

let transactionRootId = generateUuid();

window.onhashchange = () => {
  transactionRootId = generateUuid();
};

export function* fetchStatusHeadersBody(params, handleErrors = true) {
  const { url, ...options } = params;
  const appClient = getAppClient();

  const headers = {
    'X-AUTH-TOKEN': SessionService.getToken(),
    Accept: 'application/json',
    'X-Request-Id': `${sessionId}_${generateUuid()}`,
    'X-Transaction-Root-Id': `${sessionId}_${transactionRootId}`,
  };

  if (!(options.body instanceof FormData)) headers['Content-Type'] = 'application/json';

  const { status, body } = yield call(client.fetch, url, {
    ...options,
    headers: { ...headers, ...options.headers },
    credentials: 'include',
  });

  if (status && status >= 400) {
    if (status === 403 && body?.account && body.account[0] === 'team_under_maintenance') {
      window.flutter_inappwebview?.callHandler?.('onMaintenanceMode');
      maintenanceMode = true;
      if (url.includes('/doctor_signin')) {
        yield put(AlertActions.pushWarning(MAINTENANCE_MODE_MESSAGE));
      } else {
        yield put(
          sessionActions.destroySession(
            appClient,
            null,
            null,
            AlertActions.pushWarning(MAINTENANCE_MODE_MESSAGE)
          )
        );
      }
      throw yield call(HTTPError.fromStatusBody, status, body);
    }

    if (
      handleErrors &&
      ((status === 401 && !is401Whitelisted(url)) || (status === 403 && !is403Whitelisted(url)))
    ) {
      if (appClient === 'doctor') {
        yield put(sessionActions.destroySession(appClient));
      } else {
        yield call(reactRouterNavigateTo, routeBuilders.loginRoute());
      }
    }
    throw yield call(HTTPError.fromStatusBody, status, body);
  }

  if (maintenanceMode) {
    maintenanceMode = false;
    yield put(AlertActions.removeAlert(MAINTENANCE_MODE_MESSAGE.key));
  }
  return { status, headers, body };
}

export function* fetch(params, handleErrors = true) {
  const { body } = yield call(fetchStatusHeadersBody, params, handleErrors);

  return body;
}
