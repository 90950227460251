export default {
  flexboxgrid: {
    // Defaults
    gridSize: 12, // columns
    gutterWidth: 1, // rem
    outerMargin: 0.5, // rem
    mediaQuery: 'all',
    container: {
      sm: 46, // rem
      md: 61, // rem
      lg: 61, // rem
    },
    breakpoints: {
      xs: 0, // em
      sm: 48, // em
      md: 64, // em
      lg: 75, // em
    },
  },
};
