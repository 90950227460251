import { ReactElement, useState } from 'react';
import { ProviderType } from 'models/patientTypes';
import styled from 'styled-components';
import SearchDropdown from './SearchDropdown';
import { TextInput } from '../forms';
import Icon from '../Icons/Icon';
import { CheckboxOptionRow } from '../Lists';

const InputWithArrow = styled(TextInput)`
  cursor: pointer;
  width: 100%;
`;

const TargetContainer = styled('div')`
  position: relative;
  width: 100%;
  & span {
    position: absolute;
    right: 6px;
    top: 6px;
  }
`;

interface FilterProvider extends ProviderType {
  selected: boolean;
}

export interface CheckboxDropdownProps {
  itemName: string;
  options: any[];
  selectedOptions: string[];
  returnSelected: (data: string[] | undefined) => void;
  keepOpenedOnSelect?: boolean;
  [key: string]: any;
}

export const CheckboxDropdown = ({
  returnSelected,
  keepOpenedOnSelect,
  options,
  selectedOptions,
  itemName,
  ...rest
}: CheckboxDropdownProps): ReactElement => {
  const isAllSelected = typeof selectedOptions === 'undefined';
  const [isOpen, setIsOpen] = useState(false);

  const selectAllClicked = (checkboxValue: boolean) => {
    if (checkboxValue) {
      returnSelected(undefined);
    } else {
      returnSelected([]);
    }
  };

  const getOptionsWithAll = (): FilterProvider[] => {
    const selectAllOption = {
      label: 'All',
      name: 'All',
      id: '-1',
      selected: isAllSelected,
    };

    const newOptions = options.map((option) => {
      if (isAllSelected) {
        return { ...option, selected: true };
      }
      const foundOpt = selectedOptions.find((selectedOpt) => selectedOpt === option.id);
      return { ...option, selected: !!foundOpt };
    });

    newOptions.unshift(selectAllOption);
    return newOptions;
  };

  const handleCheckboxChange = (data: ProviderType, checkboxValue: boolean) => {
    if (checkboxValue) {
      if (selectedOptions.length === options.length - 1) {
        selectAllClicked(true);
      } else {
        const foundOption = options.find(({ id }) => id === data.id);
        returnSelected([...selectedOptions, foundOption.id]);
      }
    } else if (isAllSelected) {
      returnSelected(options.filter((option) => option.id !== data.id).map((option) => option.id));
    } else {
      const foundIndex = selectedOptions.findIndex((id) => id === data.id);
      const newSelected = [...selectedOptions];
      newSelected.splice(foundIndex, 1);
      if (newSelected.length === 0) {
        selectAllClicked(false);
      }
      returnSelected(newSelected);
    }
  };

  const getPlaceholderText = () => {
    const capitalize = (string: string): string =>
      string.charAt(0) ? string.charAt(0).toUpperCase() + string.slice(1) : '';

    if (typeof selectedOptions === 'undefined') {
      return `All ${capitalize(itemName)}s`;
    }
    if (selectedOptions.length === 0) {
      return `No ${itemName} selected`;
    }
    if (selectedOptions.length === 1) {
      return `1 ${capitalize(itemName)}`;
    }
    return `${selectedOptions.length} ${capitalize(itemName)}s`;
  };

  const renderTarget = () => (
    <TargetContainer data-test-id="checkbox-dropdown-target" {...rest}>
      <InputWithArrow placeholder={getPlaceholderText()} />
      <Icon name="chevron-down" />
    </TargetContainer>
  );
  const renderOption = (data: FilterProvider) => (
    <CheckboxOptionRow
      theme="dark"
      key={data.id}
      title={data.name}
      onCheck={(checkboxValue) => {
        if (data.id === '-1') {
          selectAllClicked(checkboxValue);
        } else {
          handleCheckboxChange(data, checkboxValue);
        }
      }}
      checked={data.selected}
      dataTestId={`${data.name}-option-${data.id}`.toLowerCase()}
      data-cy="checkbox-option-row"
      data-cy-id={data.id}
    />
  );

  return (
    <SearchDropdown
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      onOpen={() => setIsOpen(true)}
      renderTarget={renderTarget}
      renderOption={renderOption}
      keepOpenedOnSelect={keepOpenedOnSelect}
      options={getOptionsWithAll()}
      width="320px"
      {...rest}
    />
  );
};

export default CheckboxDropdown;
