import { ComponentType } from 'react';
import { connect, ConnectedComponent } from 'react-redux';
import { PatientRootState } from 'redux/modules/patient/reducers';
import {
  reduxForm,
  getFormSyncErrors,
  getFormSubmitErrors,
  InjectedFormProps,
  FormErrors,
  ConfigProps,
} from 'redux-form';

interface StateProps<FormData> {
  errors: FormErrors<FormData>;
  submitErrors: FormErrors<FormData>;
}

export const reduxFormWithErrors = <T, FormData>({
  formName,
  form,
  validate,
  enableReinitialize,
  ...reduxFormParams
}: {
  formName: string;
  form: ComponentType<T & InjectedFormProps<FormData, T>>;
  validate?: (values: any) => any;
  enableReinitialize?: boolean;
} & Partial<Omit<ConfigProps, 'form'>>): ConnectedComponent<any, any> => {
  const connectedForm = reduxForm({
    form: formName,
    validate,
    enableReinitialize,
    ...reduxFormParams,
  })(form);

  const mapStateToProps = (state: PatientRootState): StateProps<FormData> => ({
    errors: getFormSyncErrors(formName)(state),
    submitErrors: getFormSubmitErrors(formName)(state),
  });

  return connect<StateProps<FormData>, T>(mapStateToProps)(connectedForm);
};
