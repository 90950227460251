import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Block } from '..';
import theme from '../../config/theme/klara';
import Icon from '../Icons/Icon';

const SearchInputContainer = styled.div`
  position: relative;
  margin-bottom: 10px;
`;

const StyledSearchInput = styled.input`
  background-color: ${theme.colors.grey0};
  border: 0 none;
  border-radius: 20px;
  display: block;
  font-size: ${theme.fonts.sizes.m};
  height: 40px;
  padding: 14px 50px;
  width: 100%;
  outline: none;
  &::-webkit-search-cancel-button {
    display: none;
  }
  &[type='search'] {
    box-sizing: border-box;
  }
`;

const InputButtonContainer = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  position: absolute;
  top: 0;
  width: 40px;
  color: ${theme.colors.grey25};
`;

const SearchIconContainer = styled(InputButtonContainer)`
  left: 0;
  & span {
    cursor: default;
  }
`;

const ClearButtonContainer = styled(InputButtonContainer)`
  right: 0;
`;

const ClearButton = styled(Block)`
  cursor: pointer;
  transition: color 0.5s;
  &:hover {
    color: ${theme.colors.grey100};
  }
`;

const SearchInput = ({ onBlur, onChange, onClear, onFocus, placeholder, value }) => (
  <SearchInputContainer>
    <SearchIconContainer>
      <Icon name="magnifying-glass" isSmall />
    </SearchIconContainer>
    <StyledSearchInput
      onBlur={onBlur}
      autoFocus
      onChange={onChange}
      onFocus={onFocus}
      placeholder={placeholder}
      value={value}
      type="search"
      data-test-id="search-field"
    />
    <ClearButtonContainer data-test-id="sign-clear">
      <ClearButton onClick={onClear}>
        <Icon name="close-circle" />
      </ClearButton>
    </ClearButtonContainer>
  </SearchInputContainer>
);

SearchInput.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onClear: PropTypes.func,
};

SearchInput.displayName = 'SearchInput';

export default SearchInput;
