import PropTypes from 'prop-types';
import { Component } from 'react';
import classnames from 'classnames';
import { PlainSelectField } from 'redux/forms/elements';
import range from 'lodash/range';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
].map((month, index) => ({ value: index + 1 > 9 ? index + 1 : `0${index + 1}`, label: month }));

const years = range(1900, new Date().getFullYear() + 1)
  .reverse()
  .map((item) => ({ value: item, label: item }));

class DateField extends Component {
  constructor(props) {
    super(props);

    let numberOfDays = 31;
    let selectedYear = null;
    let selectedMonth = null;
    if (!isUndefined(props.initialValues)) {
      const initialDate = props.initialValues[props.name];
      if (!isEmpty(initialDate)) {
        numberOfDays = new Date(initialDate.year, initialDate.month, 0).getDate();
        selectedYear = initialDate.year;
        selectedMonth = initialDate.month;
      }
    }
    this.state = {
      daysInMonth: numberOfDays,
      selectedMonth: selectedMonth,
      selectedYear: selectedYear,
    };
  }

  static propTypes = {
    className: PropTypes.string,
    selectClassName: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    errors: PropTypes.shape({}),
    submitErrors: PropTypes.shape({}),
    submitFailed: PropTypes.bool,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    name: 'birth_date',
  };

  fieldClassName = () => {
    const { name, className, errors, submitFailed, submitErrors } = this.props;
    const hasError =
      submitFailed && ((errors && errors[name]) || (submitErrors && submitErrors[name]));
    return classnames('form__input-wrapper form__input-wrapper--birth_date', className, {
      'form__input-wrapper--error': hasError,
    });
  };

  handleMonthChange = (e) => {
    const year = this.state.selectedYear || 1900;
    this.setState({
      selectedMonth: e.target.value,
      daysInMonth: new Date(year, e.target.value, 0).getDate(),
    });
  };

  handleYearChange = (e) => {
    const month = this.state.selectedMonth || '11';
    this.setState({
      selectedYear: e.target.value,
      daysInMonth: new Date(e.target.value, month, 0).getDate(),
    });
  };

  days = () =>
    range(1, this.state.daysInMonth + 1).map((item) => ({
      value: item > 9 ? item : `0${item}`,
      label: item,
    }));

  renderDateFields = (dateFieldConfig) => {
    const { label, className, name, disabled } = this.props;

    return (
      <div className={`${this.fieldClassName()}`}>
        <label className="form__label">{label}</label>
        <PlainSelectField
          {...dateFieldConfig['month']}
          className={className}
          name={`${name}.month`}
          key="month"
          disabled={disabled}
          onChange={this.handleMonthChange}
        />

        <PlainSelectField
          {...dateFieldConfig['day']}
          className={className}
          name={`${name}.day`}
          key="day"
          disabled={disabled}
        />

        <PlainSelectField
          {...dateFieldConfig['year']}
          className={className}
          name={`${name}.year`}
          key="year"
          disabled={disabled}
          onChange={this.handleYearChange}
        />
      </div>
    );
  };

  render() {
    const dateFieldConfig = {
      month: {
        options: months,
        emptyLabel: 'Month',
      },
      day: {
        options: this.days(),
        emptyLabel: 'Day',
      },
      year: {
        options: years,
        emptyLabel: 'Year',
      },
    };

    return this.renderDateFields(dateFieldConfig);
  }
}

export default DateField;
