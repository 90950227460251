import PropTypes from 'prop-types';
import { DropdownMenu } from 'klara-ui/old';

const Field = ({ items, renderItem, renderTitle }) => (
  <DropdownMenu
    renderTitle={renderTitle}
    renderItem={renderItem}
    items={items}
    dropdownPosition="0px"
  />
);

Field.propTypes = {
  items: PropTypes.array.isRequired,
  renderItem: PropTypes.func.isRequired,
  renderTitle: PropTypes.func.isRequired,
};

export default Field;
