import PropTypes from 'prop-types';
import styled from 'styled-components';

const FlexRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
`;

const Component = ({ style = undefined, className = undefined, children }) => (
  <FlexRow style={style} className={className}>
    {children}
  </FlexRow>
);

Component.propTypes = {
  className: PropTypes.string,
  style: PropTypes.shape({}),
  children: PropTypes.node,
};

Component.displayName = 'FlexRow';

export default Component;
