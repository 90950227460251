import config from 'config';

export default {
  get(app) {
    let url = '';

    if (config.NODE_ENV === 'development') {
      url = config.FRONTEND_BASE_PATH;
    } else {
      url = app === 'doctor' ? config.FRONTEND_BASE_PATH_DOCTOR : config.FRONTEND_BASE_PATH_PATIENT;
    }
    if (url.slice(-1) !== '/') {
      url = `${url}/`;
    }
    const manifestSuffix = config.NODE_ENV === 'development' ? `_${app}` : '';

    return fetch(`${url}manifest${manifestSuffix}.json`).then((data) => data.json());
  },
};
