import { routeBuilders } from 'boot/patient/routes';
import AccountEndpoint from 'persistence/patient/AccountEndpoint';
import { fetch } from 'persistence/reduxFetch';
import { defineTypes } from 'redux/helpers';
import { actions as AlertActions } from 'redux/modules/common/alerts';
import { actions as sessionActions } from 'redux/modules/common/session';
import { put, call, takeLatest } from 'redux-saga/effects';

export const getAccountTypes = defineTypes('PATIENT/CURRENT_PATIENT_ACCOUNT/GET');
export const updateAccountTypes = defineTypes('PATIENT/CURRENT_PATIENT_ACCOUNT/PUT');

export const initialState = {
  account: null,
  isLoading: false,
};

export const actions = {
  getAccount() {
    return { type: getAccountTypes.REQUEST };
  },
};

export const selectors = {
  currentPatientAccount: (state) => state.currentPatient.account,
  isLoading: (state) => state.currentPatient.isLoading,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case getAccountTypes.REQUEST:
      return { ...state, isLoading: true };
    case getAccountTypes.SUCCESS:
      return { ...state, account: action.account, isLoading: false };
    case getAccountTypes.FAILURE:
      return { ...state, isLoading: false, account: null };
    default:
      return state;
  }
};

export function* getAccountSaga() {
  try {
    const response = yield call(fetch, AccountEndpoint.show());

    yield put({ type: getAccountTypes.SUCCESS, account: response });
  } catch (error) {
    yield put({ type: getAccountTypes.FAILURE, error });
    yield put(
      AlertActions.pushError({ content: 'An account with the provided credentials was not found.' })
    );
    yield put(sessionActions.destroySession('patient', routeBuilders.inboxRoute()));
  }
}

export function* rootSaga() {
  yield takeLatest(getAccountTypes.REQUEST, getAccountSaga);
}
