import { FC } from 'react';
import styled from 'styled-components';
import { useMemoryState } from '../../../hooks/useMemoryState';

// Has to be img, so it can have onError handler.
const ImageStyleElement = styled.img<{ size: number }>`
  border-radius: 50%;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  background: url(${(props) => props.src}) center center no-repeat;
  background-size: cover;
  flex-shrink: 0;
  object-fit: cover;
`;

const PlaceHolder = styled.div<{ size: number }>`
  border: 1px solid ${({ theme }) => theme.avatar.borderColor};
  background-color: ${({ theme }) => theme.avatar.backgroundColor};
  border-radius: 50%;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.avatar.textColor};
  font-size: ${({ size, theme }) =>
    size < 30 ? theme.avatar.fontSizeSmall : theme.avatar.fontSize};
`;

/**
 * The function tries to get the initials form the name.
 * For example:
 *  Levente Berky -> LB
 *  '' -> ''
 *  Maletin -> M
 *
 * @param name The full name of the User
 * @returns Initials capitalized, calculated form the name.
 */
const calculateNameForAvatar = (name: string) => {
  if (!name) return '';
  return name
    .trim()
    .replace(/[^a-zA-Z]+/g, ' ')
    .split(' ')
    .map((item) => item[0]?.toUpperCase())
    .splice(0, 2);
};

interface Props {
  /** Defines the source of the image which corresponds to the avatar. */
  url?: string;

  /** Defines the intials displayed in the avatar when the image from URL is not found/valid. */
  name?: string;

  /** Defines if the avatar should be bigger than normal. */
  isBig?: boolean;

  /** Defines if the avatar should be smaller than normal. */
  isSmall?: boolean;
}

const getSize = (isBig: boolean, isSmall: boolean): number => {
  if (isBig) return 40;
  if (isSmall) return 24;
  return 32;
};

const Avatar: FC<Props> = ({ url, name = '', isBig, isSmall, ...rest }) => {
  const calcSize = getSize(isBig, isSmall);

  const [avatarUrlValidity, setAvatarUrlValidity] = useMemoryState('avatarUrlValidity', {});

  const onImageUrlFailed = () => {
    setAvatarUrlValidity({
      ...avatarUrlValidity,
      [url]: true,
    });
  };

  const urlFailed = avatarUrlValidity[url];

  if (url && !urlFailed) {
    return (
      <ImageStyleElement
        src={url}
        onError={onImageUrlFailed}
        data-test-id="avatar"
        data-component-name="avatar"
        size={calcSize}
        {...rest}
      />
    );
  }

  const shortTitleName = calculateNameForAvatar(name);
  if (shortTitleName) {
    return (
      <PlaceHolder data-component-name="avatar" size={calcSize} {...rest}>
        {shortTitleName}
      </PlaceHolder>
    );
  }

  return <PlaceHolder size={calcSize} data-component-name="avatar" {...rest} />;
};

export default Avatar;
