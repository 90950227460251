const ACTION_STATES = ['REQUEST', 'SUCCESS', 'FAILURE', 'CONFLICT'];

export type ActionRequestType = {
  REQUEST: string;
  SUCCESS: string;
  FAILURE: string;
  CONFLICT: string;
};

/* eslint-disable no-param-reassign */
export const defineTypes = (prefix: string): ActionRequestType => {
  prefix = prefix ? `${prefix}/` : '';
  return ACTION_STATES.reduce(
    (acc, type) => ({ ...acc, [type]: `${prefix}${type}`.toUpperCase() }),
    {}
  ) as ActionRequestType;
};
