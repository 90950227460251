import { FC, ReactNode } from 'react';
import { Grid } from 'react-styled-flexboxgrid';
import { Normalize } from 'styled-normalize';

import withDSTokens from '../../hoc/withDSTokens';
import TooltipStyles from '../../hoc/withTooltip/style';
import GlobalStyle from '../GlobalStyle';

const AppContainer: FC<{children: ReactNode}>  = ({ children, ...props }) => (
  <Grid fluid {...props} className="app-wrapper js-app-wrapper">
    <Normalize />
    <GlobalStyle />
    <TooltipStyles />
    {children}
  </Grid>
);

export default withDSTokens(AppContainer);
