import { createHashHistory } from 'history';

export function navigateTo(newLocation) {
  if (!newLocation) {
    throw new Error('navigateTo was called without a newLocation');
  }
  window.location = `#${newLocation}`;
}
export function navigateToURL(newLocation) {
  if (!newLocation) {
    throw new Error('navigateToURL was called without a newLocation');
  }
  window.location = newLocation;
}

export function openInNewTab(url) {
  window.open(url, '_blank').focus();
}

export const history = createHashHistory();

export const reactRouterNavigateTo = history.push;
export const reactRouterBack = history.goBack;
export const reactRouterReplace = history.replace;
