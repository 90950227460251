import { FC, ReactElement } from 'react';
import styled from 'styled-components';
import theme from '../../old/config/theme/klara';
import Icon from '../../old/components/Icons/Icon';
import Block from '../../old/components/Blocks/index';
import LabelText2 from '../../old/components/typography/LabelText2';
import iconsExport from '../../old/tokens/exports/icons/index.json';

type IconName = keyof typeof iconsExport;

const AlertContainer = styled(Block)<{ iconName: string; action: ReactElement }>`
  border-radius: 6px;
  background-color: ${theme.colors.yellowLight};
  display: grid;
  column-gap: 20px;
  justify-items: center;
  align-items: center;
  text-align: left;
  width: 100%;
  ${(props) => {
    if (props.iconName && props.action) {
      return `
        grid-template-columns: 30px auto auto;`;
    }
    if (props.iconName) {
      return `
        grid-template-columns: 30px auto;`;
    }
    if (props.action) {
      return `
        grid-template-columns: auto auto;`;
    }
    return `
     grid-template-columns: auto;`;
  }};

  & div {
    justify-self: start;
  }
`;

interface Props {
  /** Name of the icon located on the left. */
  iconName?: IconName;

  /** Text of the Alert */
  text?: string | ReactElement;

  /** Action component located on the right. Usually a CTA button. */
  action?: ReactElement;

  dataTestId?: string;
}

const Notice: FC<Props> = ({ iconName, text, action, dataTestId = '' }) => (
  <Block pb={5} data-test-id={dataTestId}>
    <AlertContainer iconName={iconName} action={action} p={4}>
      {iconName && <Icon name={iconName} />}
      <LabelText2>{text}</LabelText2>
      {action}
    </AlertContainer>
  </Block>
);

export default Notice;
