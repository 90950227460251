import { AppClient } from 'models/common.types';

let applicationType: AppClient | undefined;

export function getAppClient(): AppClient | undefined {
  return applicationType;
}

export function setAppClient(appClient: AppClient): void {
  applicationType = appClient;
}
