import TextareaAutosize from 'react-textarea-autosize';
import styled from 'styled-components';

import colorExport from '../../tokens/exports/color/index.json';
import utilsExport from '../../tokens/exports/utils/index.json';

export default styled(TextareaAutosize)<{ showError?: boolean }>`
  border: 1px solid;
  box-sizing: border-box;
  line-height: 22px;
  outline: none;
  padding: 8px 16px 10px;
  font-size: 16px;
  border-radius: ${utilsExport.utils['radius-8'].radius.join('px ')}px;
  border-color: ${(props) =>
    props.showError ? colorExport.color['red-600'].hex : colorExport.color['grey-300'].hex};
  box-shadow: ${(props) =>
    props.showError ? `0 0 0 1px ${colorExport.color['red-600'].rgba}` : 'initial'};
  width: 100%;
  &:active {
    border-color: ${colorExport.color['blue-700'].hex};
  }
  &:focus {
    box-shadow: 0 0 0 4px ${colorExport.color['grey-400'].rgba.replace('100%', '50%')};
  }
  resize: none;
`;
