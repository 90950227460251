const SigninEndpoint = {
  create(credentials: any) {
    return {
      url: '/patient_signin',
      method: 'POST',
      body: JSON.stringify(credentials),
    };
  },
  sendEmailVerificationCode(email: string, temporary_token: string) {
    return {
      url: '/email_verification_codes',
      method: 'POST',
      body: JSON.stringify({ email, temporary_token }),
    };
  },
  validateEmail(email: string, verification_code: string, temporary_token: string) {
    return {
      url: '/email_verification_codes/validate',
      method: 'POST',
      body: JSON.stringify({ email, verification_code, temporary_token }),
    };
  },
};

export default SigninEndpoint;
