import { initializeApp } from 'firebase/app';
import { fetchAndActivate, getRemoteConfig, getNumber, getBoolean } from 'firebase/remote-config';
import config from 'config';
import { browserUtil } from 'klara-ui/old';
import { klaraLocalStorage } from 'services/KlaraStorage';

export const initFirebaseRemoteConfig = () => {
  if (
    config.NODE_ENV === 'production' &&
    config.APP_ENV !== 'functional' &&
    config.APP_ENV !== 'local' &&
    !browserUtil.isFlutterApp()
  ) {
    const firebaseConfig = {
      apiKey: 'AIzaSyAkEPexp1GafNr14oF-0I3dFl8X1lhBD_c',
      authDomain: 'klara-app.firebaseapp.com',
      databaseURL: 'https://klara-app.firebaseio.com',
      projectId: 'klara-app',
      storageBucket: 'klara-app.appspot.com',
      messagingSenderId: '172801437448',
      appId: '1:172801437448:web:44ff15a0381132c3f006bd',
    };
    const app = initializeApp(firebaseConfig);
    const remoteConfig = getRemoteConfig(app);
    remoteConfig.settings = {
      minimumFetchIntervalMillis: 60 * 60 * 1000, // 1 hour
      fetchTimeoutMillis: 60000,
    };
    fetchAndActivate(remoteConfig).then(() => {
      klaraLocalStorage.setItem(
        'use_paginated_dropdowns',
        getBoolean(remoteConfig, 'use_paginated_dropdowns')
      );
    });
  }
};
