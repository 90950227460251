import { Fragment } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Button } from '../Button';
import Modal from './Modal';

const StyledButton = styled(Button)`
  margin-right: 10px;
`;

const ConfirmationModal = ({
  handleModalClose,
  children,
  confirmationButtonText = 'Confirm',
  cancelButtonText = 'Cancel',
  handleConfirmation,
  dataTestIdConfirm = 'confirm-action',
  dataTestIdCancel = 'cancel-action',
  designType = 'red',
}) => (
  <Modal center fullscreen>
    <Fragment>
      {children}
      <Fragment>
        <StyledButton
          dataTestId={dataTestIdConfirm}
          size="medium"
          designType={designType}
          onClick={handleConfirmation}
        >
          {confirmationButtonText}
        </StyledButton>
        <Button dataTestId={dataTestIdCancel} size="medium" onClick={handleModalClose}>
          {cancelButtonText}
        </Button>
      </Fragment>
    </Fragment>
  </Modal>
);

ConfirmationModal.propTypes = {
  handleModalClose: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  confirmationButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  handleConfirmation: PropTypes.func.isRequired,
  dataTestIdConfirm: PropTypes.string,
  dataTestIdCancel: PropTypes.string,
};

export default ConfirmationModal;
