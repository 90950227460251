import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const LinkWrapper = (props) => {
  const Component = props.to ? Link : 'a';
  return <Component {...props}>{props.children}</Component>;
};

LinkWrapper.propTypes = {
  to: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.element,
    PropTypes.array,
    PropTypes.string,
  ]),
  onClick: PropTypes.func,
  className: PropTypes.string,
  replace: PropTypes.bool,
  style: PropTypes.shape({}),
  name: PropTypes.string,
  target: PropTypes.string,
};

LinkWrapper.displayName = 'Link';

export default LinkWrapper;
