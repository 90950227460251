import * as React from 'react';
import styled from 'styled-components';
import { IconButton } from '../../';

const FileLink = styled.a`
  text-decoration: none;
`;

type DownloadLinkType = {
  contentUrl: string;
  fileName: string;
  dataTestId: string;
  onClick: (e: React.MouseEvent) => void;
};

export const DownloadLink = React.forwardRef<HTMLAnchorElement, DownloadLinkType>(
  ({ contentUrl, fileName, dataTestId, onClick }, ref) => (
    <FileLink
      ref={ref}
      href={contentUrl}
      target="_blank"
      download={fileName}
      data-test-id={dataTestId}
      onClick={onClick}
    >
      <IconButton iconName="arrow-download" designType="ghost" />
    </FileLink>
  )
);

DownloadLink.displayName = 'DownloadLink';

export default DownloadLink;
