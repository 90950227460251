/* eslint-disable max-len */
import { history } from 'actions/LocationActions';
import LoginRequired from 'concerns/patient/auth/LoginRequired';
import { LoadingSpinner } from 'klara-ui/old';
import { lazy, Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router';
import { Router } from 'react-router-dom';

const SchedulingScreen = lazy(() => import('screens/SchedulingScreen'));
const SchedulingUnavailableScreen = lazy(() =>
  import('screens/SchedulingUnavailableScreen')
);
const SchedulingRequestSubmittedScreen = lazy(() =>
  import('screens/SchedulingRequestSubmittedScreen')
);
const SchedulingThankyouScreen = lazy(() =>
  import('screens/SchedulingThankyouScreen')
);
const WidgetScreen = lazy(() => import('screens/WidgetScreen'));
const WidgetConfirmAndRedirectScreen = lazy(() =>
  import('screens/WidgetConfirmAndRedirectScreen')
);
const PublicSchedulingKeycloakRedirect = lazy(() =>
  import('screens/publicScheduling/scheduling/PublicSchedulingKeycloakRedirect')
);
const PublicSchedulingThankYouScreen = lazy(() =>
  import('screens/publicScheduling/PublicSchedulingThankYouScreen')
);
const PublicSchedulingRequestSentScreen = lazy(() =>
  import('screens/publicScheduling/PublicSchedulingRequestSentScreen')
);
const PublicSchedulingUnavailable = lazy(() =>
  import('screens/publicScheduling/PublicSchedulingUnavailableScreen')
);

const WidgetCallbackKeycloak = lazy(() =>
  import('screens/WidgetCallbackKeycloak')
);

const PatientRouter = () => (
  <Router history={history}>
    <Suspense fallback={<LoadingSpinner />}>
      <Switch>


        <Route
          exact
          path="/scheduling/unavailable/:conversationId?"
          component={SchedulingUnavailableScreen}
        />
        <Route
          exact
          path="/scheduling/submitted/:conversationId"
          component={SchedulingRequestSubmittedScreen}
        />
        <Route
          exact
          path="/scheduling/thankyou/:conversationId"
          component={SchedulingThankyouScreen}
        />
        <Route exact path="/scheduling" component={SchedulingScreen} />

        <Route exact path="/widget/confirmRedirect" component={WidgetConfirmAndRedirectScreen} />
        <Route exact path="/widget/:widgetId" component={WidgetScreen} />
        <Route
          exact
          path="/widget/publicScheduling/scheduling/:teamId"
          component={SchedulingScreen}
        />
        <Route
          exact
          path="/widget/publicScheduling/thankyou/"
          component={PublicSchedulingThankYouScreen}
        />
        <Route
          exact
          path="/widget/publicScheduling/requestsent/"
          component={PublicSchedulingRequestSentScreen}
        />
        <Route
          exact
          path="/widget/publicScheduling/unavailable"
          component={PublicSchedulingUnavailable}
        />

        <LoginRequired>
          <Switch>
            <Route exact path="/widget" component={WidgetCallbackKeycloak} />

            <Route exact path="/keycloak/ps/:teamId" component={PublicSchedulingKeycloakRedirect} />

          </Switch>
        </LoginRequired>
      </Switch>
    </Suspense>
  </Router>
);

export default PatientRouter;
