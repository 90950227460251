import { FC, ReactNode } from 'react';
import styled from 'styled-components';

export enum ListItemVariant {
  flat = 'flat',
  box = 'box',
}

interface Props {
  /** Determines if the list item is more cramped together - less space. */
  isCompact?: boolean;

  /** If disabled, the click event handler not gonna be called and it will get a distinctive disabled color. */
  isDisabled?: boolean;

  /** Defines if the item should have a higlight color to pop out of the list. */
  isHiglighted?: boolean;

  /** Defines how the list item should look like. */
  variant?: 'flat' | 'box';

  /** Array of ListItem to display in the list. */
  children: ReactNode;

  /** On click even handler is called when the element is clicked. */
  onClick?: () => void;
}

const ListItemContainer = styled.div<Props>`
  display: block;
  width: 100%;

  padding: ${({
    isCompact,
    theme: {
      list: {
        listItem: { padding },
      },
    },
  }) => (isCompact ? padding.default : padding.big)};
  border-bottom: ${({ theme }) => theme.list.listItem.border};

  color: ${({
    isDisabled,
    theme: {
      list: {
        listItem: { textColor },
      },
    },
  }) => (isDisabled ? textColor.disabled : textColor.default)};

  background-color: ${({
    isHiglighted,
    isDisabled,
    theme: {
      list: {
        listItem: { backgroundColor },
      },
    },
  }) => {
    if (isDisabled) return backgroundColor.disabled;
    if (isHiglighted) return backgroundColor.higlighted;
    return backgroundColor.default;
  }};

  cursor: ${({ onClick, isDisabled }) => (onClick && !isDisabled ? 'pointer' : 'default')};

  ${({ isDisabled, isHiglighted, theme }) =>
    !isDisabled &&
    !isHiglighted &&
    `
    :hover {
      background-color: ${theme.list.listItem.backgroundColor.hover};
    }
  `}

  ${({ variant = ListItemVariant.flat, theme }) =>
    variant === ListItemVariant.box &&
    `
    border-radius: 12px;
    border: ${theme.list.listItem.border};
    margin: 12px 0;
    padding: ${theme.list.listItem.padding.box};
  `}
`;

const ListItem: FC<Props> = ({ children, ...props }) => (
  <ListItemContainer data-component-name="list-item" {...props}>
    {children}
  </ListItemContainer>
);

export default ListItem;
