import { reactRouterNavigateTo, reactRouterReplace } from 'actions/LocationActions';
import { routeBuilders } from 'boot/patient/routes';
import AccountEndpoint from 'persistence/patient/AccountEndpoint';
import ExchangeTokenEndpoint from 'persistence/patient/ExchangeTokenEndpoint';
import SignupEndpoint from 'persistence/patient/SignUpEndpoint';
import { fetch } from 'persistence/reduxFetch';
import { defineTypes } from 'redux/helpers';
import { actions as AlertActions } from 'redux/modules/common/alerts';
import {
  actions as locationActions,
  selectors as locationSelectors,
} from 'redux/modules/common/location';
import { afterLoginSaga } from 'redux/modules/patient/login';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { iFrameWithoutCookieStorage } from 'services/IFrameWithoutCookieStorage';
import { klaraLocalStorage } from 'services/KlaraStorage';
import SessionService from 'services/SessionService';

import { tokenTypes } from '../common/session';

export const exchangeTokenTypes = {
  ...defineTypes('PATIENT/EXCHANGE_TOKEN'),
};
export const SET_TEMP_TOKEN = 'PATIENT/SET_TEMP_TOKEN';
export const SET_TEAM_ID = 'PATIENT/SET_TEAM_ID';

export const initialState = {
  isLoading: false,
  error: null,
  temporaryToken: null,
  teamId: null,
};

export const selectors = {
  isLoading: (state) => state.exchangeToken.isLoading,
  error: (state) => state.exchangeToken.error,
  temporaryToken: (state) => state.exchangeToken.temporaryToken,
  teamId: (state) => state.exchangeToken.teamId,
};

export const actions = {
  exchangeToken(temporaryToken) {
    return { type: exchangeTokenTypes.REQUEST, temporaryToken };
  },
};

export const reducer = (state = initialState, { type, error, temporaryToken, teamId }) => {
  switch (type) {
    case exchangeTokenTypes.REQUEST:
      return { ...state, isLoading: true, error: null, temporaryToken };
    case exchangeTokenTypes.SUCCESS:
      return { ...state, isLoading: false, error: null };
    case exchangeTokenTypes.FAILURE:
      return { ...state, isLoading: false, error };
    case SET_TEMP_TOKEN:
      return { ...state, temporaryToken };
    case SET_TEAM_ID:
      return { ...state, teamId };
    default:
      return state;
  }
};

export function* exchangeTokenSaga({ temporaryToken }) {
  try {
    const storedToken = yield select(selectors.temporaryToken);
    const tempToken = temporaryToken || storedToken;
    const {
      key: token,
      two_factor_auth_enabled,
      error,
    } = yield call(fetch, ExchangeTokenEndpoint.create(tempToken));
    if (
      !token &&
      (two_factor_auth_enabled || error.includes('PatientEmailVerificationCodeExistsError'))
    ) {
      yield put({ type: SET_TEMP_TOKEN, temporaryToken: tempToken });
      yield call(reactRouterReplace, routeBuilders.enterEmailCodeRoute('desktop'));
      return;
    }
    yield call(SessionService.setToken, token);
    const account = yield call(fetch, AccountEndpoint.show());
    const urlAfterLogin = yield select(locationSelectors.urlAfterLogin);

    const isNotBeingRedirected = ['/', '/inbox'].includes(urlAfterLogin);
    const hasNeverSetup2FA = !account.email;
    const patientDecidedToSet2faLater =
      klaraLocalStorage.getItem('patient_decided_to_set_2fa_later') === 'true';

    if (isNotBeingRedirected && hasNeverSetup2FA && !patientDecidedToSet2faLater) {
      yield put(locationActions.setUrlAfterLogin(routeBuilders.askFor2FARoute()));
    }
    yield put({ type: SET_TEMP_TOKEN, temporaryToken });
    yield put({ type: tokenTypes.SUCCESS, token });
    yield put({ type: exchangeTokenTypes.SUCCESS });
    yield call(afterLoginSaga);
  } catch (error) {
    const errorMessage = error?.response?.error;
    if (error.isUnauthorized && error.isUnauthorized()) {
      yield put({ type: exchangeTokenTypes.FAILURE, error });
      yield put(
        AlertActions.pushError({
          content: errorMessage || 'No account with the provided credentials was found',
        })
      );
      yield call(reactRouterNavigateTo, routeBuilders.loginRoute());
      return;
    }
    throw error;
  }
}

export function* widgetAfterLoginSaga({ token }) {
  try {
    const teamId = yield select(selectors.teamId);

    yield call(SessionService.setToken, token);
    yield put({ type: tokenTypes.SUCCESS, token });
    yield put({ type: exchangeTokenTypes.SUCCESS });
    iFrameWithoutCookieStorage.token = token;

        // TODO: Add logic to grab convo

    // yield call(getConversationsSaga);

    const conversations = null // yield select(conversationsSelectors.conversations);
    const hasConversationForTeam = conversations.some((conversation) =>
      conversation.teams.some((team) => team.id === teamId)
    );

    if (hasConversationForTeam) {
      yield call(reactRouterReplace, routeBuilders.teamConversationsRoute(teamId));
    } else {
      yield call(reactRouterReplace, routeBuilders.teamSignupRoute(teamId));
    }
  } catch (error) {
    if (error.isUnauthorized && error.isUnauthorized()) {
      yield put({ type: exchangeTokenTypes.FAILURE, error });
      yield put(
        AlertActions.pushError({
          content: 'No account with the provided credentials was found',
        })
      );
      yield call(reactRouterNavigateTo, routeBuilders.loginRoute());
    } else {
      throw error;
    }
  }
}

export function* exchangeTokenSignupSaga({ temporaryToken, teamId }) {
  try {
    const {
      api_token: token,
      two_factor_auth_enabled,
      error,
    } = yield call(fetch, SignupEndpoint.create(temporaryToken, teamId));

    if (
      !token &&
      (two_factor_auth_enabled || error.includes('PatientEmailVerificationCodeExistsError'))
    ) {
      yield put({ type: SET_TEMP_TOKEN, temporaryToken });
      yield put({ type: SET_TEAM_ID, teamId });
      yield call(reactRouterReplace, routeBuilders.enterEmailCodeRoute('widget'));
      return;
    }

    yield call(SessionService.setToken, token);
    yield put({ type: tokenTypes.SUCCESS, token });
    yield put({ type: exchangeTokenTypes.SUCCESS });
    iFrameWithoutCookieStorage.token = token;

    // TODO: Add logic to grab convo
    // yield call(getConversationsSaga);

    const conversations = null // yield select(conversationsSelectors.conversations);
    const hasConversationForTeam = conversations.some((conversation) =>
      conversation.teams.some((team) => team.id === teamId)
    );

    if (hasConversationForTeam) {
      yield call(reactRouterReplace, routeBuilders.teamConversationsRoute(teamId));
    } else {
      yield call(reactRouterReplace, routeBuilders.teamSignupRoute(teamId));
    }
  } catch (error) {
    if (error.isUnauthorized && error.isUnauthorized()) {
      const errorMessage = error?.response?.error;
      yield put({ type: exchangeTokenTypes.FAILURE, error });
      yield put(
        AlertActions.pushError({
          content: errorMessage || 'No account with the provided credentials was found',
        })
      );
      yield call(reactRouterNavigateTo, routeBuilders.loginRoute());
    } else {
      throw error;
    }
  }
}

export function* rootSaga() {
  yield takeLatest(exchangeTokenTypes.REQUEST, exchangeTokenSaga);
}
