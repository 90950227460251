import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from '../../config/theme/klara';

const ForwardingPillContainer = styled.div`
  display: inline-table;
  background-color: ${theme.colors.transparentGrey};
  border-radius: 8px;
  color: ${theme.colors.grey60};
  padding: 2px 8px;
  font-size: 13px;
  line-height: 16px;
  font-weight: ${theme.fonts.weights.regular};
`;

const ForwardingPill = ({ children, dataTestId = 'forwarding-pill', ...props }) => (
  <ForwardingPillContainer data-test-id={dataTestId} {...props}>
    {children}
  </ForwardingPillContainer>
);

ForwardingPill.defaultProps = {
  dataTestId: 'forwarding-pill',
};

ForwardingPill.propTypes = {
  dataTestId: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.element, PropTypes.array]),
};

ForwardingPill.displayName = 'ForwardingPill';

export default ForwardingPill;
