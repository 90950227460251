import { ReactElement } from 'react';
import styled from 'styled-components';
import theme from '../../config/theme/klara';

interface Props {
  size?: number;
  color?: string;
}

const StyledDot = styled.div<Props>`
  background-color: ${(props) => props.color};
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  border-radius: 50%;
  display: inline-block;
`;

const Dot = ({ size = 8, color = theme.colors.primaryBrand }: Props): ReactElement => (
  <StyledDot size={size} color={color} />
);

export default Dot;
