export type RequestState = 'IDLE' | 'LOADING' | 'SUCCESS' | 'FAILED';

type RequestStatesObject = {
  IDLE: 'IDLE';
  LOADING: 'LOADING';
  SUCCESS: 'SUCCESS';
  FAILED: 'FAILED';
  isFinished(state: RequestState): boolean;
};

export const requestStates: RequestStatesObject = {
  IDLE: 'IDLE',
  LOADING: 'LOADING',
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED',
  isFinished: (state: RequestState) =>
    state === requestStates.SUCCESS || state === requestStates.FAILED,
};
