import { FC, ReactNode } from 'react';
import Avatar from 'react-avatar';

import { Color } from '../../hoc/withDSTokens';
import browserUtil from '../../util/browser';
import Block from '../Blocks';
import { Col } from '../Grid';
import Icon, { IconName } from '../Icons/Icon';
import { FlexRow } from '../layout';

export enum PillType {
  default,
  success,
  error,
  active,
}

export enum PillSize {
  small = 'small',
  large = 'large',
}

export enum AvatarSize {
  small = '24px',
  large = '36px',
}

export enum IconSize {
  small = 16,
  large = 24,
}

interface Props {
  icon?: IconName;
  avatar?: string;
  type?: PillType;
  dark?: boolean;
  size?: PillSize;
  dataTestId?: string;
  onClose?: () => void;
  onClick?: (e: any) => void;
  children: ReactNode;
}

const backgroundColors: Color[] = ['grey-100', 'green-50', 'red-100', 'blue-50'];

const darkBackgroundColors: Color[] = ['grey-700', 'green-700', 'red-600', 'blue-600'];

const fontColors: Color[] = ['grey-700', 'green-700', 'red-700', 'blue-700'];

const Pill: FC<Props> = ({
  dataTestId = 'pill',
  icon,
  avatar,
  children,
  dark,
  onClose,
  type = PillType.default,
  size = PillSize.small,
  ...props
}) => {
  const style = {
    fontSize: '13px',
    width: 'max-content',
    paddingLeft: 0,
  };
  const overflowStyle = {
    'max-width': '200px',
    'text-overflow': 'ellipsis',
    overflow: 'hidden',
    'white-space': 'nowrap',
  };
  const ph = size === PillSize.small ? 2 : 3;
  const fontColor = dark ? 'grey-100' : fontColors[type];
  const backgroundColor = dark ? darkBackgroundColors[type] : backgroundColors[type];
  const extraProps = { type: PillType[type.valueOf()] };
  return (
    <Block
      c={fontColor}
      roundedBorder
      data-test-id={dataTestId}
      bgc={backgroundColor}
      style={style}
      p={2}
      {...props}
      {...extraProps}
    >
      <FlexRow>
        {icon && (
          <Col ph={ph}>
            <Icon hc={fontColor} ic={fontColor} size={IconSize[size]} name={icon} />
          </Col>
        )}
        {avatar && (
          <Col pr={ph} style={{ paddingLeft: 0 }}>
            <Avatar
              fgColor={dark ? '#A3A3A3' : '#BDBDBD'}
              color={dark ? '#333333' : backgroundColor}
              style={{
                boxSizing: 'content-box',
                border: 'solid 1px #BDBDBD',
              }}
              round
              size={AvatarSize[size]}
              name={avatar}
            />
          </Col>
        )}
        <Col ph={ph} pb={1}>
          <div style={browserUtil.isMobile() ? overflowStyle : null}>{children}</div>
        </Col>
        {onClose && (
          <Col ph={ph} onClick={onClose}>
            <Icon hc={fontColor} ic={fontColor} size={IconSize[size]} name="close-circle" />
          </Col>
        )}
      </FlexRow>
    </Block>
  );
};

export default Pill;
