import { inIframe } from 'util/iFrame';

class IFrameWithoutCookieStorage {
  private authToken: string = undefined;

  get token(): string | undefined {
    if (this.shouldBeUsed) {
      return this.authToken;
    }

    return;
  }

  set token(token: string) {
    if (this.shouldBeUsed) {
      this.authToken = token;
    }
  }

  private get shouldBeUsed() {
    return inIframe() && !navigator.cookieEnabled;
  }
}

export const iFrameWithoutCookieStorage = new IFrameWithoutCookieStorage();
