import isEqual from 'lodash/isEqual';
import SessionService from 'services/SessionService';

export default function handleUserNotLoggedIn(response) {
  if (response.status === 401) {
    return response
      .clone()
      .json()
      .then((json) => {
        if (isEqual(json, { credentials: ['invalid'] })) {
          SessionService.resetToken();
        }
        return response;
      });
  }
  return Promise.resolve(response);
}
