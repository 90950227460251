import PropTypes from 'prop-types';
import { Fragment } from 'react';
import getDisplayName from '../../tools/getDisplayName';
import browserUtil from '../../util/browser';

export const WithTooltip = (props) => {
  const { InnerComponent, tooltipContent, tooltipPosition, tooltipLength, ...rest } = props;
  return (
    <Fragment>
      {browserUtil.isMobile() ? (
        <InnerComponent {...rest} />
      ) : (
        <InnerComponent
          {...rest}
          data-balloon={tooltipContent}
          data-balloon-pos={tooltipPosition}
          data-balloon-length={tooltipLength}
        />
      )}
    </Fragment>
  );
};

WithTooltip.propTypes = {
  InnerComponent: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.element]),
  tooltipContent: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  tooltipPosition: PropTypes.string,
  tooltipLength: PropTypes.string,
};

WithTooltip.defaultProps = {
  tooltipPosition: 'down',
};

export default (InnerComponent) => (props) => {
  WithTooltip.displayName = `WithTooltip(${getDisplayName(InnerComponent)})`;

  return <WithTooltip {...props} InnerComponent={InnerComponent} />;
};
