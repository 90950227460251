import logger from '@klara/logger';
import { reactRouterNavigateTo } from 'actions/LocationActions';
import { routeBuilders as patientRoutes } from 'boot/patient/routes';
import PatientLogoutEndpoint from 'persistence/patient/LogoutEndpoint';
import PatientSigninEndpoint from 'persistence/patient/SigninEndpoint';
import { fetch } from 'persistence/reduxFetch';
import RefreshTokenEndpoint from 'persistence/RefreshTokenEndpoint';
import { defineTypes, requestStates } from 'redux/helpers';
import { actions as alertActions } from 'redux/modules/common/alerts';
import {
  actions as locationActions,
} from 'redux/modules/common/location';
import { put, putResolve, call, all, takeLatest, select, cancel } from 'redux-saga/effects';
import SessionService from 'services/SessionService';

import { inIframe } from 'util/iFrame';

export const tokenTypes = {
  ...defineTypes('COMMON/API_TOKEN/CREATE'),
  BIOMETRICS: 'COMMON/API_TOKEN/CREATE/BIOMETRICS',
  LOGOUT_EXISTING: 'COMMON/API_TOKEN/CREATE/LOGOUT_EXISTING',
  LOGOUT_WITH_TOKEN: 'COMMON/API_TOKEN/CREATE/LOGOUT_WITH_TOKEN',
};

export const refreshTokenTypes = defineTypes('COMMON/API_TOKEN/REFRESH');
export const destroySessionTypes = defineTypes('COMMON/API_TOKEN/DESTROY');

export const SHOULD_DESTROY = 'COMMON/API_TOKEN/SESSION/SHOULD_DESTROY';
export const DESTROY_SESSION_IF_IDLE = 'COMMON/API_TOKEN/SESSION/DESTROY_SESSION_IF_IDLE';

export const initialState = {
  token: null,
  isLoading: false,
  shouldDestroy: false,
  refreshRequestState: requestStates.IDLE,
};

const persistenceEndpoints = {
  patient: PatientSigninEndpoint,
};

const logoutEndpoints = {
  patient: PatientLogoutEndpoint,
};

export const actions = {
  createSession(client, params) {
    return {
      type: tokenTypes.REQUEST,
      client,
      ...params,
    };
  },

  destroySession(client, urlAfterLogin, linkExpired, actionAfterLogout) {
    return {
      type: destroySessionTypes.REQUEST,
      client,
      urlAfterLogin,
      linkExpired,
      actionAfterLogout,
    };
  },

  logoutIfSessionExists(client, clearSession = true) {
    return {
      type: tokenTypes.LOGOUT_EXISTING,
      client,
      clearSession,
    };
  },

  logoutSessionWithToken(client, token) {
    return {
      type: tokenTypes.LOGOUT_WITH_TOKEN,
      client,
      token,
    };
  },

  shouldDestroySession() {
    return { type: SHOULD_DESTROY };
  },

  refreshToken(urlAfterLogin) {
    return {
      type: refreshTokenTypes.REQUEST,
      urlAfterLogin,
    };
  },

  setToken(token) {
    return {
      type: tokenTypes.SUCCESS,
      token,
    };
  },
  triggerLogoutIfIdle() {
    return { type: DESTROY_SESSION_IF_IDLE };
  },
};

export const selectors = {
  token: (state) => state.session.token,
  isPatient: (state) => !!state.currentPatient,
  isLoading: (state) => state.session.isLoading,
  shouldDestroy: (state) => state.session.shouldDestroy,
  refreshRequestState: (state) => state.session.refreshRequestState,
  tokenRefreshCompleted: (state) => requestStates.isFinished(state.session.refreshRequestState),
};

export const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case tokenTypes.REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case tokenTypes.SUCCESS:
      return {
        ...state,
        token: action.token,
        isLoading: false,
      };
    case destroySessionTypes.REQUEST:
      return {
        ...state,
        shouldDestroy: false,
      };
    case destroySessionTypes.SUCCESS:
      return {
        ...state,
        token: null,
        shouldDestroy: false,
      };
    case tokenTypes.LOGOUT_EXISTING:
      return {
        ...state,
        token: null,
        shouldDestroy: false,
      };
    case tokenTypes.LOGOUT_SESSION:
      return {
        ...state,
        token: null,
        shouldDestroy: false,
      };
    case tokenTypes.FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case refreshTokenTypes.REQUEST:
      return {
        ...state,
        refreshRequestState: requestStates.LOADING,
        token: null,
      };
    case refreshTokenTypes.SUCCESS:
      return {
        ...state,
        refreshRequestState: requestStates.SUCCESS,
        token: action.token,
      };
    case refreshTokenTypes.FAILURE:
      return {
        ...state,
        refreshRequestState: requestStates.FAILED,
        token: null,
      };
    case SHOULD_DESTROY:
      return {
        ...state,
        shouldDestroy: true,
      };
    default:
      return state;
  }
};

export function* createSessionSaga({ client, skipTwoFactor, ...credentials }) {
  try {
    const twoFactorToken = yield call(SessionService.get2FAToken, credentials.login);
    if (twoFactorToken) {
      // eslint-disable-next-line no-param-reassign
      credentials.sms_token = twoFactorToken;
    }

    const sigininEndpoint = persistenceEndpoints[client];

    const callFunction = call(fetch, sigininEndpoint.create(credentials), false);

    const { key: token, second_factor_token } = yield callFunction;
    if (skipTwoFactor && second_factor_token) {
      yield call(SessionService.set2FAToken, credentials.login, second_factor_token);
    }

    yield call(SessionService.setToken, token);
    yield putResolve({
      type: tokenTypes.SUCCESS,
      token,
    });
  } catch (error) {
    yield put({
      type: tokenTypes.FAILURE,
      error,
    });
    throw error;
  }
}


export function* logoutBackend({ client, token }) {
  try {
    const logoutEndpoint = logoutEndpoints[client];

    const callFunction = call(fetch, logoutEndpoint.logout(token));

    yield callFunction;

  } catch (error) {
    if (error.isUnprocessableEntity && error.isUnprocessableEntity()) {
      yield put(alertActions.pushError({ content: error.response.errors?.[0]?.message }));
    }
  }
}

export function* redirectAfterLogout(client, linkExpired, actionAfterLogout) {
  switch (client) {
    case 'patient': {
      const widgetId = SessionService.getWidgetId();
      const shouldRedirectToWidgetScreen = !!widgetId && inIframe();

      if (shouldRedirectToWidgetScreen) {
        yield call(reactRouterNavigateTo, patientRoutes.widgetRoute(widgetId));
      } else if (linkExpired) {
        yield call(reactRouterNavigateTo, patientRoutes.linkExpiredRoute());
      } else {
        yield call(reactRouterNavigateTo, patientRoutes.loginRoute());
      }

      SessionService.clearWidgetId();
      SessionService.clearTeamId();
      if (actionAfterLogout) {
        yield put(actionAfterLogout);
      }

      break;
    }

    default:
      break;
  }
}

export function* destroySessionSaga({
  client,
  urlAfterLogin,
  linkExpired = false,
  actionAfterLogout,
}) {
  try {
    const token = yield select(selectors.token);
    yield call(logoutBackend, {
      client,
      token,
    });

    yield all([
      put({ type: destroySessionTypes.SUCCESS }),
      call(SessionService.destroySession, client),
      call(redirectAfterLogout, client, linkExpired, actionAfterLogout),
      // NOTE add actions here that are safe to persist after clearing the store.
    ]);

    if (urlAfterLogin) {
      yield put(locationActions.setUrlAfterLogin(urlAfterLogin));
    }

    logger.clearUser();

    // We do not have heap on patient side
    if (window?.heap) {
      window.heap.resetIdentity();
      window.heap.clearEventProperties();
    }

    // We have tasks that do not need or should not run at all aftre logout.
    // We fork them in `afterLoginSaga` and here we have to terminate them.
    const forkedSagas = window.forkedLoginTasks ?? [];
    // eslint-disable-next-line no-restricted-syntax
    for (const task of forkedSagas) {
      yield cancel(task);
    }
  } catch (error) {
    logger.error('Destroying session failed.', {}, error);
  }
}

export function* refreshTokenSaga({ urlAfterLogin = '/inbox' }) {
  try {
    const token = yield call(fetch, RefreshTokenEndpoint.refresh());

    yield call(SessionService.setToken, token?.key);
    yield putResolve({
      type: refreshTokenTypes.SUCCESS,
      token: token?.key,
    });
  } catch (error) {
    yield call(destroySessionSaga, {
      client: 'doctor',
      urlAfterLogin,
    });
    yield put({ type: refreshTokenTypes.FAILURE });
  }
}

export function* logoutExistingSessionSaga({ client, clearSession }) {
  const token = yield select(selectors.token);

  if (token) {
    try {
      yield call(logoutBackend, { client });
      if (clearSession) {
        yield call(SessionService.destroySession, client);
      }
    } catch (error) {
      // do nothing
    }
  }
}

export function* rootSaga() {
  yield takeLatest(tokenTypes.REQUEST, createSessionSaga);
  yield takeLatest(destroySessionTypes.REQUEST, destroySessionSaga);
  yield takeLatest(tokenTypes.LOGOUT_EXISTING, logoutExistingSessionSaga);
  yield takeLatest(tokenTypes.LOGOUT_WITH_TOKEN, logoutBackend);
  yield takeLatest(refreshTokenTypes.REQUEST, refreshTokenSaga);
}
