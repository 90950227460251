import { Component, Fragment } from 'react';
import PropTypes, { string } from 'prop-types';
import styled from 'styled-components';
import theme from '../../config/theme/klara';
import AvatarOptionRow from '../Lists/AvatarOptionRow';
import IconOptionRow from '../Lists/IconOptionRow';
import OptionsContainer from './OptionsContainer';
import browserUtil from '../../util/browser';
import LoadMore from '../LoadMore';
import Icon from '../Icons/Icon';
import IconButton from '../Button/IconButton';

const KEYCODE_ESC = 27;
const SEPERATOR_INDEX_OUTSIDE_LIST = -1;

const OutterDesktopContainer = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
`;

const OutterMobileContainer = styled.div`
  display: inline-block;
  width: 100%;
`;

const InnerDesktopContainer = styled.div`
  position: absolute;
  z-index: 9999;
  left: ${(props) => props.left};
  right: ${(props) => props.right};
  top: ${(props) => props.top};
  bottom: ${(props) => props.bottom};
  overflow: hidden;
  width: ${(props) => props.width ?? '100%'};
  background-color: ${theme.colors.grey100};
  opacity: 0.9;
  border-radius: 6px;
`;

const InnerMobileContainer = styled.div`
  position: fixed;
  z-index: 9999;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: ${theme.colors.grey100};
  opacity: 0.9;
  border-radius: 6px;
  overflow-y: scroll;
`;

const TargetContainer = styled.div`
  cursor: pointer;
`;

const StyledInput = styled.input`
  width: 100%;
  height: 40px;
  background-color: ${theme.colors.grey90};
  border-radius: 6px;
  border: none;
  padding-left: 11px;
  color: ${theme.colors.grey50};
  outline: none;
  font-size: ${theme.fonts.sizes.m};
`;

const CloseIconContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  height: 30px;
  padding: 20px 20px 10px;
  color: ${theme.colors.grey10};
  &:hover {
    color: ${theme.colors.grey50};
  }
`;

const InputContainer = styled.div`
  margin: 20px 30px;
`;

const HeadingContainer = styled.div`
  color: ${theme.colors.white};
  margin: 30px 45px 0 45px;
  font-size: ${theme.fonts.sizes.m};
  font-weight: ${theme.fonts.weights.regular};
  text-align: center;
`;

const FooterDesktopContainer = styled.div`
  color: ${theme.colors.white};
  padding: 10px 30px;
  font-size: ${theme.fonts.sizes.m};
  font-weight: ${theme.fonts.weights.regular};
  text-align: center;
`;

const FooterMobileContainer = styled.div`
  padding: 10px 30px;
  color: ${theme.colors.white};
  font-size: ${theme.fonts.sizes.m};
  font-weight: ${theme.fonts.weights.regular};
  text-align: center;
`;

const TitleContainer = styled.div`
  font-size: ${theme.fonts.sizes.xs};
  font-weight: ${theme.fonts.weights.bold};
  color: ${theme.colors.grey60};
  text-transform: uppercase;
  margin-bottom: 5px;
  line-height: 1.15;
`;

const Seperator = styled.div`
  margin: 12px -30px;
  border-bottom: 1px solid ${theme.colors.white};
  opacity: 0.25;
`;

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export default class SearchDropdown extends Component {
  static propTypes = {
    isOpen: PropTypes.bool,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onTargetClick: PropTypes.func,
    onOpen: PropTypes.func,
    onClose: PropTypes.func,
    renderTarget: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    options: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string.isRequired,
          value: PropTypes.string.isRequired,
          avatar: PropTypes.string,
        })
      ),
    ]),
    maxItems: PropTypes.number,
    menuOffset: PropTypes.number,
    xPosition: PropTypes.string,
    yPosition: PropTypes.string,
    disabled: PropTypes.bool,
    filterOptions: PropTypes.func,
    renderOption: PropTypes.func,
    heading: PropTypes.string,
    titleArray: PropTypes.array,
    seperators: PropTypes.array,
    footer: PropTypes.node,
    maxHeight: PropTypes.string,
    showCloseButton: PropTypes.bool,
    dataTestId: PropTypes.string,
    keepOpenedOnSelect: PropTypes.bool,
    width: string,
  };

  static defaultProps = {
    xPosition: 'left',
    yPosition: 'below',
    renderTarget: (selectedItem, isOpen) => {
      let targetString = '';

      if (selectedItem) {
        targetString = selectedItem.label;
      } else {
        targetString = isOpen ? 'Hide Menu' : 'Show Menu';
      }
      return <Fragment>{targetString}</Fragment>;
    },
    menuOffset: 0,
    maxItems: 50,
    maxHeight: '310px',
    options: [],
    heading: '',
    footer: '',
    seperators: [],
    titleArray: [],
    dataTestId: 'search-dropdown',
  };

  static displayName = 'SearchableDropdown';

  state = {
    isHoveringOverList: false,
    isTouchingList: false,
    searchValue: '',
    pageCount: 1,
  };

  open = () => {
    const { onOpen } = this.props;

    this.setState({
      isHoveringOverList: false,
      isTouchingList: false,
    });

    if (browserUtil.isMobile()) {
      document.body.style.overflow = 'hidden';
    }

    if (onOpen) onOpen();
    window.addEventListener('keyup', this.closeIfEscape, false);
    window.addEventListener('mousedown', this.closeOnOutsideClick, false);
  };

  close = () => {
    const { onClose } = this.props;

    this.setState({
      isHoveringOverList: false,
      isTouchingList: false,
      searchValue: '',
    });

    if (browserUtil.isMobile()) {
      document.body.style.overflow = null;
    }
    if (onClose) onClose();
    window.removeEventListener('keyup', this.closeIfEscape, false);
    window.removeEventListener('mousedown', this.closeOnOutsideClick, false);
  };

  onValueSelect = (selectedValue) => () => {
    const { onChange, value: currentValue, keepOpenedOnSelect } = this.props;

    if (selectedValue?.disabled) return null;

    if (onChange) onChange(selectedValue, currentValue);

    if (!keepOpenedOnSelect) {
      this.close();
    }
  };

  renderOption = (data) => {
    const { renderOption } = this.props;
    const key = `${data.id}${data.label}`;
    if (renderOption) {
      return (
        <div key={key} onClick={this.onValueSelect(data)}>
          {renderOption(data)}
        </div>
      );
    }

    if (data.icon) {
      return (
        <IconOptionRow
          icon={data.icon}
          key={key}
          title={data.label}
          count={data.count}
          className="searchable-dropdown-option"
          onClick={this.onValueSelect(data)}
        />
      );
    }

    return (
      <AvatarOptionRow
        key={key}
        url={data.avatar}
        title={data.label}
        count={data.count}
        className="searchable-dropdown-option"
        onClick={this.onValueSelect(data)}
      />
    );
  };

  closeIfEscape = (e) => {
    if (e.keyCode === KEYCODE_ESC) this.close();
  };

  closeOnOutsideClick = (e) => {
    if (this.dropdownRef && !this.dropdownRef.contains(e.target)) this.close();
  };

  setTargetRef = (el) => {
    this.targetRef = el;
  };

  setDropdownRef = (el) => {
    this.dropdownRef = el;
  };

  renderTarget = () => {
    const { isOpen, renderTarget, value, options } = this.props;
    const selectedItem = value
      ? options.find((option) => option.value.toString() === value.toString())
      : null;

    return (
      <TargetContainer
        ref={this.setTargetRef}
        onClick={(e) => {
          e.stopPropagation();
          isOpen ? this.close(e) : this.open(e);
        }}
      >
        {renderTarget(selectedItem, isOpen)}
      </TargetContainer>
    );
  };

  onMouseEnterList = () => {
    this.setState({ isHoveringOverList: true });
  };

  onMouseLeaveList = () => {
    this.setState({ isHoveringOverList: false });
  };

  onTouchStartList = () => {
    this.setState({ isTouchingList: true });
  };

  onInputChange = (e) => {
    this.setState({ searchValue: e.currentTarget.value });
  };

  filteredOptions = () => {
    const { filterOptions, options } = this.props;
    const { searchValue } = this.state;

    if (filterOptions) return filterOptions(options, searchValue);

    return options.filter(
      (option) => option.label?.toLowerCase().indexOf(searchValue?.toLowerCase()) > -1
    );
  };

  loadMore = () => {
    const { pageCount } = this.state;

    if (this.inputRef) this.inputRef.focus();
    this.setState({ pageCount: pageCount + 1 });
  };

  setInputRef = (el) => {
    this.inputRef = el;
  };

  renderTitle = (index) => {
    const { titleArray } = this.props;
    const { searchValue } = this.state;

    if (!searchValue) {
      return (
        <Fragment>
          {titleArray
            .filter((item) => item.position === index)
            .map((item) => (
              <TitleContainer className="dropdown-title" key={item.title}>
                {' '}
                {item.title}{' '}
              </TitleContainer>
            ))}
        </Fragment>
      );
    }
    return '';
  };

  renderSeperator = (index) => {
    const { seperators } = this.props;
    const { searchValue } = this.state;

    if (!searchValue) {
      return (
        seperators.includes(index) && (
          <div className="dropdown-seperator">
            <Seperator />
          </div>
        )
      );
    }
    return '';
  };

  renderSelect = () => {
    const { searchValue, pageCount } = this.state;

    const {
      xPosition,
      yPosition,
      menuOffset,
      placeholder,
      maxItems,
      heading,
      isOpen,
      footer,
      maxHeight,
      showCloseButton,
      dataTestId,
      width,
    } = this.props;

    if (!isOpen) {
      return null;
    }

    const filteredData = this.filteredOptions();
    const displayedItemsCount = pageCount * maxItems;
    const targetHeight =
      this.targetRef && this.targetRef.getBoundingClientRect
        ? this.targetRef.getBoundingClientRect().height
        : 0;

    let left = 'unset';
    let right = 'unset';
    let top = 'unset';
    let bottom = 'unset';

    if (xPosition === 'left') left = 0;
    if (xPosition === 'right') right = 0;
    if (yPosition === 'above') bottom = `${targetHeight + menuOffset}px`;
    if (yPosition === 'below') top = `${targetHeight + menuOffset}px`;
    const InnerContainer = browserUtil.isMobile() ? InnerMobileContainer : InnerDesktopContainer;
    const FooterContainer = browserUtil.isMobile() ? FooterMobileContainer : FooterDesktopContainer;

    const maximumHeight = browserUtil.isMobile() ? '100vh' : maxHeight;

    return (
      isOpen && (
        <InnerContainer
          {...{
            left,
            right,
            top,
            bottom,
          }}
          width={width}
          onMouseEnter={this.onMouseEnterList}
          onMouseLeave={this.onMouseLeaveList}
          onTouchStart={this.onTouchStartList}
          ref={this.setDropdownRef}
          data-test-id={dataTestId}
        >
          <StyledContent>
            {browserUtil.isMobile() && (
              <CloseIconContainer>
                <IconButton
                  iconName="close"
                  onClick={this.close}
                  designType="ghost"
                  size="medium"
                  roundButton
                />
              </CloseIconContainer>
            )}
            {!browserUtil.isMobile() && showCloseButton && (
              <Icon
                pos="absolute"
                ic="grey-100"
                name="close-circle"
                style={{ right: 10, top: 10 }}
                onClick={this.close}
              />
            )}
            {heading && (
              <HeadingContainer className="dropdown-heading"> {heading} </HeadingContainer>
            )}
            {this.renderSeperator(SEPERATOR_INDEX_OUTSIDE_LIST)}

            <InputContainer>
              <StyledInput
                type="text"
                ref={this.setInputRef}
                placeholder={placeholder}
                autoFocus={!browserUtil.isMobile()}
                value={searchValue}
                onChange={this.onInputChange}
              />
            </InputContainer>
            <OptionsContainer backgroundColor="transparent" maxHeight={maximumHeight}>
              {filteredData.slice(0, displayedItemsCount).map((item, index) => (
                <Fragment key={`option-${item.id}-${index}`}>
                  {this.renderTitle(index)}
                  {this.renderOption(item)}
                  {this.renderSeperator(index)}
                </Fragment>
              ))}
              {
                <LoadMore
                  data={filteredData}
                  loadMore={this.loadMore}
                  displayedItemsCount={displayedItemsCount}
                />
              }
            </OptionsContainer>
            {footer && <FooterContainer className="dropdown-footer"> {footer} </FooterContainer>}
          </StyledContent>
        </InnerContainer>
      )
    );
  };

  render() {
    const OutterContainer = browserUtil.isMobile() ? OutterMobileContainer : OutterDesktopContainer;

    return (
      <OutterContainer>
        {this.renderTarget()}
        {this.renderSelect()}
      </OutterContainer>
    );
  }
}
