import classNames from 'classnames';
import { FC, ReactNode } from 'react';
import styled, { css } from 'styled-components';

import theme from '../../config/theme/klara';

const StyledTab = styled.a<{ disabled: boolean; name: string; children: ReactNode }>`
  ${(props) =>
    props.disabled
      ? css`
          color: ${theme.colors.grey50};
          cursor: inherit;
        `
      : css`
          color: ${theme.colors.grey100};
          cursor: pointer;

          &:hover,
          &.active {
            border-bottom: 2px solid ${theme.colors.primaryBrand};
          }
        `}
  text-decoration: none;
  font-size: ${theme.fonts.sizes.m};
  font-weight: ${theme.fonts.weights.regular};
  line-height: 20px;

  & + & {
    margin-left: 30px;
  }
`;

type TabProps = {
  name: string;
  active?: boolean;
  disabled?: boolean;
  href?: string;
  className?: string;
  onClick?: VoidFunction;
  dataTestId?: string;
  children: ReactNode;
};

const Tab: FC<TabProps> = ({
  children,
  name,
  active,
  disabled,
  href,
  className,
  onClick,
  dataTestId = null,
}) => (
  <StyledTab
    name={name}
    className={classNames(className, { active })}
    disabled={disabled}
    href={href}
    onClick={onClick}
    data-test-id={dataTestId}
  >
    {children}
  </StyledTab>
);

Tab.displayName = 'Tab';

export default Tab;
