/* eslint-disable @typescript-eslint/no-floating-promises */
import { useContext, useEffect, useState } from 'react';
import heic2any from 'heic2any';
import { FilePreviewContext } from './FilePreviewProvider';

/**
 * ! Parent component needs to be wrapped in FilePreviewProvider HOC in order to use this hook.
 * This is needed so that state can be shared between multiple components, via Context API.
 *
 * This hook accepts a file in string or File format.
 * Files in string format are used as is, since they are already blob URLs.
 * Files in File format are transformed into a blob string.
 * If file is of heic format it is first converted to jpg.
 * @param file
 * @param fileIdentifier
 * @returns A blob string that can be used for previewing the provided file
 */
const useFilePreview = (file: string | File, fileIdentifier: string) => {
  const filePreviewContext = useContext(FilePreviewContext);
  const { previewURLs, updatePreviewURLs } = filePreviewContext;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      if (!file) return updatePreviewURLs({ [fileIdentifier]: null });

      if (file instanceof File && file?.type === 'image/heic') {
        setLoading(true);
        updatePreviewURLs({
          [fileIdentifier]: URL.createObjectURL(
            (await heic2any({ blob: file, toType: 'image/jpeg' })) as Blob
          ),
        });
        setLoading(false);
        return;
      }

      if (typeof file === 'string') {
        return updatePreviewURLs({ [fileIdentifier]: file });
      }

      updatePreviewURLs({ [fileIdentifier]: URL.createObjectURL(file) });
    })();
  }, [file]);

  return {
    previewURL: previewURLs ? previewURLs[fileIdentifier] : null,
    loading,
  };
};

export default useFilePreview;
