import { omitBy } from 'lodash';

import { klaraLocalStorage } from './KlaraStorage';

const LAST_ACTIVITY = 'LAST_ACTIVITY';
const ROOM_SID = 'VIDOE_VISIT/ROOM_SID';
const PARTICIPANT_SID = 'VIDOE_VISIT/PARTICIPANT_SID';

export function saveLogin(client, login) {
  try {
    return klaraLocalStorage.setItem(`${client}App.login`, login);
  } catch (exception) {
    return null;
  }
}

export function getLogin(client) {
  try {
    return klaraLocalStorage.getItem(`${client}App.login`);
  } catch (exception) {
    return null;
  }
}

export function setLastActivity(ts = +new Date()) {
  try {
    return klaraLocalStorage.setItem(LAST_ACTIVITY, ts);
  } catch (exception) {
    return null;
  }
}
export function getLastActivity() {
  try {
    return +klaraLocalStorage.getItem(LAST_ACTIVITY);
  } catch (exception) {
    return 0;
  }
}

export function setVideoVisitRoomSID(roomSID) {
  try {
    return klaraLocalStorage.setItem(ROOM_SID, roomSID);
  } catch (exception) {
    return null;
  }
}
export function getVideoVisitRoomSID() {
  try {
    return klaraLocalStorage.getItem(ROOM_SID) || null;
  } catch (exception) {
    return 0;
  }
}

export function setVideoVisitParticipantSID(participantSID) {
  try {
    return klaraLocalStorage.setItem(PARTICIPANT_SID, participantSID);
  } catch (exception) {
    return null;
  }
}
export function getVideoVisitParticipantSID() {
  try {
    return klaraLocalStorage.getItem(PARTICIPANT_SID) || null;
  } catch (exception) {
    return 0;
  }
}
export function clearVideoVisitIdentifiers() {
  try {
    klaraLocalStorage.removeItem(PARTICIPANT_SID);
    klaraLocalStorage.removeItem(ROOM_SID);
  } catch (exception) {
    return 0;
  }
}

export function setSsoData(ssoData) {
  try {
    return klaraLocalStorage.setItem('SSO.ClientData', JSON.stringify(ssoData));
  } catch (exception) {
    return null;
  }
}

export function getSsoData() {
  try {
    return klaraLocalStorage.getItem('SSO.ClientData');
  } catch (exception) {
    return null;
  }
}

export function setSsoEmail(email) {
  try {
    return klaraLocalStorage.setItem('SSO.email', email);
  } catch (exception) {
    return null;
  }
}

export function getSsoEmail() {
  try {
    return klaraLocalStorage.getItem('SSO.email');
  } catch (exception) {
    return null;
  }
}

export function getDismissedNotificationItems() {
  try {
    const notificationItems = klaraLocalStorage.getItem('dismissedNotificationItems');

    return notificationItems ? JSON.parse(notificationItems) : {};
  } catch (exception) {
    return null;
  }
}

export function addDismissedNotificationItem({ id, timestamp }) {
  try {
    return klaraLocalStorage.setItem('dismissedNotificationItems', {
      ...getDismissedNotificationItems(),
      [id]: timestamp,
    });
  } catch (exception) {
    return null;
  }
}

export function removeDismissedNotificationItem(id) {
  try {
    return klaraLocalStorage.setItem('dismissedNotificationItems', {
      ...omitBy(getDismissedNotificationItems(), (_, itemId) => itemId === id),
    });
  } catch (exception) {
    return null;
  }
}
