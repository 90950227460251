import * as React from 'react';
import { T, TProps } from './T';
import { DSTokenProps } from '../../hoc/withDSTokens';

const HeadingText2: React.FC<TProps & DSTokenProps & {children: React.ReactNode}& {children: React.ReactNode}> = (props) => {
  const { htmlTag = 'h2' } = props;
  return <T variant="h2" htmlTag={htmlTag} {...props} />;
};

export default HeadingText2;
